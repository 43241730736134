import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RedefinePasswordService } from './redefine-password.service';
import { first } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MatStepper } from '@angular/material/stepper';
import { SnackbarDefaultService } from 'src/app/shared/services/snackbar-default.service';

@Component({
  selector: 'app-redefine-password',
  templateUrl: './redefine-password.component.html',
  styleUrls: ['./redefine-password.component.scss'],
})
export class RedefinePasswordComponent {
  confirmPasswordValue: boolean = false;
  resultField: boolean = false;
  emptyField: boolean = true;
  validEmail: boolean = false;
  formEnded: boolean = false;

  titleConclusion: string = '';
  bodyConclusion: string = '';

  emptyPassword: boolean = true;

  validLength: boolean = false;
  validNumber: boolean = false;
  validSpecialCharacter: boolean = false;
  validUpper: boolean = false;
  validLower: boolean = false;

  public redefinePasswordForm: FormGroup = this.formBuilder.group({
    // email: ['', Validators.compose([Validators.required, Validators.email])],
    password: ['', [Validators.required]],
    confirmPassword: ['', [Validators.required]],
  });

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private redefinePasswordService:RedefinePasswordService,
    private authService: AuthService,
    private _snackBar: SnackbarDefaultService
    ) {

      this.redefinePasswordForm
      .get('password')
      ?.valueChanges.subscribe((value) => this.validFormatPassword(value));

    }

  visiblePassword(event: any, elementId: string) {
    const { target } = event;
    const { checked } = target;
    const element = document.getElementById(elementId);
    element?.setAttribute('type', checked ? 'text' : 'password');
  }

  validConfirmPassword() {
    const password = this.redefinePasswordForm.get('password')?.value;
    const confirmPassword =
      this.redefinePasswordForm.get('confirmPassword')?.value;

    if (confirmPassword === password) {
      this.confirmPasswordValue = true;
    } else {
      this.confirmPasswordValue = false;
    }
  }

  public validFormatPassword(password: string) {
    if (password == null || password === '') {
      this.validLength = false;
      this.validNumber = false;
      this.validSpecialCharacter = false;
      this.validUpper = false;
      this.validLower = false;
      return;
    }

    this.emptyPassword = false;

    this.validLength = !!RegExp(/^[0-9a-zA-Z$*&@#]{8,}$/).exec(password);
    this.validNumber = !!RegExp(/\d/).exec(password);
    this.validSpecialCharacter = !!password.match(/[^a-zA-Z 0-9]+/g);
    this.validUpper = !!RegExp(/[A-Z]+/).exec(password);
    this.validLower = !!RegExp(/[a-z]+/).exec(password);

  }

  public checkEmail() {
    this.validEmail = false;
    const email = this.redefinePasswordForm.get('email')?.value;

    if (email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$/)) {
      this.validEmail = true;
    } else {
      this.validEmail = false;
    }
    return this.validEmail;
  }

  validFieldsForm(form: any, field: string) {
    this.resultField = false;
    this.emptyField = true;

    if (
      form.get(field)?.value.length === 0 &&
      form.get(field)?.touched &&
      form.get(field)?.invalid
    ) {
      this.resultField = true;
      this.emptyField = true;
    } else if (
      form.get(field)?.value.length > 0 &&
      form.get(field)?.valid &&
      form.get(field)?.touched
    ) {
      this.resultField = true;
      this.emptyField = false;
    }

    return this.resultField;
  }

  getHashFromUrl() {
    return this.route.snapshot.paramMap.get('hash');
  }

  getIdFromUrl() {
    return this.route.snapshot.paramMap.get('id');
  }

  submitFormData() {
    if (this.redefinePasswordForm.valid) {
      let loginRequest = {
        id:this.getIdFromUrl(),
        senha:this.redefinePasswordForm.value.password
      }
      this.authService.putToken(this.getHashFromUrl());
      

      this.redefinePasswordService.redefineMyPassword(loginRequest)
        .pipe(first())
        .subscribe(
          (data)=>{
            this.authService.removeToken();
          }
        );
        this.titleConclusion = 'Senha redefinida!'
        this.bodyConclusion ='Sua redefinição de senha foi realizada com sucesso. Você já pode voltar a utilizar a plataform :)';

    }
    this.formEnded = true;
  }

  returnOK(stepper: MatStepper) {
    if (
      !this.validLength ||
      !this.validNumber ||
      !this.validSpecialCharacter ||
      !this.validUpper ||
      !this.validLower
    ) {
      this._snackBar.openSnackBarWarning('Senha não atende ao padrão solicitado')

      stepper.previous();
    }else {
      this.validConfirmPassword()
      if(!this.confirmPasswordValue){
        this._snackBar.openSnackBarWarning('Confirmação de senha inválida')
        stepper.previous();
      }else{
        this.submitFormData();
      }
    }
  }
}
