import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MyCompanyFormService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  createShipper(obj: any) {
    let usuario = JSON.parse(localStorage.getItem('usuario')!);

    obj.idCliente = usuario.cliente.id
    return this.http.post<any>(`${environment.API_BACK}/empresa`, obj).pipe(
      first(),
      map((dados) => {
        return dados;
      })
    );
  }

  getShipper(id: any) {
    return this.http.get<any>(`${environment.API_FILTER}/empresa?id=${id}`).pipe(
      first(),
      map((dados) => {
        return dados;
      })
    );
  }

  getSector() {
    return this.http.get<any>(`${environment.API_FILTER}/setor/all`).pipe(
      first(),
      map((dados) => {
        return dados;
      })
    );
  }

  saveProduct(obj: any) {
    const objComLookupRenomeado = { empresa: obj.empresa, produtoList: obj.produtoList };
    return this.http
      .post<any>(
        `${environment.API_BACK}/produto-empresa`,
        objComLookupRenomeado
      )
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  deleteProduct(obj: any) {
    let params = new HttpParams();
    params = params.append('idEmpresa', obj.idEmpresa);
    params = params.append('produtoLookup', obj.product);

    const httpOptions = {
      params: params,
    };

    return this.http.delete(
      `${environment.API_BACK}/produto-empresa/remove-produto`,
      httpOptions
    );
  }

}
