import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-quantity-vehicle',
  templateUrl: './dialog-quantity-vehicle.component.html',
  styleUrls: ['./dialog-quantity-vehicle.component.scss'],
})
export class DialogQuantityVehicleComponent implements OnInit{
  vehicle: any;
  quantity: any;
  type:any

  public formQuantity: FormGroup = this.formBuilder.group({
    quantity: [''],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<DialogQuantityVehicleComponent>
  ) {
    this.vehicle = this.data?.vehicle;
    this.type = this.data?.type
    if(this.type === 'edit'){
      this.quantity = this.data?.quantity;
      this.formQuantity.get('quantity')?.setValue(this.quantity);
    }
  }

  save(){
    this.dialogRef.close(this.formQuantity.get('quantity')?.value)
  }

  ngOnInit(): void {
    //empty
  }
}
