import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { MaskService } from 'src/app/shared/services/mask.service';
import { MyCompaniesService } from './my-companies.service';
import { PermissaoService } from 'src/app/shared/services/permissao.service';

export interface HeaderTable {
  lbCorporateName: string;
  lbCnpj: string;
  lbAddress: string;
  lbActions: string;
}

@Component({
  selector: 'app-my-companies',
  templateUrl: './my-companies.component.html',
  styleUrls: ['./my-companies.component.scss']
})
export class MyCompaniesComponent implements OnInit{
  routePath: string = '';
  newCompany: string = '';
  editCompany: string = '';
  loaded: boolean = true;

  displayedColumns: string[] = [
    'corporateName',
    'cnpj',
    'address',
    'actions',
  ];
  dataSource = new MatTableDataSource();

  options: string[] = ['Editar'];

  page: number = 0;
  size: number = 50;
  totalElements: number = 0;
  gridData: any = [];

  isAdmin:boolean = false;

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  headerTable: HeaderTable = {
    lbCorporateName: 'Razão Social',
    lbCnpj: 'CPF/CNPJ',
    lbAddress: '	Endereço',
    lbActions: 'Ações',
  };

  constructor(
    private router: Router,
    private myCompaniesService: MyCompaniesService,
    private maskService: MaskService,
    private permissaoService: PermissaoService,
  ) {}

  ngOnInit(): void {
    this.routePath = window.location.pathname;
    this.newCompany = `${this.routePath}/new-company`;
    this.editCompany = `${this.routePath}/edit-company`;
    this.isAdmin = this.permissaoService.isAdmin();
    this.getCompanies();
  }

  public redirect(action: string, row: any) {
    if (action === 'Editar') {
      this.router.navigate([this.editCompany, row.id]).catch((err) => err);
    }
  }

  public getCompanies() {
    const size = this.size;
    const page = this.page;

    const paramsObj = {
      page,
      size,
    };

    this.myCompaniesService
      .getCompanies(paramsObj)
      .pipe(first())
      .subscribe((companies: any) => {
        this.gridData = companies.content?.map((company: any) => ({
          id: company.id,
          corporateName: company.razaoSocial.toUpperCase(),
          cnpj: company.cnpj,
          address: this.getAddress(company.endereco),
          actions: '',
        }));
        this.totalElements = companies.totalElements;
        this.dataSource = new MatTableDataSource(this.gridData);
      });
  }

  public getAddress(adress: any) {
    return `${adress.cidade} - ${adress.uf}`;
  }

  public handlePageEvent(e: PageEvent) {
    if (this.size != e.pageSize) {
      this.page = 0;
    } else {
      this.page = e.pageIndex;
    }
    this.size = e.pageSize;
    this.getCompanies();
  }

}
