import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OfferConsultationService {
  constructor(private readonly http: HttpClient) { }

  getAuctions(paramsObject: any) {
    let params = new HttpParams();
    params = params.append('size', String(paramsObject.size));
    params = params.append('page', String(paramsObject.page));
    if (paramsObject.filtro.possuiLance != null) {
      params = params.append('possuiLance', paramsObject.filtro.possuiLance);
    }

    if (paramsObject.filtro) {
      let filtro = paramsObject.filtro;
      for (let key of Object.keys(filtro)) {
        if (
          filtro[key] != undefined &&
          filtro[key] != null &&
          filtro[key] != '' &&
          key != 'possuiLance'
        ) {
          params = params.append(key, filtro[key]);
        }
      }

    }

    params = params.append('sort', 'dataFim,desc');

    const httpOptions = {
      params,
    };
    return this.http
      .get<any>(`${environment.API_FILTER}/oferta/all`, httpOptions)
      .pipe(
        first(),
        map((data) => {
          return data;
        })
      );
  }

  getCSV(paramsObject: any) {
    let params = new HttpParams();

    if (paramsObject.statusOferta != null) {
      params = params.append('statusOferta', paramsObject.statusOferta);
    }
    if (paramsObject.numeroPedido != null) {
      params = params.append('numeroPedido', paramsObject.numeroPedido);
    }
    params = params.append('sort', 'dataFim,desc');

    const httpOptions = {
      params,
      responseType: 'text' as 'json',
    };
    return this.http
      .get<any>(`${environment.API_FILTER}/oferta/all/csv`, httpOptions)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  importOrderCsv(file: any) {
    const form: FormData = new FormData();
    form.append('file', file);
    const headers = new HttpHeaders();
    return this.http
      .post<any>(`${environment.API_BACK}/pedido/import`, form, { headers })
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  importRecipientCsv(file: any) {
    const form: FormData = new FormData();
    form.append('file', file);
    const headers = new HttpHeaders();
    return this.http
      .post<any>(`${environment.API_BACK}/destinatario/import`, form, { headers })
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  importCompanyCsv(file: any) {
    const form: FormData = new FormData();
    form.append('file', file);
    const headers = new HttpHeaders();
    return this.http
      .post<any>(`${environment.API_BACK}/empresa/import`, form, { headers })
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  cancelOffer(offerId: any) {
    return this.http
      .post<any>(`${environment.API_BACK}/oferta/cancelar`, offerId)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        }),
        catchError((error) => {
          throw new Error(error);
        })
      );
  }

  reopenOffer(obj: any) {
    return this.http.put<any>(`${environment.API_BACK}/oferta`, obj).pipe(
      map((dados) => {
        return dados;
      }),
      catchError((error) => {
        throw new Error(error);
      })
    );
  }
}
