import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private readonly http: HttpClient) {}

  login(authentication: any): Observable<any> {
    return this.http
      .post<any>(`${environment.API_LOGIN}/login`, authentication)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        }),
        catchError((error) => {
          return of(error);
        })
      );
  }

  forgotMyPassword(email: string) {
    let headers = new HttpHeaders();

    return this.http
      .post<any>(
        `${environment.API_LOGIN}/esqueci-minha-senha?email=${email}`,
        null,
        { headers, responseType: 'text' as 'json' }
      )
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }
}
