<mat-card class="container-principal">
  <mat-card-content>
    <div class="">
      <h6>Regras de ambiente</h6>
    </div>
    <mat-card class="mt-3 container-information">
      <mat-card-content>
        <form [formGroup]="rulesForm">
          <div fxLayout="row" class="mb-3">
            <div fxLayout="column" fxFlex="25">
              <div class="form-group">
                <label class="label-input form-label right" for="offerApproval">
                  Aprovar oferta a qualquer momento
                </label>
                <br>
                <div class="form-check form-check-inline">
                  <input
                    id="offerApprovalYes"
                    class="form-check-input radio"
                    name="offerApproval"
                    formControlName="offerApproval"
                    type="radio"
                    value="true"
                  />
                  <label class="form-check-label" for="offerApprovalYes">
                    Sim
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    id="offerApprovalNo"
                    class="form-check-input radio"
                    name="offerApproval"
                    formControlName="offerApproval"
                    type="radio"
                    value="false"
                  />
                  <label class="form-check-label" for="offerApprovalNo">
                    Não
                  </label>
                </div>
              </div>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </mat-card-content>
</mat-card>
