import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationsService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  atualizaPerfil(perfil: any) {
    return this.http
      .put(
        `${environment.API_BACK}/usuario/atualiza-usuario`,
        perfil,
        this.httpOptions
      )
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getEmbarcador(obj: any) {
    let params = new HttpParams();
    params = params.append('size', String(obj.size));
    params = params.append('page', String(obj.page));

    let httpOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${environment.API_BACK}/embarcador`, httpOptions)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getUsers(page: any) {
    let params = new HttpParams();
    params = params.append('size', String(page.size));
    params = params.append('page', String(page.page));

    return this.http
      .get<any>(`${environment.API_FILTER}/usuario`, { params: params })
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  createRecipient(obj: any) {
    return this.http.post<any>(`${environment.API_BACK}/destinatario`, obj).pipe(
      first(),
      map((dados) => {
        return dados;
      })
    );
  }

  getRecipients(obj: any) {
    let params = new HttpParams();

    if(obj.document){
      params = params.append('documento', String(obj.document));
    }

    params = params.append('size', String(obj.size));
    params = params.append('page', String(obj.page));

    return this.http
      .get<any>(`${environment.API_FILTER}/destinatario`, { params: params })
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getRecipientById(id: any) {
    return this.http.get<any>(`${environment.API_FILTER}/destinatario?id=${id}`).pipe(
      first(),
      map((dados) => {
        return dados;
      })
    );
  }

  deteleteRecipient(id: number) {
    let params = new HttpParams();
    params = params.append('id', id);

    const httpOptions = {
      params: params,
    };

    return this.http
      .delete(`${environment.API_BACK}/destinatario`, httpOptions)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        }),
        catchError((error) => {
          throw new Error(error);
        })
      );
  }

  changeUserStatus(id: number) {
    return this.http
      .put<any>(
        `${environment.API_BACK}/usuario/alterar-status-usuario/${id}`,
        id
      )
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }
}
