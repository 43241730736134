import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationsCarrierService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  atualizaPerfil(perfil: any) {
    return this.http
      .put(
        `${environment.API_BACK}/usuario/atualiza-usuario`,
        perfil,
        this.httpOptions
      )
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getTransportadoras(obj: any, id: any) {
    let params = new HttpParams();
    params = params.append('size', String(obj.size));
    params = params.append('page', String(obj.page));
    params = params.append('usuario', id);

    let httpOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${environment.API_FILTER}/gestao-usuario`, httpOptions)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getCarriers() {
    return this.http
      .get<any>(`${environment.API_FILTER}/empresa/empresas`)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getUsuarios(obj: any) {
    let params = new HttpParams();
    if (obj.id != undefined) {
      params = params.append('id', obj.id);
    } else {
      params = params.append('size', String(obj.size));
      params = params.append('page', String(obj.page));
    }

    let httpOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${environment.API_FILTER}/usuario`, httpOptions)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getUserById(id: any) {
    let params = new HttpParams();

    params = params.append('id', id);

    let httpOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${environment.API_FILTER}/usuario`, httpOptions)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }


  getTipoUsuarios(obj: any) {
    let params = new HttpParams();
    if (obj.id != undefined) {
      params = params.append('id', obj.id);
    } else {
      params = params.append('size', String(obj.size));
      params = params.append('page', String(obj.page));
    }

    let httpOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${environment.API_FILTER}/tipo-usuario`, httpOptions)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  criaUsuario(obj: any) {
    return this.http.post<any>(`${environment.API_BACK}/usuario`, obj).pipe(
      first(),
      map((dados) => {
        return dados;
      })
    );
  }

  atualizaUsuario(obj: any) {
    return this.http.put<any>(`${environment.API_BACK}/usuario/atualiza-usuario`, obj).pipe(
      first(),
      map((dados) => {
        return dados;
      })
    );
  }

  criaGestao(dto: any) {
    return this.http
      .post<any>(`${environment.API_BACK}/gestao`, dto)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        }),
        catchError((error) => {
          throw new Error(error);
        })
      );
  }
}
