import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { finalize, first } from 'rxjs/operators';
import { ConfigurationsCarrierService } from '../configurations-carrier.service';
import { PermissaoService } from 'src/app/shared/services/permissao.service';
import { ConfigurationsService } from 'src/app/configurations/configurations.service';
import { DialogConfirmComponent } from 'src/app/shared/dialog-confirm/dialog-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarDefaultService } from 'src/app/shared/services/snackbar-default.service';
export interface PeriodicElement {
  id: number;
  name: string;
  email: string;
  status: string;
  actions: string;
}

export interface HeaderTable {
  lbName: string;
  lbEmail: string;
  lbStatus: string;
  lbActions: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    id: 13,
    name: 'Empresa X',
    email: 'seniorx@senior.com',
    status: 'Ativo',
    actions: '',
  },
  {
    id: 2,
    name: 'Empresa X',
    email: 'seniorx@senior.com',
    status: 'Ativo',
    actions: '',
  },
  {
    id: 3,
    name: 'Empresa X',
    email: 'seniorx@senior.com',
    status: 'Inativo',
    actions: '',
  },
];

@Component({
  selector: 'app-my-users',
  templateUrl: './my-users.component.html',
  styleUrls: ['./my-users.component.scss'],
})
export class MyUsersComponent implements OnInit {
  displayedColumns: string[] = ['name', 'email', 'status', 'actions'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  options: any = ['Editar', 'Alterar status'];

  page: number = 0;
  size: number = 50;
  totalElements: number = 0;
  loadingContent: boolean = false;

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  headerTable: HeaderTable = {
    lbName: 'Nome',
    lbEmail: 'E-mail',
    lbStatus: 'Status',
    lbActions: '	Ações',
  };

  statusUser: string;
  routePath: string = '';
  newUser: string = '';
  editUser: string = '';

  isAdmin: boolean = false;
  userId = Number(JSON.parse(localStorage.getItem('usuario'))?.id);

  constructor(
    private router: Router,
    private service: ConfigurationsCarrierService,
    private permissaoService: PermissaoService,
    private configurationsService: ConfigurationsService,
    private dialog: MatDialog,
    private _snackBar: SnackbarDefaultService
  ) {}

  ngOnInit(): void {
    this.routePath = window.location.pathname;
    this.newUser = `${this.routePath}/new-user`;
    this.editUser = `${this.routePath}/edit-user`;
    this.isAdmin = this.permissaoService.isAdmin();
    this.getUsers();
  }

  public redirect(action: string, row: any) {
    if (action === 'Editar') {
      this.router.navigate([this.editUser, row.id]).catch((err) => err);
    }
    if (action === 'Alterar status') {
      this.changeStatus(row);
    }
  }

  public getUsers() {
    const paginatorObj = {
      page: this.page,
      size: this.size,
    };
    this.loadingContent = false;

    this.service
      .getUsuarios(paginatorObj)
      .pipe(finalize(() => {}))
      .subscribe((result: any) => {
        let usuarios: PeriodicElement[] = [];
        result.content.forEach(
          (element: { id: any; nome: any; email: any; statusUsuario: any }) => {
            let usuario: PeriodicElement = {
              id: element.id,
              name: element.nome.toUpperCase(),
              email: element.email,
              status: this.statusUserAdjustment(element.statusUsuario),
              actions: '',
            };

            usuarios.push(usuario);
          }
        );
        this.totalElements = result.totalElements;
        this.loadingContent = true;
        this.dataSource = new MatTableDataSource(usuarios);
      });
  }

  public handlePageEvent(e: PageEvent) {
    if (this.size != e.pageSize) {
      this.page = 0;
    } else {
      this.page = e.pageIndex;
    }
    this.size = e.pageSize;
    this.getUsers();
  }

  public statusUserAdjustment(status: string) {
    if (status === 'ATIVO') {
      return 'Ativo';
    } else {
      return 'Inativo';
    }
  }

  public changeStatus(row: any) {
    let text = row.status == 'Ativo' ? 'INATIVAR' : 'ATIVAR';

    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: 'Confirmação',
        description: `Tem certeza que deseja ${text} esse usuário?`,
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.configurationsService
          .changeUserStatus(row.id)
          .pipe(first())
          .subscribe((data: any) => {
            if (data) {
              this._snackBar.openSnackBarSuccess(
                `Usuário alterado com sucesso`
              );
              setTimeout(() => {
                this.getUsers();
              }, 500);
            }
          });
      }
    });
  }
}
