import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, first, tap } from 'rxjs';
import { MyProfileService } from 'src/app/configurations-carrier/my-profile/my-profile.service';
import { SnackbarDefaultService } from 'src/app/shared/services/snackbar-default.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  public forgotPasswordForm: FormGroup = this.formBuilder.group({
    email: ['', Validators.compose([Validators.required, Validators.email])],
  });

  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;
  siteKey: string = "6LfYJgwqAAAAABbMIZO-UMjmOuibu4dTFPfiH3Sw"
  title: string;
  recaptchaValue: string = null;
  recaptcha: boolean = true;
  send: boolean = false;
  validEmail: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private myProfileService: MyProfileService,
    private dialogRef: MatDialogRef<ForgotPasswordComponent>,
    private _snackBar: SnackbarDefaultService
  ) { }

  ngOnInit(): void {
    this.addRecaptchaScript();
  }


  public checkEmail() {
    this.validEmail = false;
    const email = this.forgotPasswordForm.get('email')?.value;

    if (email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$/)) {
      this.validEmail = true;
    } else {
      this.validEmail = false;
    }
    return this.validEmail;
  }

  changePassword() {
    const email = this.forgotPasswordForm.get('email')?.value
    this.myProfileService
      .forgotMyPassword(email, this.recaptchaValue)
      .pipe(first())
      .subscribe((result) => {
        this._snackBar.openSnackBarSuccess('Verifique a caixa de entrada do email.');
      })
    catchError((error) => {
      const errorMessage = 'Erro ao enviar email: ' + error.error;
      this._snackBar.openSnackBarError(errorMessage);
      return [];
    })
  }

  sendData() {
    if (this.checkEmail()) {
      this.changePassword()
      this.dialogRef.close(!this.send)
    }

  }

  close() {
    this.dialogRef.close(this.send);
  }

  renderRecaptcha() {
    (window as any)['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey': this.siteKey,
      'expired-callback': () => this.recaptchaExpired(),
      'callback': (response: string) => {
        this.recaptchaValue = response;
        this.recaptcha = false;
        this.statusButton(false);

      }
    });
  }

  addRecaptchaScript() {

    (window as any)['grecaptchaCallback'] = () => {
      this.renderRecaptcha();
    }

    (function (d, s, id, obj) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderRecaptcha(); return; }
      js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));

  }

  recaptchaExpired() {
    this.recaptcha = false;
    this.recaptchaValue = null;
    this.statusButton(true);
  }

  recaptchaStage() {
    return this.recaptcha;
  }

  statusButton(stage: boolean) {
    let buttonRecaptcha = document.getElementById('button-recaptcha') as HTMLButtonElement;
    buttonRecaptcha.disabled = stage;
  }

}
