<div id="main-content">
  <app-toolbar></app-toolbar>
  <app-sidebar></app-sidebar>
  <div class="main-content">
    <div class="content-profile">
      <div class="card border-0 container-profile" *ngIf="!hideNavItems">
        <div class="card-body" *ngFor="let mi of menuRoutes">
          <h6 class="card-title profile mt-3">{{ mi.label }}</h6>

          <mat-nav-list
            class="primary"
            *ngFor="let i of mi.items"
            [activated]="i?.isActive"
          >
            <a id="label-menu-{{removeAccentuationForID(i.label)}}"
              [hidden]="i.label == 'Meus destinatários' && !visibleRecipients || (i.label == 'Integrações' && !isAdmin)"
              mat-list-item
              [routerLink]="[i.path]"
              class="list-item"
              [ngClass]="{ 'item-active': i.path === lastPath }"
            >
              <i class="ri-{{ i.icon }} margin-icons"></i>
              {{ i.label }}
            </a>
          </mat-nav-list>
        </div>
      </div>
    </div>
    <div class="container-body">
      <app-page-title
        [title]="pageTitle"
        [paths]="componentRoutes"
        [isHome]="isHome"
      ></app-page-title>
      <div class="p-4">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<ng-template #shipperProfile> </ng-template>
