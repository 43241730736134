import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './shared/services/auth.service';
import { PermissaoService } from './shared/services/permissao.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isAdmin: boolean = false;

  constructor(
    private readonly router: Router,
    private authservice: AuthService,
    private permissaoService: PermissaoService
  ) {}

  ngOnInit(): void {
    const route = window.location.pathname;
    sessionStorage.setItem('rota', route);
    const authenticated = sessionStorage.getItem('authenticated');

    if (
      (RegExp(/\/shipper/).exec(route) ||
        RegExp(/\/carrier/).exec(route) ||
        RegExp(/\/configurations-shipper/).exec(route) ||
        RegExp(/\/configurations-carrier/).exec(route)) &&
      !authenticated
    ) {
      this.router.navigate(['/login']);
    }

    if (authenticated) {
      this.isAdmin = this.permissaoService.isAdmin();
      if (!this.isAdmin && RegExp(/\/integrations/).exec(route)) {
        this.router.navigate(['/shipper']);
      }
    }
  }
}
