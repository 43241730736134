import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {  first } from 'rxjs/operators';
import { ConfigurationsCarrierService } from '../configurations-carrier.service';
import { SnackbarDefaultService } from 'src/app/shared/services/snackbar-default.service';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss'],
})
export class UserRegistrationComponent {
  public userForm: FormGroup = this.formBuilder.group({
    id: [''],
    user: ['', Validators.required],
    email: ['', Validators.compose([Validators.required, Validators.email])],
    phone: ['', Validators.required],
    alcada: ['', Validators.required],
    userType: ['', Validators.required],
    password: ['', Validators.required],
    repitaSenha: ['', Validators.required],
    carriersByUser: ['', Validators.required],
  });

  public usuario = JSON.parse(localStorage.getItem('usuario')!);
  id: any;
  options: any[] = [];
  validEmail: boolean = false;
  typePassword: string = 'password';
  typeRepitPassword: string = 'password';
  empresaId:any;
  carriersByUser: any[] = [];
  page: number = 0;
  size: number = 50;
  totalElements: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private service: ConfigurationsCarrierService,
    private route: ActivatedRoute,
    private _snackBar: SnackbarDefaultService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getTipoUsuarios();
    this.id = this.route.snapshot.params['id'];
    
    if (this.id != undefined && this.id != null) {
      this.getGestaoMeuUsuario();
      this.getUsuario();
    }

    this.getCarriers();
  }

  getGestaoMeuUsuario(){
    const size = this.size;
    const page = this.page;
    const usuario = this.route.snapshot.params['id'];
    const paramsObj = {
      page,
      size,
      usuario,
    };

    this.service
    .getTransportadoras(paramsObj, usuario)
    .pipe(first())
    .subscribe((result: any) => {
     if(result){
      const relatedCarriersIds = result.content?.map((carrier: any) => carrier.empresa.id);
      this.userForm.get('carriersByUser')!.setValue(relatedCarriersIds);
     }
      this.empresaId = result.content[0].empresa.id
    });
  }

  getCarriers() {
    this.service
      .getCarriers()
      .pipe()
      .subscribe((result: any) => {
        this.carriersByUser = result.content.map((dado: any) => ({
          label: dado.razaoSocial,
          value: dado.id,
          id: dado.id,
        }));
      });
  }

  getTipoUsuarios() {
    const paginatorObj = {
      id: this.id,
      page: 0,
      size: 50,
    };

    this.service
      .getTipoUsuarios(paginatorObj)
      .pipe()
      .subscribe((result: any) => {
        result.content.forEach((element: { tipo: any; id: any }) => {
          this.options.push({
            label: this.typeUser(element.tipo),
            value: element.id,
          });
        });
      });
  }

  public getUsuario() {
    const paginatorObj = {
      id: this.id,
    };

    this.service
      .getUsuarios(paginatorObj)
      .pipe()
      .subscribe((result: any) => {
        this.userForm.get('id')!.setValue(result.id);
        this.userForm.get('user')!.setValue(result.nome);
        this.userForm.get('email')!.setValue(result.email);
        this.userForm.get('userType')!.setValue(result.tipoUsuario.id);
        this.userForm.get('phone')!.setValue(result.telefone);
        this.userForm.get('alcada')!.setValue(result.alcada);
        this.userForm.get('repitaSenha').clearValidators();
        this.userForm.get('repitaSenha').updateValueAndValidity();
        this.userForm.get('password').clearValidators();
        this.userForm.get('password').updateValueAndValidity();

      });
  }

  public onChange(eventValue: any) {
    //empty
  }

  public checkEmail() {
    this.validEmail = false;
    const email = this.userForm.get('email')?.value;

    if (email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$/)) {
      this.validEmail = true;
    } else {
      this.validEmail = false;
    }
    return this.validEmail;
  }

  public submitForm() {
    if (
      this.userForm.get('password')?.value !==
      this.userForm.get('repitaSenha')?.value
    ) {
      return;
    }
    let usuario = {
      nome: this.userForm.get('user')?.value,
      email: this.userForm.get('email')?.value,
      senha: this.userForm.get('password')?.value,
      alcada: this.userForm.get('alcada')?.value,
      telefone: this.userForm.get('phone')?.value.replace(/[()-]/g, ''),
      clienteId: this.usuario.cliente.id,
      tipoUsuario: this.userForm.get('userType')?.value,
      id: this.id ? this.id : null
    };
    if(this.id){
      this.service
      .atualizaUsuario(usuario)
      .pipe(first())
      .subscribe({
        next: () => {
          const management = {
            empresa: this.userForm.get('carriersByUser')?.value,
            usuario: this.id,
          };
          this.criaGestao(management, false)
        },
        error: (error) => {
          this._snackBar.openSnackBarError(
            'Ocorreu um erro'
          );
        }
    });
    }else{
       this.service
      .criaUsuario(usuario)
      .pipe(first())
      .subscribe({
        next: (result:any) => {
          const management = {
            empresa: this.userForm.get('carriersByUser')?.value,
            usuario: result.id,
          };
          this.criaGestao(management, true)
        },
        error: (error) => {
          this._snackBar.openSnackBarError(
            'Um usuário com esse email já existe'
          );
        }
    });
    }

  }

  public criaGestao(management: any, novo:boolean) {
    this.service
      .criaGestao(management)
      .pipe(first())
      .subscribe({
       next: (result: any) => {
          this.redirect();
          if(novo){
            this._snackBar.openSnackBarSuccess('Usuário cadastrado com sucesso');
          }
          if(!novo){
            this._snackBar.openSnackBarSuccess('Usuário alterado com sucesso');
          }
        },
        error: (error) => {
          this._snackBar.openSnackBarError(
            'Erro ao incluir gestao'
          );
        }
  });
  }

  public typeUser(typeUser: string) {
    if (typeUser == 'admin') return 'admin';
    return 'padrão';
  }

  public redirect() {
    setTimeout(() => {
      this.router
        .navigate(['configurations-carrier/users'])
        .catch((err) => err);
    }, 2000);
  }

  showPassword(event: any) {
    if (event.target.checked) {
      this.typePassword = 'text';
    } else {
      this.typePassword = 'password';
    }
  }

  showRepitPassword(event: any) {
    if (event.target.checked) {
      this.typeRepitPassword = 'text';
    } else {
      this.typeRepitPassword = 'password';
    }
  }
}
