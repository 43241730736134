import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RedefinePasswordDialogService {

  constructor(private http: HttpClient) {}

  redefinePassword(obj: any) {
    let headers = new HttpHeaders();
    return this.http
      .post<any>(`${environment.API_LOGIN}/troca-senha`, obj, { headers, responseType: 'text' as 'json' })
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }
}
