import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  carriers,
  companies,
  headerTable,
  invites,
  myCarriers,
  offer,
  order,
  profile,
  titleAccordion,
  userTypes,
  users,
  validationCriteria,
} from '../../model/access-privileges.model.shipper';

@Component({
  selector: 'app-access-privileges',
  templateUrl: './access-privileges.component.html',
  styleUrls: ['./access-privileges.component.scss'],
})
export class AccessPrivilegesComponent implements OnInit {
  public privilegesForm: FormGroup = this.formBuilder.group({
    user: ['', Validators.required],
    comments: ['', Validators.required],
  });

  listTables = [
    profile,
    companies,
    users,
    userTypes,
    carriers,
    invites,
    myCarriers,
    validationCriteria,
    offer,
    order,
  ];
  listHeaders = [headerTable];

  listTitle = titleAccordion;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    //empty
  }

  public submitForm() {
    //empty
  }
}
