import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { MyCompaniesComponent } from './my-companies/my-companies.component';
import { MyUsersComponent } from './my-users/my-users.component';
import { UserTypeComponent } from './user-type/user-type.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { AccessPrivilegesComponent } from './access-privileges/access-privileges.component';
import { MyCompanyComponent } from './my-company/my-company.component';
import { MyCompanyFormComponent } from './my-company-form/my-company-form.component';

const routes: Routes = [
  {
    path: '',
    component: MyProfileComponent,
  },
  {
    path: 'profile',
    component: MyProfileComponent,
  },
  {
    path: 'companies',
    component: MyCompaniesComponent,
  },
  {
    path: 'companies/new-company',
    component: MyCompanyFormComponent,
  },
  {
    path: 'companies/edit-company/:id',
    component: MyCompanyComponent,
  },
  {
    path: 'users',
    component: MyUsersComponent,
  },
  {
    path: 'users/new-user',
    component: UserRegistrationComponent,
  },
  {
    path: 'users/edit-user/:id',
    component: UserRegistrationComponent,
  },
  {
    path: 'user-types',
    component: UserTypeComponent,
  },
  {
    path: 'user-types/access-privileges',
    component: AccessPrivilegesComponent,
  },
  {
    path: 'user-types/edit-access-privileges/:id',
    component: AccessPrivilegesComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConfigurationsCarrierRoutingModule {}
