import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MyCompaniesService {
  constructor(private readonly http: HttpClient) {}

  getCompanies(paramsObject: any) {
    let params = new HttpParams();
    params = params.append('size', String(paramsObject.size));
    params = params.append('page', String(paramsObject.page));
    return this.http
      .get<any>(`${environment.API_FILTER}/cliente/minhas-empresas`, {
        params: params,
      })
      .pipe(
        first(),
        map((data: any) => {
          return data;
        })
      );
  }
}
