<mat-card class="container-recipient">
  <mat-card-content>
    <form action="" [formGroup]="recipientForm" (ngSubmit)="submitForm()">
      <h6 *ngIf="!id" class="bottom mt-1">Cadastro de destinatário</h6>
      <h6 *ngIf="id" class="bottom mt-1">Edição de destinatário</h6>
      <span class="description">Dados do destinatário</span>

      <div fxLayout="row" fxLayoutAlign="spce-between" class="mt-4">
        <div fxLayout="column" fxFlex class="right">
          <div class="form-group">
            <label class="label-input form-label required" for="name">
              <span> Nome Destinatário </span>
            </label>
            <input
              matInput
              name="name"
              class="form-control input-data w-100"
              formControlName="name"
              placeholder="Digite o nome"
              required
              [ngClass]="{
                'is-invalid': validFieldsForm('province') && emptyField,
                'is-valid': validFieldsForm('province') && !emptyField
              }"
            />
            <div
              *ngIf="validFieldsForm('province') && emptyField"
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex class="left">
          <div class="form-group">
            <label
              class="label-input form-label required"
              for="destinationDocument"
            >
              <span> CPF/CNPJ </span>
            </label>
            <input
              matInput
              mask="00.000.000/0000-00||000.000.000-00"
              name="document"
              class="form-control input-data w-100"
              formControlName="document"
              placeholder="Digite o documento"
              required
              [ngClass]="{
                'is-invalid':
                  (recipientForm.get('document')?.invalid &&
                    recipientForm.get('document')?.touched) ||
                  (recipientForm.get('document')?.value?.length <= 5 &&
                    recipientForm.get('document')?.touched),
                'is-valid':
                  recipientForm.get('document')?.valid &&
                  recipientForm.get('document')?.touched &&
                  recipientForm.get('document')?.value?.length > 5
              }"
            />
            <div
              *ngIf="
                recipientForm.get('document')?.touched &&
                recipientForm.get('document')?.errors
              "
              class="invalid-feedback label-mandatory"
            >
              <span *ngIf="recipientForm.get('document')?.value === ''">
                Campo obrigatório*</span
              >
            </div>
            <div
              *ngIf="
                recipientForm.get('document')?.value?.length <= 5 &&
                recipientForm.get('document')?.value?.length > 0 &&
                recipientForm.get('document')?.touched
              "
              class="invalid-feedback label-mandatory"
            >
              <span> Documento inválido! </span>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="spce-between" class="mt-3">
        <div fxLayout="column" fxFlex="49.2">
          <div class="form-group">
            <label class="label-input form-label required" for="zipcode">
              <span> CEP </span>
            </label>
            <input
              id="zipcode"
              matInput
              mask="00000-000"
              name="zipcode"
              class="form-control input-data w-100"
              formControlName="zipcode"
              placeholder="Digite o CEP"
              (blur)="onZipcodeChange(recipientForm.get('zipcode')?.value)"
              required
              [ngClass]="{
                'is-invalid':
                  recipientForm.get('zipcode')?.invalid &&
                  recipientForm.get('zipcode')?.touched,
                'is-valid':
                  recipientForm.get('zipcode')?.valid &&
                  recipientForm.get('zipcode')?.touched
              }"
            />
            <div
              *ngIf="
                (recipientForm.get('zipcode')?.touched &&
                  recipientForm.get('zipcode')?.errors) ||
                invalidZipCode
              "
              class="invalid-feedback label-mandatory"
            >
              <span *ngIf="recipientForm.get('zipcode')?.value === ''">
                Campo obrigatório*</span
              >
              <span
                *ngIf="
                  recipientForm.get('zipcode')?.value !== '' && invalidZipCode
                "
              >
                CEP inválido!
              </span>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="spce-between" class="mt-3">
        <div fxLayout="column" fxFlex class="right">
          <div class="form-group">
            <label class="label-input form-label required" for="province">
              UF
            </label>
            <ng-select
              name="province"
              formControlName="province"
              [items]="UFs"
              [closeOnSelect]="true"
              [searchable]="true"
              [clearable]="false"
              bindLabel="value"
              bindValue="value"
              required
              [ngClass]="{
                'invalid is-invalid': validFieldsForm('province') && emptyField,
                'valid is-valid': validFieldsForm('province') && !emptyField
              }"
            >
              <ng-template
                ng-label-tmp
                let-item="item"
                let-index="index"
                let-search="searchTerm"
              >
                {{ item.value }}
              </ng-template>
            </ng-select>
            <div
              *ngIf="validFieldsForm('province') && emptyField"
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex class="left">
          <div class="form-group">
            <label class="label-input form-label required" for="city">
              Cidade
            </label>
            <input
              matInput
              name="city"
              class="form-control input-data w-100"
              formControlName="city"
              placeholder="Digite o nome da cidade"
              required
              [ngClass]="{
                'is-invalid': validFieldsForm('city') && emptyField,
                'is-valid': validFieldsForm('city') && !emptyField || recipientForm.get('city')?.value?.length > 0
              }"
            />
            <div
              *ngIf="validFieldsForm('city') && emptyField"
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="spce-between" class="mt-3">
        <div fxLayout="column" fxFlex class="right">
          <div class="form-group">
            <label class="label-input form-label required" for="street">
              Logradouro
            </label>
            <input
              matInput
              name="street"
              class="form-control input-data w-100"
              formControlName="street"
              placeholder="Digite o logradouro"
              required
              [ngClass]="{
                'is-invalid': validFieldsForm('street') && emptyField,
                'is-valid': validFieldsForm('street') && !emptyField || recipientForm.get('street')?.value?.length > 0
              }"
            />
            <div
              *ngIf="validFieldsForm('street') && emptyField"
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex class="left">
          <div class="form-group">
            <label class="label-input form-label required" for="number">
              Número
            </label>
            <input
              matInput
              name="number"
              type="number"
              class="form-control input-data w-100"
              formControlName="number"
              placeholder="Digite o número"
              required
              [ngClass]="{
                'is-invalid': validFieldsForm('number') && emptyField,
                'is-valid': validFieldsForm('number') && !emptyField
              }"
            />
            <div
              *ngIf="validFieldsForm('number') && emptyField"
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="spce-between" class="mt-3">
        <div fxLayout="column" fxFlex class="right">
          <div class="form-group">
            <label class="label-input form-label required" for="neighborhood">
              Bairro
            </label>
            <input
              matInput
              name="neighborhood"
              class="form-control input-data w-100"
              formControlName="neighborhood"
              placeholder="Digite o bairro"
              required
              [ngClass]="{
                'is-invalid': validFieldsForm('neighborhood') && emptyField,
                'is-valid': validFieldsForm('neighborhood') && !emptyField || recipientForm.get('neighborhood')?.value?.length > 0
              }"
            />
            <div
              *ngIf="validFieldsForm('neighborhood') && emptyField"
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex class="left">
          <div class="form-group">
            <label class="label-input form-label" for="complement">
              Complemento
            </label>
            <input
              matInput
              name="complement"
              class="form-control input-data w-100"
              formControlName="complement"
              placeholder="Digite um complemento"
              [ngClass]="{
                'is-valid': validFieldsForm('complement') && !emptyField
              }"
            />
          </div>
        </div>
      </div>
      <div class="d-flex flex-row-reverse mt-4">
        <div>
          <button
            mat-flat-button
            color="primary"
            class="save-button"
            (ngSubmit)="submitForm()"
            [disabled]="recipientForm.invalid"
          >
            Salvar
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
