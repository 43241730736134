import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RedefinePasswordService {

  constructor(protected http: HttpClient) { }

  redefineMyPassword(loginRequest: any) {
    let headers = new HttpHeaders()

    return this.http
      .post<any>(`${environment.API_LOGIN}/recupera-senha`, loginRequest, { headers, responseType: "text" as "json" })
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );

  }


}
