import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UFs, veiculo_options } from 'src/app/shared/const/consts';

interface ViaCepResponse {
  bairro: string;
  cep: string;
  complemento: string;
  ddd: string;
  gia: string;
  ibge: string;
  localidade: string;
  logradouro: string;
  siafi: string;
  uf: string;
}

@Component({
  selector: 'app-shipper-registration',
  templateUrl: './shipper-registration.component.html',
  styleUrls: ['./shipper-registration.component.scss'],
})
export class ShipperRegistrationComponent {
  userForm: FormGroup;
  companyForm: FormGroup;
  formEnded: boolean = false;

  loadTypes = [
    //{ label: 'Fracionado', value: 'Fracionado' },
    { label: 'Lotação', value: 'Lotação' },
  ];

  UFs = UFs

  vehicleTypes = veiculo_options

  constructor(private fb: FormBuilder, private http: HttpClient) {
    this.userForm = this.fb.group({
      name: ['', [Validators.required]],
      companyName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phonenumber: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });

    this.companyForm = this.fb.group({
      document: ['', [Validators.required]],
      legalName: ['', [Validators.required]],
      companyName: ['', [Validators.required]],
      zipcode: ['', [Validators.required]],
      province: ['', [Validators.required]],
      city: ['', [Validators.required]],
      street: ['', [Validators.required]],
      number: ['', [Validators.required]],
      complement: [''],

      loadsType: ['', [Validators.required]],
      states: ['', [Validators.required]],
      vehicleTypes: ['', [Validators.required]],
      insurance: ['', [Validators.required]],
      patrimonialCost: ['', [Validators.required]],
      hasTracker: ['', [Validators.required]],
    });

    this.companyForm.controls['zipcode'].valueChanges.subscribe(
      (value: string) => this.onZipcodeChange(value)
    );
  }

  onZipcodeChange(value: string) {
    if (value.length < 8) return;
    const protocol = location.protocol;
    let zipsub = this.http
      .get<ViaCepResponse>(`${protocol}//viacep.com.br/ws/${value}/json/`)
      .subscribe((zipData) => {
        this.companyForm.setValue({
          province: zipData.uf,
          city: zipData.localidade,
          street: zipData.logradouro,
          complement: zipData.complemento,
        });
        zipsub.unsubscribe();
      });
  }

  visiblePassword(event: any, elementId: string) {
    const { target } = event;
    const { checked } = target;
    const element = document.getElementById(elementId);
    element?.setAttribute('type', checked ? 'text' : 'password');
  }

  submitUserData() {
    if (!this.userForm.valid) {
      return;
    }
    const passValue = this.userForm.controls['password'].value;
    const cpassValue = this.userForm.controls['confirmPassword'].value;
    if (passValue !== cpassValue) {
      return;
    }
  }

  submitFormData() {
    this.formEnded = true;
  }
}
