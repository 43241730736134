<div class="container-attachement">
  <mat-dialog-title>
    <h6 class="mt-2">{{ title }}</h6></mat-dialog-title
  >
  <mat-dialog-content>
    <p class="description" *ngIf="description">{{ description }}</p>
    <ngx-dropzone
    *ngIf="!loading; else isLoading"
      [ngClass]="{
        'no-description': !description,
        'top-description': description
      }"
      (change)="onSelect($event)"
      [multiple]="false"
      [accept]="'*'"
      class="custom-attachment"
    >
      <ngx-dropzone-label id="dropzone-label">
        <div id="icon">
          <i class="ri-upload-cloud-2-line"></i>
        </div>
        <p class="text-upload">Selecione um arquivo ou arraste aqui</p>

        <span id="file-upload-btn" class="">
          <i class="ri-upload-cloud-line"></i> Arquivo</span
        >
      </ngx-dropzone-label>
      <div *ngIf="isImage">
        <ngx-dropzone-image-preview
          ngProjectAs="ngx-dropzone-preview"
          *ngFor="let f of files"
          [file]="f"
          [removable]="true"
          (removed)="onRemove(f)"
        >
          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-image-preview>
      </div>
      <div *ngIf="!isImage">
        <ngx-dropzone-preview
          *ngFor="let f of files"
          [removable]="true"
          (removed)="onRemove(f)"
        >
          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-preview>
      </div>
    </ngx-dropzone>
  </mat-dialog-content>
  <div class="d-flex flex-row-reverse mt-3">
    <div mat-dialog-actions>
      <button mat-flat-button mat-dialog-close class="btn-cancel">
        Cancelar
      </button>
      <button
        mat-flat-button
        color="primary"
        cdkFocusInitial
        class="btn-send"
        (click)="importFile()"
      >
        Enviar
      </button>
    </div>
  </div>
</div>

<ng-template #isLoading>
  <div class="container-spinner">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
