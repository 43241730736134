import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

class ImageUser {
  constructor(public src: string, public file: File) {}
}


@Injectable({
  providedIn: 'root',
})
export class ImageService {

  selectedFile : ImageUser ;
  usuarioAtual = JSON.parse(localStorage.getItem('usuario')!);

  constructor(private http: HttpClient) {

  }

  uploadImagemPerfil(file: File): Observable<any> {

    const formData = new FormData();
    formData.append('file', file);

  return this.http.post<any>(`${environment.API_BACK}/usuario/upload-imagem`, formData);

}


updateFile(event: any) {
  const fileList: FileList = event.target.files;
  if (fileList && fileList.length > 0) {
      const file: File = fileList[0];
      const fileType: string = file.type;
      const fileSize: number = file.size;
      const maxSize: number = 5 * 1024 * 1024;

      if (fileSize <= maxSize && (fileType === 'image/png' || fileType === 'image/jpeg')) {
          const render = new FileReader();
          render.addEventListener('load', (input: any) => {
              this.selectedFile = new ImageUser(input.target.result, file);
              this.uploadImagemPerfil(this.selectedFile.file).subscribe(
                  (res) => {

                   this.usuarioAtual.imagem = res.imagem;
                    localStorage.setItem('usuario', JSON.stringify(this.usuarioAtual));

                  },
                  (err) => {
                      // Lida com o erro do upload da imagem
                  }
              );
          });
          render.readAsDataURL(file);
      } else {

          if (fileSize > maxSize) {
              console.error('O arquivo selecionado é muito grande. Por favor, selecione um arquivo menor que 20MB.');
          } else {
              console.error('Por favor, selecione uma imagem PNG ou JPEG.');
          }

      }
  } else {

  }
}



}
