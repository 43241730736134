import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TrackingService } from './tracking.service';
import { first } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

interface OfferSpot {
  nomeEmbarcador: string;
  cnpjEmbarcador: string;
  tipoOferta: string;
  valorOferta: number;
  certificacoes: string[];
  rastreamentoVeiculo: any;
  numeroOferta: number;
  ordemColeta: string;
  origem: {
    cep: string;
    cidade: string;
    uf: string;
    logradouro: string;
    numero: string;
    bairro: string;
    complemento: string;
  };
  destino: {
    cep: string;
    cidade: string;
    uf: string;
    logradouro: string;
    numero: string;
    bairro: string;
    complemento: string;
  };
  dataPrevisaoColeta: string;
  dataPrevistaEntrega: string;
  tipoProduto: string;
  valorTotalCarga: number;
  valorTotalPesoBruto: string;
  volume: string;
  tipoDeCarga: string[];
  tipoEmbalagem: string;
  altura: string;
  largura: string;
  comprimento: string;
  cubagem: string;
  numeroPedido: string;
  numeroNota: string;
  nomeTransportadora: string;
  cnpjTransportadora: string;
  telefoneTransportadora: string;
  emailTransportadora: string;
  nomeDestinatario: string;
  documentoDestinatario: string;
}

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss'],
})
export class TrackingComponent implements OnInit {
  prefix: string;
  isContract: boolean = false;
  loaded: boolean = false;

  constructor(
    private trackingService: TrackingService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
  ) { }

  detailsOfOffer: any;
  detailsOfOfferSpot: OfferSpot;
  endereco: any;

  ngOnInit(): void {
    this.prefix = this.route.snapshot.paramMap.get('prefix');
    if (this.prefix == 'oferta-contrato') {
      this.isContract = true;
      this.getTracking();
    } else {
      this.isContract = false;
      this.getTrackingSpot()
    }
    this.breakpointObserver.observe([
      Breakpoints.Small,
      Breakpoints.HandsetPortrait,
      Breakpoints.Handset,
    ]).subscribe(result => {
      this.cdr.detectChanges(); // Força a detecção de mudanças
    });
  }

  getTracking() {
    this.loaded = false;
    const parameter = {
      empresaId: this.route.snapshot.paramMap.get('clientId'),
      ofertaId: this.route.snapshot.paramMap.get('ofertaId'),
      hash: this.route.snapshot.paramMap.get('hash'),
    };

    this.trackingService
      .getTrackingOrdemDeColeta(parameter, this.prefix)
      .pipe(first())
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          this.detailsOfOffer = {
            ...data,
            dataEmissaoColeta: this.formatDate(data.dataEmissaoColeta),
            dataPrevistaColeta: this.formatDate(data.dataPrevistaColeta),
            dataPrevistaEntrega: this.formatDate(data.dataPrevistaEntrega),
          };
          this.endereco =
            this.detailsOfOffer.destinoLogradouro +
            ', ' +
            this.detailsOfOffer.destinoNumero;
          console.log(this.detailsOfOffer);
        }
        this.loaded = true;
      });
  }

  getTrackingSpot() {
    this.loaded = false;
    const parameter = {
      empresaId: this.route.snapshot.paramMap.get('clientId'),
      ofertaId: this.route.snapshot.paramMap.get('ofertaId'),
      hash: this.route.snapshot.paramMap.get('hash'),
    };

    this.trackingService
      .getTrackingOrdemDeColetaSpot(parameter, this.prefix)
      .pipe(first())
      .subscribe((data: OfferSpot) => {
        if (data) {
          this.detailsOfOfferSpot = {
            ...data,
          }
        }
        this.loaded = true;
      });
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    if (!dateString || isNaN(date.getTime())) {
      return ''; // Retorna uma string vazia se a data não for válida
    }
    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  print() {
    window.scroll(0, 0);
    setTimeout(() => {
      window.print();
    }, 500);
    clearTimeout(300)
  }

  cpfOrCnpjMask(document: any): string | null {
    const value = document ? document.toString().replace(/\D/g, '') : '';

    if (value.length <= 11) {
      return value
        .replace(/\D/g, '')
        .replace(/^(\d{3})(\d)/, '$1.$2')
        .replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3')
        .replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d{1,2})/, '$1.$2.$3-$4');
    } else {
      return value
        .replace(/\D/g, '')
        .replace(/^(\d{2})(\d)/, '$1.$2')
        .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
        .replace(/^(\d{2})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3/$4')
        .replace(
          /^(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})(\d{1,2})/,
          '$1.$2.$3/$4-$5'
        );
    }
  }

  cepMask(document: any): string | null {
    if (!document) return null;

    const onlyNumbers = document.toString().replace(/\D/g, '');
    return onlyNumbers.replace(/^(\d{5})(\d{3})$/, '$1-$2');
  }

}
