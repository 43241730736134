<section class="top-nav">
  <div>
    <a id="link-home-landingpage" class="navbar-brand px-3" href="#">
      <img
        src="assets/img/svg/logo-plataforma-de-frete-small.svg"
        alt="Logo app"
        class="img-fluid navbar-logo"
      />
    </a>
  </div>
  <input id="menu-toggle" type="checkbox"/>
  <label class="menu-button-container" for="menu-toggle">
    <div class="menu-button"></div>
  </label>
  <ul class="menu">
    <li>
      <a
        id="link-about-landingpage"
        mat-button
        class="nav-link label"
        href="https://www.senior.com.br/"
        target="_blank"
        [ngClass]="{
          'label-about': currentRoute === '/'
        }"
        (click)="hiddenMenu()"
      >
        Sobre nós
      </a>
    </li>
    <li>
      <a
        id="link-shipper-landingpage"
        mat-button
        class="nav-link label"
        routerLink="/im-a-shipper"
        [ngClass]="{
          'active-embarcador': currentRoute === '/im-a-shipper'
        }"
        (click)="hiddenMenu()"
        >Sou embarcador</a
      >
    </li>
    <li>
      <a
        id="link-carrier-landingpage"
        mat-button
        class="nav-link label"
        routerLink="/im-a-carrier"
        [ngClass]="{
          'active-transportador': currentRoute === '/im-a-carrier'
        }"
        (click)="hiddenMenu()"
        >Sou transportador</a
      >
    </li>
    <li class="margin-btnlogin">
      <a
       id="link-login-landingpage"
       mat-button class="nav-link btn-login" routerLink="login" (click)="hiddenMenu()"
        ><i class="ri-user-3-line user-icon"></i>Entrar</a
      >
    </li>
    <li 
      id="link-language-landingpage"
      class="margin-btnlanguage">
      <ng-select
        class="label"
        [items]="languages"
        [clearable]="false"
        [(ngModel)]="selectedLanguage"
        bindLabel="label"
        bindValue="value"
        required
      >
        <ng-template
          ng-option-tmp
          ng-label-tmp
          let-item="item"
          let-index="index"
          let-search="searchTerm"
        >
          <img height="15" width="15" src="/assets/img/svg/brasil.svg" alt="" />
          {{ item.label }}
        </ng-template>
      </ng-select>
    </li>
  </ul>
</section>
