<mat-card class="container-privileges">
  <mat-card-content>
    <h6>Privilégios de acesso</h6>
    <form
      action=""
      method="post"
      [formGroup]="privilegesForm"
      (ngSubmit)="submitForm()"
    >
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column" fxFlex>
          <div class="form-group mt-4 left">
            <label for="" class="label-input form-label required">
              Nome de usuário
            </label>
            <input
              type="text"
              class="input-fields form-control"
              placeholder="Administrador"
              id="user"
              name="user"
              formControlName="user"
              required
              [ngClass]="{
                'is-invalid':
                  privilegesForm.get('user')?.invalid &&
                  privilegesForm.get('user')?.touched,
                'is-valid':
                  privilegesForm.get('user')?.valid &&
                  privilegesForm.get('user')?.touched
              }"
            />
            <div
              *ngIf="
                (privilegesForm.get('user')?.errors &&
                  privilegesForm.get('user')?.touched) ||
                privilegesForm.get('user')?.dirty
              "
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex>
          <div class="form-group mt-4 right">
            <label for="" class="label-input form-label required">
              Observação
            </label>
            <input
              type="text"
              class="input-fields form-control"
              placeholder="Usuário que possui acesso a tudo"
              id="comments"
              name="comments"
              formControlName="comments"
              required
              [ngClass]="{
                'is-invalid':
                  privilegesForm.get('comments')?.invalid &&
                  privilegesForm.get('comments')?.touched,
                'is-valid':
                  privilegesForm.get('comments')?.valid &&
                  privilegesForm.get('comments')?.touched
              }"
            />
            <div
              *ngIf="
                (privilegesForm.get('comments')?.errors &&
                  privilegesForm.get('comments')?.touched) ||
                privilegesForm.get('comments')?.dirty
              "
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
          </div>
        </div>
      </div>
      <p class="mt-4">Permissionamentos</p>
      <div *ngFor="let title of listTitle; let i = index">
        <app-accordion-check
          [lbAccordion]="title.title"
          [headerTable]="listHeaders[0]"
          [ELEMENT_DATA]="listTables[i]"
          [hideTable]="!listTables[i].length"
        ></app-accordion-check>
      </div>
      <div class="d-flex flex-row-reverse mt-5">
        <div>
          <button
            mat-raised-button
            color="primary"
            class="save-button"
            (ngSubmit)="submitForm()"
            [disabled]="privilegesForm.invalid"
          >
            Salvar
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
