import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';

import { ConfigurationsCarrierRoutingModule } from './configurations-carrier-routing.module';
import { MyCompaniesComponent } from './my-companies/my-companies.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { MyUsersComponent } from './my-users/my-users.component';
import { UserTypeComponent } from './user-type/user-type.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';

import { FlexLayoutModule } from '@angular/flex-layout';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { AccessPrivilegesComponent } from './access-privileges/access-privileges.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ConfigurationsCarrierComponent } from './configurations-carrier.component';
import { MyCompanyComponent } from './my-company/my-company.component';
import { GeneralInformationComponent } from './general-information/general-information.component';
import { RequirementsRegisterComponent } from './requirements-register/requirements-register.component';
import { CertificationsComponent } from './certifications/certifications.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CpfCnpjCarrierPipe } from './cpfCnpj.pipe';
import { MyCompanyFormComponent } from './my-company-form/my-company-form.component';
import { DialogCompanyCreatedComponent } from './dialog-company-created/dialog-company-created.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    MyCompaniesComponent,
    MyProfileComponent,
    MyUsersComponent,
    UserTypeComponent,
    UserRegistrationComponent,
    ConfigurationsCarrierComponent,
    AccessPrivilegesComponent,
    MyCompanyComponent,
    GeneralInformationComponent,
    RequirementsRegisterComponent,
    CertificationsComponent,
    CpfCnpjCarrierPipe,
    MyCompanyFormComponent,
    DialogCompanyCreatedComponent
  ],
  imports: [
    CommonModule,
    ConfigurationsCarrierRoutingModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    MatToolbarModule,
    MatCardModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressBarModule,
    NgSelectModule,
    NgxMaskDirective,
    NgxMaskPipe,
    CdkAccordionModule,
    MatExpansionModule,
    FlexLayoutModule,
    MatTabsModule,
    NgxDropzoneModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatDialogModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class ConfigurationsCarrierModule {}
