import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MyCompaniesService } from './my-companies.service';
import { first } from 'rxjs';
import {} from 'src/app/carrier/company-details/company-details.component';
import { MaskService } from 'src/app/shared/services/mask.service';
import { PermissaoService } from 'src/app/shared/services/permissao.service';
import { DialogAttachmentComponent } from 'src/app/shared/dialog-attachment/dialog-attachment.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { DialogConfirmComponent } from 'src/app/shared/dialog-confirm/dialog-confirm.component';
import moment from 'moment';
import { SnackbarDefaultService } from 'src/app/shared/services/snackbar-default.service';
import { error } from 'highcharts';

export interface HeaderTable {
  lbCorporateName: string;
  lbCnpj: string;
  lbAddress: string;
  lbProductType: string;
  lbStatus: string;
  lbActions: string;
}

@Component({
  selector: 'app-my-companies',
  templateUrl: './my-companies.component.html',
  styleUrls: ['./my-companies.component.scss'],
})
export class MyCompaniesComponent implements OnInit {
  routePath: string = '';
  newCompany: string = '';
  editCompany: string = '';
  loaded: boolean = true;
  optionsCSV: string[] = ['Baixar modelo CSV', 'Importar CSV'];
  statusAction: string = '';

  displayedColumns: string[] = [
    'corporateName',
    'cnpj',
    'address',
    'productType',
    'status',
    'actions',
  ];
  dataSource = new MatTableDataSource();

  options: string[] = ['Editar'];

  page: number = 0;
  size: number = 50;
  totalElements: number = 0;
  gridData: any = [];

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  headerTable: HeaderTable = {
    lbCorporateName: 'Razão Social',
    lbCnpj: 'CNPJ',
    lbAddress: 'Endereço',
    lbProductType: 'Tipo de produto padrão',
    lbStatus: 'Status',
    lbActions: 'Ações',
  };

  isAdmin: boolean = false;

  constructor(
    private router: Router,
    private myCompaniesService: MyCompaniesService,
    private maskService: MaskService,
    private permissaoService: PermissaoService,
    private dialog: MatDialog,
    private _snackBar: SnackbarDefaultService,
  ) {}

  ngOnInit(): void {
    this.routePath = window.location.pathname;
    this.newCompany = `${this.routePath}/new-company`;
    this.editCompany = `${this.routePath}/edit-company`;
    this.isAdmin = this.permissaoService.isAdmin();
    this.getCompanies();
  }

  public redirect(action: string, row: any) {
    if (action === 'Editar') {
      this.router.navigate([this.editCompany, row.id]).catch((err) => err);
    }else if(action === 'Inativar' || action === 'Ativar'){
      this.changeStatus(row);
    }
  }

  public getCompanies(status?: string) {
    const size = this.size;
    const page = this.page;

    const paramsObj = {
      page,
      size,
      status,
    };

    this.myCompaniesService
      .getCompanies(paramsObj)
      .pipe(first())
      .subscribe((companies: any) => {
        this.gridData = companies.content?.map((company: any) => ({
          id: company.id,
          corporateName: company.razaoSocial.toUpperCase(),
          cnpj: this.maskService.formatCNPJ(company.cnpj),
          address: this.getAddress(company.endereco),
          productType:
            company.produto?.length > 0
              ? this.formatProductType(company.produto[0])
              : '',
          status: company.ativa ? 'Ativo' : 'Inativo',
          actions: ['Editar', company.ativa ? 'Inativar' : 'Ativar'],
          dataUltimaModificacao: company.dataUltimaModificacao,
          usuarioUltimaModificacao: company.usuarioUltimaModificacao,
        }));
        this.totalElements = companies.totalElements;
        this.dataSource = new MatTableDataSource(this.gridData);
      });
  }

  public getAddress(adress: any) {
    return `${adress.cidade} - ${adress.uf}`;
  }

  public handlePageEvent(e: PageEvent) {
    if (this.size != e.pageSize) {
      this.page = 0;
    } else {
      this.page = e.pageIndex;
    }
    this.size = e.pageSize;
    this.getCompanies();
  }

  formatProductType(product: string) {
    switch (product) {
      case 'CARGAS_FRIGORIFICAS_PERECIVEIS':
        return 'Cargas Frigoríficas - Perecíveis';
      case 'CARGAS_FRIGORIFICAS_CONGELADAS':
        return 'Cargas Frigoríficas - Congeladas';
      case 'CARGAS_A_GRANEL_LIQUIDO':
        return 'Cargas a Granel - Líquido';
      case 'CARGAS_A_GRANEL_SOLIDO':
        return 'Cargas a Granel - Sólido';
      case 'CARGAS_VIVAS':
        return 'Cargas Vivas';
      case 'CARGAS_INDIVISIVEIS_E_EXCEPCIONAIS_DE_GRANDE_PORTE':
        return 'Cargas Indivisíveis e excepcionais de grande porte';
      case 'CARGAS_SECAS':
        return 'Cargas Secas';
      case 'CARGAS_PERIGOSAS':
        return 'Cargas Perigosas';
      case 'QUIMICO':
        return 'Químico';
      case 'OUTROS':
        return 'Outros';
      default:
        return '';
    }
  }

  redirectActionsCSV(action: string) {
    if (action === 'Baixar modelo CSV') {
      this.csvDownload();
    } else {
      this.openDialogAttachment();
    }
  }

  public csvDownload() {
    let element = document.createElement('a');
    element.setAttribute(
      'href',
      `https://plataformadefretes-csv.s3.amazonaws.com/${environment.AMBIENTE}/csv/exemplo/empresas.csv`
    );
    console.log(element);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  openDialogAttachment() {
    const dialog = this.dialog.open(DialogAttachmentComponent, {
      data: {
        title: 'Importar empresas',
        type: 'companyCSV',
        description:
          'Arquivos suportados: .csv ou .xlsx. Se .csv, o separador das células deve ser vírgula(,).',
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        setTimeout(() => {
          this.getCompanies();
        }, 2000);
      }
    });
  }

  filterStatus(event: any) {
    let active = document.getElementById('check-active') as HTMLInputElement;
    let inactive = document.getElementById(
      'check-inactive'
    ) as HTMLInputElement;

    if (event.checked && event.id == 'check-active') {
      inactive.checked = false;
      this.getCompanies('true');
    } else if (event.checked && event.id == 'check-inactive') {
      active.checked = false;
      this.getCompanies('false');
    } else {
      active.checked = false;
      inactive.checked = false;
      this.getCompanies();
    }
  }

  changeStatus(row: any) {
    let text = row.status == 'Ativo' ? 'INATIVAR' : 'ATIVAR';

    let description =
      row.status == 'Ativo'
        ? `Ao inativar a empresa ela perderá o vínculo com todos os usuários e não poderá ser selecionada para novas ofertas`
        : `Ao ativar a empresa ela terá vínculo com seu usuário e poderá ser selecionada para novas ofertas`;

    let obj = {
        title: 'Confirmação',
        description,
        msgConfirm: `Tem certeza que deseja ${text} essa empresa?`,
    };

    if(row.dataUltimaModificacao){
      let lastModificationDate = this.formatDate(new Date(row.dataUltimaModificacao));
      let lastModificationUser = row.usuarioUltimaModificacao.nome;
      let lastModification = `Última modificação em: ${lastModificationDate} por ${lastModificationUser}`;
      Object.assign(obj, {lastModification: lastModification});
    }

    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: obj,
    });

    let changes = {
      idEmpresa: row.id,
      status: row.status == 'Ativo' ? 'false' : 'true',
    };

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.myCompaniesService
          .updateStatus(changes)
          .pipe(first())
          .subscribe( {
            next: () => {
              this._snackBar.openSnackBarSuccess(
                `Empresa alterada com sucesso`
              );
              setTimeout(() => {
                this.getCompanies();
              }, 500);
              this.cleanerFilter();
            },
            error: (error) => { 
              this._snackBar.openSnackBarError(
                error.error
              );
            }
          });
      }
    });


  }

  formatDate(date: Date) {
    return moment(date).format('DD/MM/yyyy HH:mm');
  }

  cleanerFilter(){
    let active = document.getElementById('check-active') as HTMLInputElement;
    let inactive = document.getElementById(
      'check-inactive'
    ) as HTMLInputElement;
    active.checked = false;
    inactive.checked = false;
  }
}
