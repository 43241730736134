<div class="container-fluid mt-5">
    <div class="row justify-content-center" *ngIf="!formEnded">
      <div class="col-md-4 text-center">
        <h4>Redefina sua senha</h4>
        <img src="/assets/img/svg/Registration.svg" alt="" />
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-8">
        <mat-stepper linear #stepper>
          <mat-step [stepControl]="redefinePasswordForm">
            <form [formGroup]="redefinePasswordForm">
                <div class="card-body container">
                      <h5>Redefinir senha</h5>
                    <div fxLayout="row" fxLayoutAlign="space-between">
                      <div fxLayout="column" fxFlex class="form-group position-relative right">
                        <label class="label-input form-label required" for="password">
                          Senha
                        </label>
                        <input
                          matInput
                          id="password"
                          name="password"
                          type="password"
                          class="form-control icon input-data w-100"
                          formControlName="password"
                          placeholder="Digite uma senha"
                          required
                          [ngClass]="{
                            'is-invalid':
                              validFieldsForm(redefinePasswordForm, 'password') && emptyField,
                            'is-valid':
                              validFieldsForm(redefinePasswordForm, 'password') && !emptyField
                          }"
                        />
                        <div
                          *ngIf="validFieldsForm(redefinePasswordForm, 'password') && emptyField"
                          class="invalid-feedback label-mandatory"
                        >
                          Campo obrigatório*
                        </div>
                        <i
                          class="ri-lock-line password-icon position-absolute end-75 mx-2 py-1"
                        ></i>
                      </div>
                      <div fxLayout="column" fxFlex class=" form-group position-relative left">
                        <label
                          class="label-input form-label required"
                          for="confirmPassword"
                        >
                          Confirme a sua senha
                        </label>
                        <input
                          matInput
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          class="form-control icon input-data w-100"
                          formControlName="confirmPassword"
                          placeholder="Digite novamente sua senha"
                          required
                          (change)="validConfirmPassword()"
                          [ngClass]="{
                            'is-invalid':
                              (validFieldsForm(redefinePasswordForm, 'confirmPassword') &&
                                emptyField) ||
                              (!confirmPasswordValue && !emptyField),
                            'is-valid':
                              validFieldsForm(redefinePasswordForm, 'confirmPassword') &&
                              !emptyField &&
                              confirmPasswordValue
                          }"
                        />
                        <div
                          *ngIf="
                            validFieldsForm(redefinePasswordForm, 'confirmPassword') && emptyField
                          "
                          class="invalid-feedback label-mandatory"
                        >
                          Campo obrigatório*
                        </div>
                        <div
                          *ngIf="
                            validFieldsForm(redefinePasswordForm, 'confirmPassword') &&
                            !emptyField &&
                            !confirmPasswordValue
                          "
                          class="invalid-feedback label-mandatory"
                        >
                          Confirmação de senha incorreta*
                        </div>
                        <i
                          class="ri-lock-line password-icon position-absolute end-75 mx-2 py-1"
                        ></i>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col col-md-6">
                        <div class="form-check">
                          <input
                            name="showPassword"
                            type="checkbox"
                            class="form-check-input"
                            (change)="visiblePassword($event, 'password')"
                          />
                          <label
                            class="form-check-label view-password mt-1"
                            for="showPassword"
                          >
                            Mostrar senha
                          </label>
                        </div>
                      </div>
                      <div class="col col-md-6">
                        <div class="form-check">
                          <input
                            name="showConfirmPassword"
                            type="checkbox"
                            class="form-check-input"
                            (change)="visiblePassword($event, 'confirmPassword')"
                          />
                          <label
                            class="form-check-label view-password mt-1"
                            for="showConfirmPassword"
                          >
                            Mostrar senha
                          </label>
                        </div>
                      </div>
                    </div>


                    <p class="text-password">Sua nova senha deve conter:</p>

                    <div>
                      <p>
                        <i
                          *ngIf="emptyPassword || (!emptyPassword && validLength)"
                          class="ri-checkbox-circle-fill icon-check"
                          [ngClass]="{
                            'check-none': emptyPassword,
                            'check-yes': !emptyPassword && validLength
                          }"
                        ></i
                        ><i
                          class="ri-close-circle-fill icon-check check-no"
                          *ngIf="!emptyPassword && !validLength"
                        ></i
                        >Entre 8 e 30 caracteres
                      </p>
                      <p>
                        <i
                          *ngIf="
                            emptyPassword || (!emptyPassword && validUpper && validLower)
                          "
                          class="ri-checkbox-circle-fill icon-check"
                          [ngClass]="{
                            'check-none': emptyPassword,
                            'check-yes': !emptyPassword && validUpper && validLower
                          }"
                        ></i
                        ><i
                          class="ri-close-circle-fill icon-check check-no"
                          *ngIf="!emptyPassword && (!validUpper || !validLower)"
                        ></i
                        >Letras maiúsculas e minúsculas
                      </p>
                      <p>
                        <i
                          *ngIf="emptyPassword || (!emptyPassword && validNumber)"
                          class="ri-checkbox-circle-fill icon-check"
                          [ngClass]="{
                            'check-none': emptyPassword,
                            'check-yes': !emptyPassword && validNumber
                          }"
                        ></i
                        ><i
                          class="ri-close-circle-fill icon-check check-no"
                          *ngIf="!emptyPassword && !validNumber"
                        ></i
                        >Pelo menos um número
                      </p>
                      <p>
                        <i
                          *ngIf="
                            emptyPassword || (!emptyPassword && validSpecialCharacter)
                          "
                          class="ri-checkbox-circle-fill icon-check"
                          [ngClass]="{
                            'check-none': emptyPassword,
                            'check-yes': !emptyPassword && validSpecialCharacter
                          }"
                        ></i
                        ><i
                          class="ri-close-circle-fill icon-check check-no"
                          *ngIf="!emptyPassword && !validSpecialCharacter"
                        ></i
                        >Pelo menos um caractere especial
                      </p>
                    </div>

                    <div class="row justify-content-end">
                      <div class="col col-md-2 text-end">
                        <button
                          mat-flat-button
                          matStepperNext
                          color="primary"
                          class="step-next-button"
                          (click)="returnOK(stepper)"
                          [disabled]="redefinePasswordForm.invalid || !confirmPasswordValue"
                        >
                          Enviar
                        </button>
                      </div>
                    </div>
                  </div>
            </form>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel>Conclusão</ng-template>
            <div class="card-body container">
              <div class="row mb-2 text-center">
                <h5 class="complete-title">{{ titleConclusion }}</h5>
              </div>
              <div class="row mb-2 justify-content-center text-center">
                <p class="complete-text px-4" id="textConclusion">
                  {{ bodyConclusion }}
                </p>
              </div>
              <div class="row mb-2 justify-content-center">
                <a routerLink="/login" mat-flat-button color="primary" class="access-platform">Acessar plataforma</a>
              </div>
              <div class="row mb-2 justify-content-center">
                <div class="col-md-4">
                  <img src="/assets/img/svg/New-message-pana.svg" alt="" />
                </div>
              </div>
            </div>
          </mat-step>
        </mat-stepper>
      </div>
    </div>
  </div>
  