import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CarrierRegistrationService {
  constructor(private http: HttpClient) {}

  registerCarrier(
    carrier: any,
    hash: string,
    clientId: string,
    carrierId: string,
    file: any
  ) {
    let form: FormData = new FormData();
    form.append('file', file);

    const headers = new HttpHeaders();
    headers.append('responseType', 'text');
    headers.append('Content-Type', 'multipart/form-data');
    form.append('entityDTO', JSON.stringify(carrier));

    return this.http
      .post<any>(
        `${environment.API_BACK}/convite/convite-aceito/${hash}/${clientId}/${carrierId}`,
        form
      )
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }
}
