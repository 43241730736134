<mat-card class="container-companies" *ngIf="loaded; else isLoading">
    <mat-card-content>
      <h6>Minhas empresas</h6>
      <div class="d-flex mt-4">
        <div *ngIf="isAdmin">
          <a [routerLink]="[newCompany]" mat-flat-button class="new-button">
            <i class="ri-add-line"></i> Novo
          </a>
        </div>
      </div>
      <table mat-table [dataSource]="dataSource" class="table mt-4">
        <caption hidden>
          Tabela minhas empresas
        </caption>
        <ng-container matColumnDef="corporateName">
          <th mat-header-cell *matHeaderCellDef class="table-head-color">
            {{ headerTable.lbCorporateName }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            {{ element.corporateName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="cnpj">
          <th mat-header-cell *matHeaderCellDef class="table-head-color">
            {{ headerTable.lbCnpj }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            {{ element.cnpj | cpfCnpjCarrier }}
          </td>
        </ng-container>
  
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef class="table-head-color">
            {{ headerTable.lbAddress }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            {{ element.address }}
          </td>
        </ng-container>
  
        <ng-container matColumnDef="actions">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-head-color column-actions"
          >
            {{ headerTable.lbActions }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            <button mat-button [matMenuTriggerFor]="menu" class="dropdown-button">
              {{ headerTable.lbActions }}
              <i class="ri-arrow-down-s-line"></i>
            </button>
            <mat-menu #menu="matMenu">
              <div *ngIf="isAdmin">
                <a
                  mat-menu-item
                  class="dropdown-button-item"
                  *ngFor="let action of options"
                  (click)="redirect(action, element)"
                >
                  {{ action }}
                </a>
              </div>
              <div *ngIf="!isAdmin">
                <a
                  mat-menu-item
                  class="dropdown-button-item"
                >
                  -
                </a>
              </div>
            </mat-menu>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
        (page)="handlePageEvent($event)"
        [length]="totalElements"
        [pageIndex]="page"
        [pageSize]="size"
      >
      </mat-paginator>
    </mat-card-content>
  </mat-card>
  
  <ng-template #isLoading>
    <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
  </ng-template>
  