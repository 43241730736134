import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LayoutComponent } from './layout.component'
import { RouterModule } from '@angular/router'

import { MatListModule } from '@angular/material/list'

import { SharedModule } from '../shared/shared.module'
import { PublicLayoutComponent } from './public/public.component'
import { PrivateLayoutComponent } from './private/private.component'
import { ConfigurationsCarrierModule } from '../configurations-carrier/configurations-carrier.module'
import { ConfigurationsShipperModule } from '../configurations-shipper/configurations-shipper.module'

@NgModule({
  declarations: [
    LayoutComponent,
    PublicLayoutComponent,
    PrivateLayoutComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    ConfigurationsCarrierModule,
    ConfigurationsShipperModule,
    MatListModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LayoutModule {}
