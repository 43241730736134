import { Component, DoCheck, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';
import { IntegrationsService } from 'src/app/configurations-shipper/integrations/integrations.service';
import { Router } from '@angular/router';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { PermissaoService } from '../services/permissao.service';
import { SnackbarDefaultService } from '../services/snackbar-default.service';
import { PortalLoginComponent } from 'src/app/carrier-portal/portal-login/portal-login.component';
import { CarrierPortalService } from 'src/app/carrier-portal/carrier-portal.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, DoCheck {
  routePath: string = '';
  isCarrier: boolean = false;
  isShipper: boolean = false;

  //home transportadora
  offersCarrier: string = '';
  myShippers: string = '';
  configurationsCarrier: string = '';
  newsCarrier: string = '';
  homeCarrier: string = '';
  allOffersShipper: string = '';

  //home embarcador
  configurationsShipper: string = '';
  offersShipper: string = '';
  contractOffer: string = '';
  newsShipper: string = '';
  carriers: string = '';
  homeShipper: string = '';
  integration: string = '';

  payments: string = '';
  carrierTutorials: string = '';
  shipperTutorials: string = '';
  viewDashboard: boolean = environment.DASHBOARD;
  viewPayments: boolean = environment.PAYMENTS;
  viewPortal: boolean = environment.PORTAL
  isAdmin: boolean = false;
  session: any;
  idsessao: any;

  user = JSON.parse(localStorage.getItem('usuario')!);

  constructor(
    private authService: AuthService,
    private integrationsService: IntegrationsService,
    private router: Router,
    private dialog: MatDialog,
    private permissaoService: PermissaoService,
    private snackbar: SnackbarDefaultService
  ) {}

  ngOnInit(): void {
    this.routePath = window.location.pathname;
    this.user = JSON.parse(localStorage.getItem('usuario')!);
    this.isAdmin = this.user?.tipoUsuario == 1;
    this.redirectIcon();
  }

  ngDoCheck(): void {
    this.routePath = window.location.pathname;
    this.user = JSON.parse(localStorage.getItem('usuario')!);
    this.isAdmin = this.user?.tipoUsuario == 1;
    this.session = JSON.parse(sessionStorage.getItem('session'));
    this.idsessao = this.session?.idsessao;
  }

  setTab() {
    sessionStorage.setItem('tab', '0');
  }

  redirectIcon() {
    if (this.user.cliente.ofertaLance) {
      this.isCarrier = true;
      this.isShipper = false;
    } else {
      this.isShipper = true;
      this.isCarrier = false;
    }

    this.offersCarrier = `/carriers/offers`;
    this.myShippers = `/carriers/my-shippers`;
    this.configurationsCarrier = '/configurations-carrier/profile';
    this.newsCarrier = '/carriers';
    this.homeCarrier = '/carriers';

    this.configurationsShipper = '/configurations-shipper/profile';
    this.offersShipper = `/shipper/freight-offer/simplified-offer`;
    this.contractOffer = '/shipper/freight-offer/contract-offer';
    this.integration = '/configurations-shipper/integrations';
    this.newsShipper = '/shipper';
    this.carriers = `/shipper/my-carriers`;
    this.homeShipper = '/shipper';
    this.allOffersShipper = '/shipper/freight-offer';

    this.payments = '/payments/invoice-extract';
    this.carrierTutorials = '/carriers/tutorials';
    this.shipperTutorials = '/shipper/tutorials';
  }

  checkIntegrations() {
    this.integrationsService
      .getIntegration(this.user.id)
      .pipe()
      .subscribe((data) => {
        if (data.length > 0) {
          this.router.navigate([this.contractOffer]);
        } else {
          this.openDialog();
        }
      });
  }

  openDialog() {
    let dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: 'Oferta contrato não ativada',
        description:
          'O seu ambiente não possui uma integração ativa com um TMS para utilizar esta funcionalidade.',
        msgConfirm:
          'Deseja realizar a configuração do seu TMS neste ambiente ?',
        config: 'Configurar',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (this.isAdmin) {
          this.router.navigate([this.integration]);
        } else {
          this.snackbar.openSnackBarWarning(
            'Necessário privilégios de admin para acessar a página'
          );
        }
      }
    });
  }

  logout() {
    this.authService.logout();
  }

  loginPortal() {
    if (this.idsessao) {
      this.router.navigate(['portal']);
    } else {
      let dialogRef = this.dialog.open(PortalLoginComponent);

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.router.navigate(['portal']);
        }
      });
    }
  }
}
