import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarDefaultService } from 'src/app/shared/services/snackbar-default.service';
import { ConfigurationsService } from 'src/app/configurations/configurations.service';
import { first } from 'rxjs';
import { UFs } from 'src/app/shared/const/consts';

interface ViaCepResponse {
  bairro: string;
  cep: string;
  complemento: string;
  ddd: string;
  gia: string;
  ibge: string;
  localidade: string;
  logradouro: string;
  siafi: string;
  uf: string;
}

@Component({
  selector: 'app-recipient',
  templateUrl: './recipient.component.html',
  styleUrls: ['./recipient.component.scss'],
})
export class RecipientComponent implements OnInit {
  id: any;
  invalidZipCode: boolean = false;
  resultField: boolean = false;
  emptyField: boolean = true;

  UFs = UFs

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _snackBar: SnackbarDefaultService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private service: ConfigurationsService
  ) {}

  public recipientForm: FormGroup = this.formBuilder.group({
    name: ['', [Validators.required]],
    document: ['', [Validators.required]],
    zipcode: ['', [Validators.required]],
    province: ['', [Validators.required]],
    city: ['', [Validators.required]],
    street: ['', [Validators.required]],
    number: ['', Validators.compose([Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
    neighborhood: ['', [Validators.required]],
    complement: [''],
  });

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    if(this.id) this.getRecipient()
  }

  onZipcodeChange(value: string) {
    let el = document.getElementById('zipcode');
    if (value) {
      const protocol = location.protocol;
      let zipsub = this.http
        .get<ViaCepResponse>(`${protocol}//viacep.com.br/ws/${value}/json/`)
        .subscribe((zipData) => {
          if (!zipData.cep) {
            this.invalidZipCode = true;
            if (el.classList.contains('is-valid')) {
              el.classList.remove('is-valid');
              el.classList.add('is-invalid');
            } else {
              el.classList.add('is-invalid');
            }
            this.recipientForm.patchValue({
              province: '',
              city: '',
              street: '',
              complement: '',
              neighborhood: '',
            });
            zipsub.unsubscribe();
          } else {
            this.invalidZipCode = false;
            if (el.classList.contains('is-invalid')) {
              el.classList.remove('is-valid');
              el.classList.add('is-valid');
            } else {
              el.classList.add('is-valid');
            }
            this.recipientForm.patchValue({
              province: zipData.uf,
              city: zipData.localidade,
              street: zipData.logradouro,
              complement: zipData.complemento,
              neighborhood: zipData.bairro,
            });
            zipsub.unsubscribe();
          }
        });
    }
  }

  validFieldsForm(field: string) {
    this.resultField = false;
    this.emptyField = true;

    if (
      (this.recipientForm.get(field)?.value?.length === 0 ||
        this.recipientForm.get(field)?.value === '') &&
      this.recipientForm.get(field)?.touched &&
      this.recipientForm.get(field)?.invalid
    ) {
      this.resultField = true;
      this.emptyField = true;
    } else if (
      (this.recipientForm.get(field)?.value?.length > 0 ||
        this.recipientForm.get(field)?.value !== '') &&
      this.recipientForm.get(field)?.valid &&
      this.recipientForm.get(field)?.touched
    ) {
      this.resultField = true;
      this.emptyField = false;
    }

    return this.resultField;
  }

  private buildRecipient() {
    return {
      nome: this.recipientForm.value.name,
      documento: this.recipientForm.value.document,
      endereco: {
        cep: this.recipientForm.value.zipcode,
        uf: this.recipientForm.value.province,
        cidade: this.recipientForm.value.city,
        logradouro: this.recipientForm.value.street,
        numero: this.recipientForm.value.number,
        bairro: this.recipientForm.value.neighborhood,
        complemento: this.recipientForm.value.complement || '',
      },
    };
  }

  private fillinFields(data: any) {
    this.recipientForm.get('name').setValue(data.nome);
    this.recipientForm.get('document').setValue(data.documento);
    this.recipientForm.get('zipcode').setValue(data.endereco.cep);
    this.recipientForm.get('province').setValue(data.endereco.uf);
    this.recipientForm.get('city').setValue(data.endereco.cidade);
    this.recipientForm.get('street').setValue(data.endereco.logradouro);
    this.recipientForm.get('number').setValue(data.endereco.numero);
    this.recipientForm.get('neighborhood').setValue(data.endereco.bairro);
    this.recipientForm.get('complement')?.setValue(data.endereco.complemento);
  }

  getRecipient() {
    this.service
      .getRecipientById(this.id)
      .pipe()
      .subscribe((data) => {
        if (data) {
          this.fillinFields(data);
        }
      });
  }

  editRecipient(recipient: object) {
    this.service
      .createRecipient(Object.assign(recipient, { id: this.id }))
      .pipe(first())
      .subscribe({
        next: () => {
          this._snackBar.openSnackBarSuccess(
            'Destinatário atualizado com sucesso.'
          );
          setTimeout(() => {
            this.redirect();
          }, 1000);
        },
        error: () => {
          this._snackBar.openSnackBarError('Erro ao atualizar destinatário.');
        },
      });
  }

  redirect() {
    this.router
      .navigate([`configurations-shipper/recipients`])
      .catch((err) => err);
  }

  submitForm() {
    let recipient = this.buildRecipient();
    if (this.id) {
      this.editRecipient(recipient)
    } else {
      this.service
        .createRecipient(recipient)
        .pipe(first())
        .subscribe({
          next: () => {
            this._snackBar.openSnackBarSuccess(
              'Destinatário cadastrado com sucesso.'
            );
            setTimeout(() => {
              this.redirect();
            }, 1000);
          },
          error: () => {
            this._snackBar.openSnackBarError('Erro ao cadastrar destinatário.');
          },
        });
    }
  }
}
