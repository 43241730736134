import { Injectable } from '@angular/core';
import { first, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class OfferContractService {

  constructor(private http: HttpClient) { }

  saveConfigContractOffer(obj: any) {
    let usuario = JSON.parse(localStorage.getItem('usuario')!);

    obj.idCliente = usuario.cliente.id
    return this.http.post<any>(`${environment.API_BACK}/cliente/config-oferta-contrato`, obj).pipe(
      first(),
      map((dados) => {
        return dados;
      })
    );
  }


  getClients(id: any) {

    return this.http.get<any>(`${environment.API_FILTER}/cliente?id=${id}`).pipe(
      first(),
      map((data) => {
        return data;
      })
    );

  }

}
