import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  removeAccentuationForID(text: string) {
    let format = text.normalize('NFD').replace(/\p{Mn}/gu, '');
    
    // Substitui todos os espaços por hifens
    format = format.replace(/ /g, '-');

    // Remove quaisquer caracteres que não sejam letras, números ou hifens
    format = format.replace(/[^a-zA-Z0-9-]/g, '');

    format = format.toLowerCase();
    return format;
  }
}
