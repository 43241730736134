export interface TablePrivileges {
  id: number;
  columnA: string;
  columnB: string;
}

export interface HeaderTable {
  lbColumnA: string;
  lbColumnB: string;
}

export const profile: TablePrivileges[] = [
  {
    id: 1,
    columnA: 'Listar',
    columnB: 'Pode visualizar suas informações',
  },
  {
    id: 2,
    columnA: 'Editar',
    columnB: 'Pode editar as suas informações',
  },
];

export const payments: TablePrivileges[] = [
  {
    id: 1,
    columnA: 'Listar',
    columnB: 'Pode visualizar os pagamentos cadastros',
  },
  {
    id: 2,
    columnA: 'Novo',
    columnB: 'Pode adicionar novo pagamento',
  },
  {
    id: 3,
    columnA: 'Editar',
    columnB: 'Pode editar os cadastros dos pagamentos',
  },
  {
    id: 4,
    columnA: 'Remover',
    columnB: 'Pode deletar os cadastros dos pagamentos',
  },
];

export const carriers: TablePrivileges[] = [
  {
    id: 1,
    columnA: 'Listar',
    columnB: 'Pode visualizar todas as transportadoras cadastradas na base',
  },
  {
    id: 2,
    columnA: 'Detalhar',
    columnB: 'Pode Detalhar qualquer transportadora cadastradas na base',
  },
  {
    id: 3,
    columnA: 'Convidar',
    columnB: ' Pode Convidar qualquer transportadoras cadastradas na base',
  },
];

export const validationCriteria: TablePrivileges[] = [
  {
    id: 1,
    columnA: 'Listar',
    columnB: 'Pode visualizar os dados de homologação',
  },
  {
    id: 2,
    columnA: 'Editar',
    columnB: 'Pode editar os dados de homologação',
  },
];

export const headerTable: HeaderTable = {
  lbColumnA: 'Página',
  lbColumnB: 'Descrição',
};

export const titleAccordion: any = [
  {
    title: 'Configurações de perfil',
  },
  {
    title: 'Configurações de pagamento',
  },
  {
    title: 'Transportadoras',
  },
  {
    title: 'Critério de avaliação',
  },
];
