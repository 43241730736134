import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarDefaultService } from 'src/app/shared/services/snackbar-default.service';
import { certificacao_options } from 'src/app/shared/const/consts';
import { PageEvent } from '@angular/material/paginator';
import { CertificationService } from './certification.service';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';

export interface HeaderTable {
  lbCertifies: string;
  lbExpirationDate: string;
  lbAttachment: string;
  lbActions: string;
}

export interface PeriodicElement {
  id: string;
  certifies: string;
  expirationDate: string;
  attachment: any;
  actions: any;
}

@Component({
  selector: 'app-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.scss'],
})
export class CertificationsComponent {
  public formCertifications: FormGroup = this.formBuilder.group({
    certifies: ['', Validators.required],
    expirationDate: ['', Validators.required],
  });

  ngOnInit(): void {
    this.getCompany();
  }

  files: File[] = [];
  certifies = certificacao_options;
  page: number = 0;
  size: number = 50;
  link: string;

  id: any;
  gridData: any = [];
  dataSource = new MatTableDataSource();

  options: string[] = ['Remover'];

  headerTable: HeaderTable = {
    lbCertifies: 'Certificação',
    lbExpirationDate: 'Validade',
    lbAttachment: 'Anexo',
    lbActions: 'Ações',
  };

  displayedColumns: string[] = [
    'certifies',
    'expirationDate',
    'attachment',
    'actions',
  ];

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private certificationService: CertificationService,
    private _snackBar: SnackbarDefaultService
  ) {}

  onSelect(event: any) {
    if (this.files.length > 0) {
      this.files.splice(this.files.indexOf(event), 1);
    }
    this.files.push(...event.addedFiles);
    this.importFile();
  }

  public importFile() {
    if (this.files.length > 0) {
      this.certificationService
        .salvaAnexo(this.files[0])
        .pipe(first())
        .subscribe((url) => {
          this.link = environment.AWS_S3_ANEXO + url;
          this._snackBar.openSnackBarSuccess('Arquivo importado com sucesso');
        });
    } else {
      this._snackBar.openSnackBarWarning(
        'Não há arquivos para serem enviados.'
      );
    }
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  public handlePageEvent(e: PageEvent) {
    if (this.size != e.pageSize) {
      this.page = 0;
    } else {
      this.page = e.pageIndex;
    }
    this.size = e.pageSize;
    this.getCompany();
  }

  removeItem(label: any) {
    this.certificationService
      .deleteCertifies(this.getIdFromUrl(), label)
      .pipe(first())
      .subscribe({
        next: () => {
          this._snackBar.openSnackBarSuccess('Certificação Removida');
          this.getCompany();
        },
        error: () => {
          this._snackBar.openSnackBarError('Erro ao Remover Certificacao');
        }
  });
  }

  getIdFromUrl() {
    return this.route.snapshot.paramMap.get('id');
  }

  saveCertification() {
    let certificatonObj = {
      empresa: {
        id: this.getIdFromUrl(),
      },
      certificacaoList: [this.formCertifications.get('certifies')!.value],
      validade: moment(
        this.formCertifications.get('expirationDate')!.value
      ).format('yyyy-MM-DDTHH:mm:ss'),
      linkAnexo: this.link,
    };
    this.certificationService
      .saveCertification(Object.assign(certificatonObj))
      .pipe(first())
      .subscribe({
        next: () => {
          this._snackBar.openSnackBarSuccess('Certificações Adicionadas');
          this.formCertifications.reset();
          this.formCertifications.clearValidators();
          this.getCompany();
        },
        error: () => {
          this._snackBar.openSnackBarError('Erro ao Adicionar Certificacao');
        }
  });
  }

  getCompany() {
    this.certificationService
      .getCompany(this.getIdFromUrl())
      .pipe()
      .subscribe((data) => {
        this.gridData = data.certificacao?.map((element: any) => ({
          certifiesWithUndescore: element.certificacao,
          certifies: element.certificacao
            ? element.certificacao.replaceAll('_', ' ')
            : '',
          expirationDate: new Intl.DateTimeFormat('pt-BR').format(
            new Date(element.validade)
          ),
          attachment: element.linkAnexo,
        }));
        this.dataSource = this.gridData;
      });
  }
}
