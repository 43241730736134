<mat-accordion>
  <mat-expansion-panel [expanded]="!panel" [disabled]="panel" class="mt-3">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <label for="accordionCheck" class="label-switch cursor-switch-panel">{{
          lbAccordion
        }}</label>
        <div class="form-check form-switch">
          <input
            class="form-check-input cursor-switch-panel"
            type="checkbox"
            id="accordionCheck"
            (click)="$event.stopPropagation()"
            (change)="enablePanel()"
          />
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container-table">
      <table
        mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z1"
        *ngIf="!hideTable"
      >
        <caption hidden>
          Tabela
        </caption>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef class="table-head-color">
            <mat-checkbox
              (change)="onChangeAll($event, null)"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="onChange($event, selection, row)"
              [checked]="selection.isSelected(row)|| linhaSelecionada(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="columnA">
          <th mat-header-cell *matHeaderCellDef class="table-head-color">
            {{ headerTable.lbColumnA }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            {{ element.columnA }}
          </td>
        </ng-container>

        <ng-container matColumnDef="columnB" >
          <th mat-header-cell *matHeaderCellDef class="table-head-color">
            {{ headerTable.lbColumnB }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            {{ element.columnB }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="selection.toggle(row)"
        ></tr>
      </table>
    </div>
  </mat-expansion-panel>
</mat-accordion>
