<div
  id="layout-wrapper"
  *ngIf="isLogged; else userLoggedOut"
>
  <app-private-layout></app-private-layout>
</div>

<ng-template #userLoggedOut>
  <app-public-layout></app-public-layout>
</ng-template>
