import { Component, OnInit } from '@angular/core';
import { ConfigurationsShipperService } from '../configurations-shipper.service';
import { first } from 'rxjs';
import moment from 'moment';

interface NameColumns {
  lbPlan: string;
  lbTotalOffers: string;
  lbActivationDate: string;
  lbExpirationDate: string;
  lbTotalOffersMonth: string;
  lbSpotOffer: string;
  lbContractOffer: string
}

interface PaymentsInformation {
  plan: string;
  totalOffers: number | string;
  activationDate: string;
  expirationDate: string;
  totalOffersMonth: number;
  spotOffers: number;
  contractOffers: number;
}

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit {
  loaded: boolean = false;
  data: PaymentsInformation;
  id: string|number;
  user: any;

  nameColumns: NameColumns = {
    lbPlan: 'Plano',
    lbTotalOffers: 'Quantidade total de ofertas',
    lbActivationDate: 'Data de ativação',
    lbExpirationDate: 'Data de expiração do plano',
    lbTotalOffersMonth: 'Total de ofertas criadas',
    lbSpotOffer: 'Ofertas SPOT',
    lbContractOffer: 'Ofertas contrato'
  };

  constructor(private service: ConfigurationsShipperService) {}

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('usuario'));
    this.id = this.user.cliente.id;
    this.getCompany();
  }

  getCompany() {
    this.loaded = true;
    console.log(this.id);
    this.service
      .getTotalOfferts()
      .pipe(first())
      .subscribe((client) => {
        if (client != null) {
          console.log(client)
          this.data = {
            plan: client.plano || 'Sem informação',
            totalOffers: client.quantidadeOfertasPlano || 'Sem informação',
            activationDate: client.dataAtivacaoPlano ? this.formatDate(client.dataAtivacaoPlano) : null,
            expirationDate: client.dataEncerramentoPlano ? this.formatDate(client.dataEncerramentoPlano) : null,
            totalOffersMonth: client.ofertasMes || 0,
            spotOffers: client.ofertasSpotMes || 0,
            contractOffers: client.ofertasContratoMes || 0
          };
        }
        this.loaded = false;
      });
  }

  formatDate(date: Date) {
    return moment(date).format('DD/MM/yyyy');
  }
}
