import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent {
  title: string = '';
  description: string = '';
  config: string;
  msgConfirm: string = '';
  maxBid: boolean = false;
  lastModification: string = '';
  send : boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogConfirmComponent>
  ) {
    this.title = this.data.title;
    this.description = this.data?.description;
    this.msgConfirm = this.data?.msgConfirm;
    this.lastModification = this.data?.lastModification;
    this.config = this.data?.config;
    this.maxBid = this.data?.maxBid;
  }
  
  confirm(){
    this.dialogRef.close(!this.send)
  }
  
  close(){
    this.dialogRef.close(this.send)
  }
  

}
