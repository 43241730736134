export interface TablePrivileges {
  id: number;
  columnA: string;
  columnB: string;
}

export interface HeaderTable {
  lbColumnA: string;
  lbColumnB: string;
}

export const profile: TablePrivileges[] = [
  {
    id: 1,
    columnA: 'Listar',
    columnB: 'Pode visualizar suas informações',
  },
  {
    id: 2,
    columnA: 'Editar',
    columnB: 'Pode editar as suas informações',
  },
];

export const companies: TablePrivileges[] = [
  {
    id: 1,
    columnA: 'Listar',
    columnB: 'Pode visualizar os embarcadores cadastros',
  },
  {
    id: 2,
    columnA: 'Novo',
    columnB: 'Pode adicionar novo embarcadores',
  },
  {
    id: 3,
    columnA: 'Editar',
    columnB: 'Pode editar os cadastros dos embarcadores',
  },
  {
    id: 4,
    columnA: 'Remover',
    columnB: 'Pode deletar os cadastros dos embarcadores',
  },
];

export const users: TablePrivileges[] = [
  {
    id: 1,
    columnA: 'Listar ',
    columnB: 'Pode visualizar todos os usuários cadastrados',
  },
  {
    id: 2,
    columnA: 'Novo',
    columnB: 'Pode adicionar novos usuários',
  },
  {
    id: 3,
    columnA: 'Editar',
    columnB: 'Pode editar os dados do usuários',
  },
  {
    id: 4,
    columnA: 'Remover',
    columnB: 'Pode remover os usuários',
  },
];

export const userTypes: TablePrivileges[] = [
  {
    id: 1,
    columnA: 'Listar',
    columnB: 'Pode visualizar todos os tipos de usuários cadastrados',
  },
  {
    id: 2,
    columnA: 'Novo',
    columnB: 'Pode adicionar novos tipos de usuários',
  },
  {
    id: 3,
    columnA: 'Editar',
    columnB: ' Pode editar os tipos de usuários',
  },
  {
    id: 4,
    columnA: 'Remover',
    columnB: 'Pode remover os tipos de usuários',
  },
];

export const shipper: TablePrivileges[] = [
  {
    id: 1,
    columnA: 'Listar',
    columnB: 'Pode visualizar todos os embarcadores cadastrados na base',
  },
  {
    id: 2,
    columnA: 'Detalhar',
    columnB: 'Pode Detalhar qualquer embarcador cadastrados na base',
  },
];

export const invites: TablePrivileges[] = [
  {
    id: 1,
    columnA: 'Listar',
    columnB: 'Pode visualizar todos os convites realizados',
  },
  {
    id: 2,
    columnA: 'Aceitar',
    columnB: 'Pode aceitar os novos convites enviados',
  },
];

export const offer: TablePrivileges[] = [
  {
    id: 1,
    columnA: 'Listar',
    columnB: 'Pode visualizar as novas ofertas de fretes',
  },
  {
    id: 2,
    columnA: 'Listar',
    columnB: 'Pode visualizar as ofertas de fretes',
  },
];

export const offerThrow: TablePrivileges[] = [
  {
    id: 1,
    columnA: 'Enviar',
    columnB: 'Pode enviar um lance para uma oferta de frete',
  },
];

export const headerTable: HeaderTable = {
  lbColumnA: 'Página',
  lbColumnB: 'Descrição',
};

export const titleAccordion: any = [
  {
    title: 'Meu Perfil',
  },
  {
    title: 'Minhas Empresas',
  },
  {
    title: 'Meus Usuários',
  },
  {
    title: 'Tipos de Usuários',
  },
  {
    title: 'Embarcadores',
  },
  {
    title: 'Convites',
  },
  {
    title: 'Ofertas',
  },
  {
    title: 'Lances',
  },
];
