import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { AuthService } from './../shared/services/auth.service';
import { Router } from '@angular/router';
import { SnackbarDefaultService } from '../shared/services/snackbar-default.service';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  advancedFilfer = /explore-carriers|my-carriers/;
  constructor(
    private _snackBar: SnackbarDefaultService,
    private authenticationService: AuthService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authenticationService.getToken();
    const isLoggedIn = token != null;
    const isApiUrl = request.url.startsWith('https://utilities');
    const isApiUrlLocal = request.url.startsWith('http://localhost');

    if (isLoggedIn && (isApiUrl || isApiUrlLocal)) {
      request = request.clone({
        setHeaders: {
          Authorization: token,
        },
      });
    }
    
    
    if(!RegExp(/\/freight-offer/).exec(this.router.url)){
      let filter_spot = JSON.parse(localStorage.getItem('filter_spot'));
      let filter_order = JSON.parse(localStorage.getItem('filter_order'));
      let filter_contract = JSON.parse(localStorage.getItem('filter_contract'));

      if(filter_spot){
        localStorage.removeItem('filter_spot');
      }
      if(filter_order){
        localStorage.removeItem('filter_order');
      }
      if(filter_contract){
        localStorage.removeItem('filter_contract');
      }
    }

    if (!this.advancedFilfer.test(this.router.url)) {
      localStorage.removeItem('advancedFilterData');
    }

    return next.handle(request).pipe(
      tap({
        next: () => {},
        error: (err) => {
          if (err.status == 401) {
            this._snackBar.openSnackBarError("Seu token expirou, por favor realize o login novamente.");

            this.authenticationService.removeToken();
            const authenticated = sessionStorage.getItem('authenticated');
            if (authenticated) {
              sessionStorage.removeItem('authenticated');
            }

            this.router.navigate(['/login']);
          }else if(err.status == 500){
            return
          }
        },
      })
    );
  }

}
