<div class="container-quantity">
  <mat-dialog-title>
    <h6 class="mt-2">Quantidade de Veículos</h6></mat-dialog-title
  >
  <mat-dialog-content>
    <form action="" [formGroup]="formQuantity">
      <div *ngIf="type === 'edit'">
        <div fxLayout="row" fxLayoutAlign="space-between">
          <div fxLayout="column" fxFlex="70" fxLayoutAlign="center">
            <span>{{ vehicle }}</span>
          </div>
          <div fxLayout="column" fxFlex="30">
            <input
              type="text"
              id="quantity"
              name="quantity"
              formControlName="quantity"
              type="text"
              class="form-control pe-5 input-data w-100"
            />
          </div>
        </div>
      </div>

      <div *ngIf="type === 'add'" id="container-add">
        <div
          fxLayout="row"
          fxLayoutAlign="space-between" style="margin-bottom: 1rem;"
          *ngFor="let v of vehicle"
        >
          <div fxLayout="column" fxFlex="70" fxLayoutAlign="center" >
            <span>{{ v.label }}</span>
          </div>
          <div fxLayout="column" fxFlex="30">
            <input
              type="text"
              id="quantity"
              name="quantity"
              formControlName="quantity"
              type="text"
              class="form-control pe-5 input-data w-100"
            />
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <div class="d-flex flex-row-reverse mt-3">
    <div mat-dialog-actions>
      <button mat-flat-button mat-dialog-close class="btn-cancel">
        Cancelar
      </button>
      <button mat-flat-button color="primary" class="btn-send" mat-dialog-close (click)="save()">Salvar</button>
    </div>
  </div>
</div>
