import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { produto_options } from 'src/app/shared/const/consts';

@Component({
  selector: 'app-dialog-add-default-product',
  templateUrl: './dialog-add-default-product.component.html',
  styleUrls: ['./dialog-add-default-product.component.scss'],
})
export class DialogAddDefaultProductComponent implements AfterViewInit {
  productsType = produto_options;
  page: number = 0;
  size: number = 50;
  totalElements: number = this.productsType.length;
  product: any;

  displayedColumns = ['select', 'product'];

  dataSource = new MatTableDataSource(this.productsType);

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  constructor(
    private dialogRef: MatDialogRef<DialogAddDefaultProductComponent>
  ) {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  selectItem(item: any) {
    this.productsType.forEach((i) => {
      if (i !== item) {
        i.selected = false; // Desmarca os outros itens
      }
    });
    item.selected = !item.selected; // Inverte a seleção do item atual
    this.product = item;
  }

  public handlePageEvent(e: PageEvent) {
    if (this.size != e.pageSize) {
      this.page = 0;
    } else {
      this.page = e.pageIndex;
    }
    this.size = e.pageSize;
  }

  close() {
    this.dialogRef.close();
  }

  confirm() {
    this.dialogRef.close(this.product);
    this.product.selected = false
  }
}
