export interface TablePrivileges {
  id: number;
  columnA: string;
  columnB: string;
}

export interface HeaderTable {
  lbColumnA: string;
  lbColumnB: string;
}

export const profile: TablePrivileges[] = [
  {
    id: 1,
    columnA: 'Listar',
    columnB: 'Pode visualizar suas informações',
  },
  {
    id: 2,
    columnA: 'Editar',
    columnB: 'Pode editar as suas informações',
  },
];

export const companies: TablePrivileges[] = [
  {
    id: 1,
    columnA: 'Listar',
    columnB: 'Pode visualizar os embarcadores cadastros',
  },
  {
    id: 2,
    columnA: 'Novo',
    columnB: 'Pode adicionar novos embarcadores',
  },
  {
    id: 3,
    columnA: 'Editar',
    columnB: 'Pode editar os cadastros dos embarcadores',
  },
  {
    id: 4,
    columnA: 'Remover',
    columnB: 'Pode deletar os cadastros dos embarcadores',
  },
];

export const users: TablePrivileges[] = [
  {
    id: 1,
    columnA: 'Listar ',
    columnB: 'Pode visualizar todos os usuários cadastrados',
  },
  {
    id: 2,
    columnA: 'Novo',
    columnB: 'Pode adicionar novos usuários',
  },
  {
    id: 3,
    columnA: 'Editar',
    columnB: 'Pode editar os dados do usuários',
  },
  {
    id: 4,
    columnA: 'Remover',
    columnB: 'Pode remover os usuários',
  },
];

export const userTypes: TablePrivileges[] = [
  {
    id: 1,
    columnA: 'Listar',
    columnB: 'Pode visualizar todos os tipos de usuários cadastrados',
  },
  {
    id: 2,
    columnA: 'Novo',
    columnB: 'Pode adicionar novos tipos de usuários',
  },
  {
    id: 3,
    columnA: 'Editar',
    columnB: ' Pode editar os tipos de usuários',
  },
  {
    id: 4,
    columnA: 'Remover',
    columnB: 'Pode remover os tipos de usuários',
  },
];

export const carriers: TablePrivileges[] = [
  {
    id: 1,
    columnA: 'Listar',
    columnB: 'Pode visualizar todas as transportadoras cadastradas na base',
  },
  {
    id: 2,
    columnA: 'Detalhar',
    columnB: 'Pode Detalhar qualquer transportadora cadastradas na base',
  },
  {
    id: 3,
    columnA: 'Convidar',
    columnB: ' Pode Convidar qualquer transportadoras cadastradas na base',
  },
];

export const invites: TablePrivileges[] = [
  {
    id: 1,
    columnA: 'Listar',
    columnB: 'Pode visualizar todos os convites realizados',
  },
  {
    id: 2,
    columnA: 'Novo',
    columnB: 'Pode enviar ou importar novos convites',
  },
  {
    id: 3,
    columnA: 'Editar',
    columnB: 'Pode editar os convites',
  },
];

export const myCarriers: TablePrivileges[] = [
  {
    id: 1,
    columnA: 'Listar',
    columnB: 'Pode visualizar todas as transportadoras associadas a sua base',
  },
  {
    id: 2,
    columnA: 'Remover',
    columnB: 'Pode remover uma parceria com a transportadora da sua base',
  },
];

export const validationCriteria: TablePrivileges[] = [
  {
    id: 1,
    columnA: 'Listar',
    columnB: 'Pode visualizar os dados de homologação',
  },
  {
    id: 2,
    columnA: 'Editar',
    columnB: 'Pode editar os dados de homologação',
  },
];

export const offer: TablePrivileges[] = [
  {
    id: 1,
    columnA: 'Listar',
    columnB: 'Pode visualizar as ofertas de fretes',
  },
  {
    id: 2,
    columnA: 'Novo',
    columnB: 'Pode criar ou importar uma oferta de frete',
  },
  {
    id: 3,
    columnA: 'Editar',
    columnB: 'Pode editar uma oferta de frete',
  },
  {
    id: 4,
    columnA: 'Reabrir',
    columnB: 'Pode reabrir uma oferta de frete',
  },
  {
    id: 5,
    columnA: 'Cancelar',
    columnB: 'Pode cancelar uma oferta de frete',
  },
  {
    id: 6,
    columnA: 'Aprovar',
    columnB: 'Pode aprovar uma oferta enviada',
  },
];

export const order: TablePrivileges[] = [
  {
    id: 1,
    columnA: 'Listar',
    columnB: 'Pode visualizar os Pedido',
  },
  {
    id: 2,
    columnA: 'Novo',
    columnB: 'Pode criar ou importar um Pedido',
  },
  {
    id: 3,
    columnA: 'Editar',
    columnB: 'Pode editar um pedido',
  },
  {
    id: 4,
    columnA: 'Remover',
    columnB: 'Pode deletar um pedido',
  },
];

export const headerTable: HeaderTable = {
  lbColumnA: 'Página',
  lbColumnB: 'Descrição',
};

export const titleAccordion: any = [
  {
    title: 'Meu Perfil',
  },
  {
    title: 'Minhas Empresas',
  },
  {
    title: 'Meus Usuários',
  },
  {
    title: 'Tipos de Usuários',
  },
  {
    title: 'Transportadoras',
  },
  {
    title: 'Convites',
  },
  {
    title: 'Minhas Transportadoras',
  },
  // {
  //   title: 'Critérios de Validação',
  // },
  {
    title: 'Ofertas',
  },
  {
    title: 'Pedido',
  },
];
