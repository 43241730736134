import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DialogRequirementsRegisterComponent } from '../dialog-requirements-register/dialog-requirements-register.component';
import { MatDialog } from '@angular/material/dialog';
import { RequirementsService } from './requirements.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, first } from 'rxjs';
import { SnackbarDefaultService } from '../services/snackbar-default.service';
import { DialogQuantityVehicleComponent } from '../dialog-quantity-vehicle/dialog-quantity-vehicle.component';

@Component({
  selector: 'app-requirements',
  templateUrl: './requirements.component.html',
  styleUrls: ['./requirements.component.scss'],
})
export class RequirementsComponent implements OnInit {
  panelOpenState = false;

  @Input() title: string;
  @Input() datasource: any;
  @Input() headerTable: any;
  @Input() displayedColumns: any;
  @Output() updateData: EventEmitter<void> = new EventEmitter<void>();

  element: any;
  vehicle: string;
  quantidade: any;
  possuiRastreador: boolean;
  lookup: any;
  type: string = '';
  paineisAbertos: boolean[] = [];

  constructor(
    private dialog: MatDialog,
    private requirementsService: RequirementsService,
    private route: ActivatedRoute,
    private _snackBar: SnackbarDefaultService
  ) {}

  ngOnInit(): void {
  }

  remove(label: any, titlePanel: any) {
    let promise = null;
    if (titlePanel === 'Produtos') {
      promise = this.removeProduct(label);
    } else if (titlePanel === 'Veículos') {
      promise = this.removeVehicle(label);
    } else if (titlePanel === "UF's atendidas") {
      promise = this.removeUF(label);
    } else {
      promise = this.removeCarga(label);
    }
    promise.then(() => {
      this.updateData.emit();
    });
  }

  edit(row: any) {
    this.vehicle = row.label;
    this.quantidade = row?.quantidade;
    this.possuiRastreador = row?.possuiVeiculosComRastreador;
    this.lookup = row?.labelWithUndescore;
    const dialogRef = this.dialog.open(DialogQuantityVehicleComponent, {
      data: {
        vehicle: this.vehicle,
        quantity: this.quantidade,
        type: 'edit',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if(!result) return
      this.quantidade = result;
      this.editData(
        this.requirementsService.saveVeiculo.bind(this.requirementsService),
        'Quantidade do veículo alterada',
        'Erro ao Alterar quantidade do veículo'
      );
    });
  }

  editData(
    serviceFunction: (obj: any) => Observable<any>,
    successMessage: string,
    errorMessage: string
  ) {
    const obj = {
      empresa: {
        id: this.getIdFromUrl(),
        possuiVeiculosComRastreador: this.possuiRastreador,
      },
      lookup: [this.lookup],
      veiculoList: [{ veiculo: this.lookup, quantidade: this.quantidade }],
    };

    serviceFunction(Object.assign(obj))
      .pipe(first())
      .subscribe({
        next: () => {
          this._snackBar.openSnackBarSuccess(successMessage);
          this.updateData.emit();
        },
        error: () => {
          this._snackBar.openSnackBarError(errorMessage);
        }
  });
  }

  removeProduct(label: any): Promise<void> {
    return this.removeItem(
      this.requirementsService.deleteProduct.bind(this.requirementsService),
      label,
      'Produto Removido',
      'Erro ao Remover Produto'
    );
  }
  removeVehicle(label: any): Promise<void> {
    return this.removeItem(
      this.requirementsService.deleteVehicle.bind(this.requirementsService),
      label,
      'Veiculo Removido',
      'Erro ao Remover Veiculo'
    );
  }

  removeUF(label: any): Promise<void> {
    return this.removeItem(
      this.requirementsService.deleteUF.bind(this.requirementsService),
      label,
      'UF Removida',
      'Erro ao Remover UF'
    );
  }

  removeCarga(label: any): Promise<void> {
    return this.removeItem(
      this.requirementsService.deleteLoad.bind(this.requirementsService),
      label,
      'Carga Removida',
      'Erro ao Remover Carga'
    );
  }

  removeItem(
    serviceFunction: (obj: any) => Observable<any>,
    label: any,
    successMessage: string,
    errorMessage: string
  ) {
    return new Promise<void>((res, rej) => {
      let objPadrao = {
        empresa: {
          id: this.getIdFromUrl(),
        },
        label: label,
      };
      serviceFunction(objPadrao)
        .pipe(first())
        .subscribe({
          next: () => {
            this._snackBar.openSnackBarSuccess(successMessage);
            res();
          },
          error: (error) => {
            this._snackBar.openSnackBarError(errorMessage);
            rej(error);
          }
    });
    });
  }

  openDialogRequirementsRegister() {
    const dialogRef = this.dialog.open(DialogRequirementsRegisterComponent, {
      data: {
        type: this.title,
        id: this.route.snapshot.paramMap.get('id'),
        possuiVeiculosComRastreador:
          this.datasource[0]?.possuiVeiculosComRastreador,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.updateData.emit();
    });
  }

  getIdFromUrl() {
    return this.route.snapshot.paramMap.get('id');
  }
}
