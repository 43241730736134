<div class="container-fluid mt-5">
  <div class="card-body container">
    <div class="row mb-2 text-center">
      <h5 class="complete-title">Parceria não foi realizada!</h5>
    </div>
    <div class="description mt-3">
      <p class="complete-text px-4" id="textConclusion">
        Poxa, que pena :(. Parece que o seu convite expirou, por favor entrar em
        contato com o embarcador para mais detalhes.
      </p>
    </div>
    <div class="row mt-3 mb-2 justify-content-center">
      <a routerLink="/" mat-flat-button color="primary" class="access-platform"
        >Acessar plataforma</a
      >
    </div>
    <div class="row mb-2 justify-content-center">
      <img
        src="/assets/img/svg/New-message-pana.svg"
        alt=""
        width="250px"
        height="250px"
      />
    </div>
  </div>
</div>
