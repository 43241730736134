<mat-card class="container-myProfile">
  <mat-card-content class="padding-card-body">
    <form action="" [formGroup]="profileForm">

      <h6 class="mt-2 title">Meu perfil</h6>

      <div class="flex-grow">
        <div>
            <img src="{{ perfilImage }}"
            alt="" id="imagem-usuario"
            style="width: 100px; height: 100px; max-width: 100%; height: auto;" />
        </div>
      </div>


      <div fxLayout="column" class="right" class="d-flex flex-row-reverse mt-3">
        <div>
          <button
          mat-flat-button
          color="primary"
          class="file-update"
          (click)="fileInput.click()"
          [disabled]="profileForm.invalid"
        >
          Alterar Imagem
        </button>
        <input
          type="file"
          id="fileInput"
          style="display: none"
          (change)="updateFile($event)"
          #fileInput
        >
        </div>
      </div>


      <span class="description-user">Dados do usuário</span>




      <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column" fxFlex>
          <div class="form-group mt-4">
            <label for="" class="label-input form-label required">
              Nome de usuário
            </label>
            <input
              type="text"
              class="input-user form-control"
              placeholder="Usuário"
              required
              name="user"
              formControlName="user"
              [ngClass]="{
                'is-invalid':
                  profileForm.get('user')?.invalid &&
                  profileForm.get('user')?.touched,
                'is-valid':
                  (profileForm.get('user')?.valid &&
                    profileForm.get('user')?.touched) ||
                  (profileForm.get('user')?.valid &&
                    profileForm.get('user')?.value !== '')
              }"
            />
            <div
              *ngIf="
                (profileForm.get('user')?.errors &&
                  profileForm.get('user')?.touched) ||
                profileForm.get('user')?.dirty
              "
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between" class="mt-3">
        <div fxLayout="column" fxFlex class="column-email">
          <div class="form-group">
            <label for="" class="label-input form-label required">
              E-mail
            </label>
            <input
              readonly
              type="email"
              class="input-email form-control"
              placeholder="example@senior.com.br"
              required
              name="email"
              formControlName="email"
            />
          </div>
        </div>
        <div fxLayout="column" fxFlex class="column-phone">
          <div class="form-group">
            <label for="" class="label-input form-label required">
              Telefone
            </label>
            <input
              mask="(00) 0000-0000||(00) 00000-0000"
              class="input-phone form-control"
              placeholder="(xx) xxxx-xxxx"
              required
              name="phone"
              formControlName="phone"
              [ngClass]="{
                'is-invalid':
                profileForm.get('phone')?.invalid &&
                profileForm.get('phone')?.touched,
                'is-valid':
                profileForm.get('phone')?.valid &&
                profileForm.get('phone')?.touched || profileForm.get('phone')?.valid && profileForm.get('phone')?.value !== '',
              }"
            />
            <div
              *ngIf="
                profileForm.get('phone')?.value.length === 0 &&
                profileForm.get('phone')?.touched &&
                profileForm.get('phone')?.invalid
              "
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
            <div
              *ngIf="
                profileForm.get('phone')?.errors &&
                profileForm.get('phone')?.value.length > 0 &&
                profileForm.get('phone')?.dirty
              "
              class="invalid-feedback label-mandatory"
            >
              Telefone inválido!*
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-row mt-4">
        <div>
          <button
            (click)="changePassword()"
            mat-flat-button
            class="change-password-button"
          >
            <i class="ri-lock-password-fill"></i>
            Alterar senha
          </button>
        </div>
      </div>

      <div class="d-flex flex-row-reverse mt-3">
        <div>
          <button
            mat-flat-button
            color="primary"
            class="save-button"
            (click)="submitForm()"
            [disabled]="profileForm.invalid"
            [ngClass]="{ 'btn-disabled': profileForm.invalid }"
          >
            Salvar
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<div class="mt-4">
  <mat-card class="container-shipper">
    <mat-card-content class="padding-card-body">
      <h6 class="mt-2">Embarcadores associados</h6>
      <table mat-table [dataSource]="dataSource" class="table mt-4">
        <caption hidden>
          Tabela convites
        </caption>
        <ng-container matColumnDef="corporateName">
          <th mat-header-cell *matHeaderCellDef class="table-head-color">
            {{ headerTable.lbCorporateName }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            {{ element.corporateName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="cnpj">
          <th mat-header-cell *matHeaderCellDef class="table-head-color">
            {{ headerTable.lbCnpj }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            {{ element.cnpj }}
          </td>
        </ng-container>

        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef class="table-head-color">
            {{ headerTable.lbAddress }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            {{ element.address }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <td mat-cell *matCellDef="let element" class="table-row">
            <mat-menu #menu="matMenu">
              <a
                [routerLink]=""
                mat-menu-item
                class="dropdown-button-item"
                *ngFor="let action of options"
              >
                {{ action }}
              </a>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
        (page)="handlePageEvent($event)"
        [length]="totalElements"
        [pageIndex]="page"
        [pageSize]="size"
      >
      </mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
