import { Component } from '@angular/core';
import {} from 'src/app/shared/const/consts';
import { CertificationService } from '../certifications/certification.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-requirements-register',
  templateUrl: './requirements-register.component.html',
  styleUrls: ['./requirements-register.component.scss'],
})
export class RequirementsRegisterComponent {
  ngOnInit(): void {
    this.getCompany();
  }

  defaultColumns = ['title', 'actions'];
  vehiclesColumns = ['title', 'quantity' ,'actions'];

  listTitle = ['Produtos', 'Veículos', "UF's atendidas", 'Cargas'];

  displayedColumns = [this.defaultColumns, this.vehiclesColumns, this.defaultColumns, this.defaultColumns ];

  products: any;
  ufs: any;
  vehicle: any;
  load: any;
  listTables: any[] = [];

  listHeader = [
    {
      title: 'Produtos',
      description: 'Descrição',
      actions: 'Ações',
    },
    {
      title: 'Veículos',
      description: 'Descrição',
      quantity: 'Quantidade',
      actions: 'Ações',
    },
    {
      title: "UF's atendidas",
      description: 'Descrição',
      actions: 'Ações',
    },
    {
      title: 'Cargas',
      description: 'Descrição',
      actions: 'Ações',
    },
  ];

  constructor(
    private certificationService: CertificationService,
    private route: ActivatedRoute
  ) {}

  getIdFromUrl() {
    return this.route.snapshot.paramMap.get('id');
  }

  getCompany() {
    this.certificationService
      .getCompany(this.getIdFromUrl())
      .pipe()
      .subscribe((data) => {
        this.populaTabelaRequisitos(data);
        this.listTables = [];

        this.listTables.push(this.products);
        this.listTables.push(this.vehicle);
        this.listTables.push(this.ufs);
        this.listTables.push(this.load);
      });
  }

  populaTabelaRequisitos(data: any) {
    this.products = data.produto?.map((element: any) => ({
      labelWithUndescore: element,
      label: element ? element.replaceAll('_', ' ') : '',
    }));
    this.vehicle = data.veiculoList?.map((element: any) => ({
      labelWithUndescore: element.veiculo,
      label: element.veiculo ? element.veiculo.replaceAll('_', ' ') : '',
      possuiVeiculosComRastreador: data.possuiVeiculosComRastreador,
      quantidade: element?.quantidade ? element?.quantidade : '-'
    }));
    this.ufs = data.ufAtendida?.map((element: any) => ({
      labelWithUndescore: element,
      label: element ? element.replaceAll('_', ' ') : '',
    }));
    this.load = data.carga?.map((element: any) => ({
      labelWithUndescore: element,
      label: element,
    }));
  }
}
