import { environment } from './../../../environments/environment';
import {
  Component,
  DoCheck,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-private-layout',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss'],
})
export class PrivateLayoutComponent implements OnInit, DoCheck {
  routePath: string = '';

  hideNavItems: boolean = false;
  pageTitle: string = '';
  componentRoutes: Array<any> = [];
  isHome: boolean = false;
  lastPath: string = '';
  visibleRecipients = environment.RECIPIENTS
  isAdmin: boolean = false;
  menuRoutes: Array<any> = [];
  user: any

  constructor(private router: Router, private route: ActivatedRoute, private utilsService: UtilsService,) {
    this.router.events.subscribe((value) => {
      if (!(value instanceof NavigationEnd || value instanceof Scroll)) return;

      this.configureTitle(route.snapshot.data);
    });
  }

  ngOnInit(): void {
    this.routePath = window.location.pathname;
    this.user = JSON.parse(localStorage.getItem('usuario')!)
    this.isAdmin = this.user?.tipoUsuario == 1
  }

  ngDoCheck(): void {
    this.routePath = window.location.pathname;
    this.lastPath = this.routePath.split('/').pop();
    this.user = JSON.parse(localStorage.getItem('usuario')!)
    this.isAdmin = this.user?.tipoUsuario == 1
  }

  configureTitleChildrens(childrens: any, currentPaths: any) {
    for (let ch of childrens) {
      const { breadcrumb, items } = ch;
      for (let i = 0; i < currentPaths.length; i++) {
        let path = currentPaths[i];
        const c = items.find((i: any) => i.path === path);

        if (!c) continue;
        this.componentRoutes.push({
          label: c.label,
          path: c.path,
        });
        if (i >= 1) {
          this.pageTitle = breadcrumb;
        }
        if (i !== currentPaths.length - 1) continue;
      }
    }
  }

  public checkDigitPath(path: string) {
      if (!isNaN(Number(path))) {
        this.hideNavItems = true;
      }
  }

  configureTitle(data: any) {
    let menuitems: any[] = []
    let childrens: any[] = []

    menuitems = data?.menuitems || []
    childrens = data?.childrens || []

    const currentPaths = String(window.location.pathname)
      .split('/')
      .filter((s: string) => s);

    this.hideNavItems = !menuitems.length || false;
    this.isHome = false;

    if(menuitems.length > 0){
      this.menuRoutes = menuitems.map((mi: any) => ({
        ...mi,
        items: mi.items.filter((i: any) => !i?.hidden),
      }));
    }

    this.componentRoutes = [
      {
        path: `/${currentPaths[0]}`,
      },
    ];

    if(childrens.length > 0) {
      this.configureTitleChildrens(childrens, currentPaths);
    }

    if(menuitems.length > 0){
      for (let mi of menuitems) {
        const { label, items } = mi;
        for (let i = 0; i < currentPaths.length; i++) {
          let path = currentPaths[i];
          const m = items.find((i: any) => i.path === path);
  
          this.checkDigitPath(path);
  
          if (!m) continue;
  
          !m.home &&
            this.componentRoutes.push({
              label: m.label,
              path: m.path,
            });
  
          this.defineTitle(i, m, label)
  
          if (i !== currentPaths.length - 1) continue;
  
          this.hideNavItems = m.hideNav || false;
          this.isHome = m.home || false;
        }
      }
    }

  }

  defineTitle(i: any, m: any, label: any){
    if (i >= 2) {
      if (m.breadcrumb) {
        this.pageTitle = m.breadcrumb;
      } else {
        this.pageTitle = label;
      }
    } else if (i < 2 && m.breadcrumb) {
      this.pageTitle = m.breadcrumb;
    }
  }

  removeAccentuationForID(text: string){
    return this.utilsService.removeAccentuationForID(text)
  }

}
