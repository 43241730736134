<mat-card id="principal">
  <div *ngIf="loaded; else isLoading">
    <mat-card-content class="container-details">
      <div class="d-flex mt-2">
        <div class="flex-grow-1" fxFlex="90">
          <h6>Integrações API</h6>
        </div>
      </div>

      <mat-card class="container-details border-0 mt-2">
        <mat-card-content class="padding-card-body container-information">
          <h6 class="mt-2">
            Documentação
            <span>
              <i
                class="ri-information-line info-icon position-absolute top-0 end-1 me-3 mt-3"
                style="font-size: 10px"
              ></i>
            </span>
          </h6>
          <div class="mt-4">
            <div
              fxLayout="row"
              fxLayoutAlign="space-between start"
              class="mt-3"
            >
              <div fxLayout="column" fxFlex>
                <p class="informations-title">API de autenticação</p>
                <a
                  class="informations-content"
                  target="_blank"
                  href="https://utilities.plataformadefretes.com.br/loginleilao/swagger-ui/index.html"
                  >Autenticação</a
                >
              </div>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="space-between start"
              class="mt-4"
            >
              <div fxLayout="column" fxFlex>
                <p class="informations-title">API de carga de dados</p>
                <a
                  class="informations-content"
                  target="_blank"
                  href="https://utilities.plataformadefretes.com.br/leilao/swagger-ui/index.html"
                  >Carga de dados</a
                >
              </div>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="space-between start"
              class="mt-4"
            >
              <div fxLayout="column" fxFlex>
                <p class="informations-title">API de consulta</p>
                <a
                  class="informations-content"
                  target="_blank"
                  href="https://utilities.plataformadefretes.com.br/filter/swagger-ui/index.html"
                  >Consulta</a
                >
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-card-content>
  </div>
</mat-card>

<mat-card class="container-details mt-4">
  <form [formGroup]="simulationForm" (ngSubmit)="submitForm('GKO_SIMULACAO')">
    <div *ngIf="loaded; else isLoading">
      <mat-card-content>
        <h6>Configuração de Integração</h6>
        <mat-card class="mt-3">
          <mat-card-content class="container-information">
            <h6 class="mt-2">
              Simulação Rápida GKO
              <span>
                <i class="ri-information-fill info-green hover-text">
                  <div class="tooltip-text left">
                    <p class="p-tooltip">
                      Para que as suas ofertas de frete do tipo "Contrato" sejam
                      realizadas, é necessário inserir neste campo as
                      credenciais de API que podem ser obtidas dentro do
                      GKOFrete. Caso tenha dúvida em como obter essas
                      informações, basta abrir um chamado com o time de suporte
                      do GKOFrete solicitando as "Credenciais de API para
                      Simulação de Frete".<br><br> Dúvidas estamos a disposição.
                    </p>
                  </div>
                </i>
              </span>
            </h6>
            <div fxLayout="row" class="mt-4">
              <div fxLayout="column" fxFlex style="margin-right: 10px">
                <div class="form-group">
                  <label for="url" class="label-input form-label required">
                    URL
                  </label>
                  <input
                    type="text"
                    class="input-fields form-control"
                    id="url"
                    name="url"
                    formControlName="url"
                    placeholder="Digite a URL"
                    required
                    [ngClass]="{
                      'is-invalid':
                        simulationForm.get('url')?.invalid &&
                        simulationForm.get('url')?.touched,
                      'is-valid':
                        (simulationForm.get('url')?.valid &&
                          simulationForm.get('url')?.touched &&
                          simulationForm.get('url')?.value !== '') ||
                        simulationForm.get('url')?.value !== ''
                    }"
                  />
                  <div
                    *ngIf="
                      simulationForm.get('url')?.invalid &&
                      simulationForm.get('url')?.touched &&
                      simulationForm.get('url').value.length == 0
                    "
                    class="invalid-feedback label-mandatory"
                  >
                    Campo obrigatório*
                  </div>
                </div>
              </div>
              <div fxLayout="column" fxFlex style="margin-left: 10px">
                <div class="form-group">
                  <label for="database" class="label-input form-label required">
                    Banco
                  </label>
                  <input
                    type="text"
                    class="input-fields form-control"
                    id="database"
                    name="database"
                    formControlName="database"
                    placeholder="Digite o Banco de Dados"
                    required
                    [ngClass]="{
                      'is-invalid':
                        simulationForm.get('database')?.invalid &&
                        simulationForm.get('database')?.touched,
                      'is-valid':
                        (simulationForm.get('database')?.valid &&
                          simulationForm.get('database')?.touched &&
                          simulationForm.get('database')?.value !== '') ||
                        simulationForm.get('database')?.value !== ''
                    }"
                  />
                  <div
                    *ngIf="
                      simulationForm.get('database')?.invalid &&
                      simulationForm.get('database')?.touched &&
                      simulationForm.get('database').value.length == 0
                    "
                    class="invalid-feedback label-mandatory"
                  >
                    Campo obrigatório*
                  </div>
                </div>
              </div>
            </div>

            <div fxLayout="row" class="mt-4">
              <div fxLayout="column" fxFlex style="margin-right: 10px">
                <div class="form-group">
                  <label for="usuario" class="label-input form-label required">
                    Usuário
                  </label>
                  <input
                    type="text"
                    class="input-fields form-control"
                    id="usuario"
                    name="usuario"
                    formControlName="usuario"
                    placeholder="Digite o Usuário"
                    required
                    [ngClass]="{
                      'is-invalid':
                        simulationForm.get('usuario')?.invalid &&
                        simulationForm.get('usuario')?.touched,
                      'is-valid':
                        (simulationForm.get('usuario')?.valid &&
                          simulationForm.get('usuario')?.touched &&
                          simulationForm.get('usuario')?.value !== '') ||
                        simulationForm.get('usuario')?.value.length > 0
                    }"
                  />
                  <div
                    *ngIf="
                      simulationForm.get('usuario')?.invalid &&
                      simulationForm.get('usuario')?.touched &&
                      simulationForm.get('usuario').value.length == 0
                    "
                    class="invalid-feedback label-mandatory"
                  >
                    Campo obrigatório*
                  </div>
                </div>
              </div>
              <div fxLayout="column" fxFlex style="margin-left: 10px">
                <div class="form-group">
                  <label for="password" class="label-input form-label required">
                    Senha
                  </label>
                  <input
                    type="text"
                    class="input-fields form-control"
                    id="password-simulation"
                    type="password"
                    name="password"
                    formControlName="password"
                    placeholder="Digite uma senha"
                    required
                    [ngClass]="{
                      'is-invalid':
                        simulationForm.get('password')?.invalid &&
                        simulationForm.get('password')?.touched,
                      'is-valid':
                        (simulationForm.get('password')?.valid &&
                          simulationForm.get('password')?.touched &&
                          simulationForm.get('password')?.value !== '') ||
                        simulationForm.get('password')?.value !== ''
                    }"
                  />
                  <div
                    *ngIf="
                      simulationForm.get('password')?.invalid &&
                      simulationForm.get('password')?.touched &&
                      simulationForm.get('password').value.length == 0
                    "
                    class="invalid-feedback label-mandatory"
                  >
                    Campo obrigatório*
                  </div>

                  <div class="form-check mt-2">
                    <input
                      name="showPassword"
                      type="checkbox"
                      class="form-check-input"
                      (change)="visiblePassword($event, 'password-simulation')"
                    />
                    <label
                      class="form-check-label view-password"
                      for="showPassword"
                    >
                      Mostrar senha
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex flex-row-reverse mt-3">
              <div>
                <button
                  mat-flat-button
                  color="primary"
                  class="save-button"
                  [disabled]="simulationForm.invalid"
                >
                  Salvar
                </button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-card-content>
    </div>
  </form>

  <mat-card>
    <mat-card-content>
      <form [formGroup]="combinedShippingForm" (ngSubmit)="submitForm('RETORNO_FRETE_COMBINADO')">
        <div *ngIf="loaded; else isLoading">
            <mat-card>
              <mat-card-content class="container-information">
                <h6 class="mt-2">
                  Oferta Contrato / Frete Combinado
                  <span>
                    <i class="ri-information-fill info-green hover-text">
                      <div class="tooltip-text left">
                        <p class="p-tooltip">
                          Para que as suas ofertas de frete do tipo "Contrato" sejam
                          enviadas para o GKOFrete, é necessário inserir neste campo as
                          credenciais de API que podem ser obtidas dentro do
                          GKOFrete. Caso tenha dúvida em como obter essas
                          informações, basta abrir um chamado com o time de suporte
                          do GKOFrete solicitando as "Credenciais de API". <br><br>Dúvidas estamos a disposição.
                        </p>
                      </div>
                    </i>
                  </span>
                </h6>
                <div fxLayout="row" class="mt-4">
                  <div fxLayout="column" fxFlex style="margin-right: 10px">
                    <div class="form-group">
                      <label for="url" class="label-input form-label required">
                        URL
                      </label>
                      <input
                        type="text"
                        class="input-fields form-control"
                        id="url-combinedShipping"
                        name="url"
                        formControlName="url"
                        placeholder="Digite a URL"
                        required
                        [ngClass]="{
                          'is-invalid':
                            combinedShippingForm.get('url')?.invalid &&
                            combinedShippingForm.get('url')?.touched,
                          'is-valid':
                            (combinedShippingForm.get('url')?.valid &&
                              combinedShippingForm.get('url')?.touched &&
                              combinedShippingForm.get('url')?.value !== '') ||
                            combinedShippingForm.get('url')?.value !== ''
                        }"
                      />
                      <div
                        *ngIf="
                          combinedShippingForm.get('url')?.invalid &&
                          combinedShippingForm.get('url')?.touched &&
                          combinedShippingForm.get('url').value.length == 0
                        "
                        class="invalid-feedback label-mandatory"
                      >
                        Campo obrigatório*
                      </div>
                    </div>
                  </div>
                  <div fxLayout="column" fxFlex style="margin-left: 10px">
                    <div class="form-group">
                      <label for="database" class="label-input form-label required">
                        Banco
                      </label>
                      <input
                        type="text"
                        class="input-fields form-control"
                        id="database-combinedShipping"
                        name="database"
                        formControlName="database"
                        placeholder="Digite o Banco de Dados"
                        required
                        [ngClass]="{
                          'is-invalid':
                            combinedShippingForm.get('database')?.invalid &&
                            combinedShippingForm.get('database')?.touched,
                          'is-valid':
                            (combinedShippingForm.get('database')?.valid &&
                              combinedShippingForm.get('database')?.touched &&
                              combinedShippingForm.get('database')?.value !== '') ||
                            combinedShippingForm.get('database')?.value !== ''
                        }"
                      />
                      <div
                        *ngIf="
                          combinedShippingForm.get('database')?.invalid &&
                          combinedShippingForm.get('database')?.touched &&
                          combinedShippingForm.get('database').value.length == 0
                        "
                        class="invalid-feedback label-mandatory"
                      >
                        Campo obrigatório*
                      </div>
                    </div>
                  </div>
                </div>
    
                <div fxLayout="row" class="mt-4">
                  <div fxLayout="column" fxFlex style="margin-right: 10px">
                    <div class="form-group">
                      <label for="usuario" class="label-input form-label required">
                        Usuário
                      </label>
                      <input
                        type="text"
                        class="input-fields form-control"
                        id="usuario-combinedShipping"
                        name="usuario"
                        formControlName="usuario"
                        placeholder="Digite o Usuário"
                        required
                        [ngClass]="{
                          'is-invalid':
                            combinedShippingForm.get('usuario')?.invalid &&
                            combinedShippingForm.get('usuario')?.touched,
                          'is-valid':
                            (combinedShippingForm.get('usuario')?.valid &&
                              combinedShippingForm.get('usuario')?.touched &&
                              combinedShippingForm.get('usuario')?.value !== '') ||
                            combinedShippingForm.get('usuario')?.value.length > 0
                        }"
                      />
                      <div
                        *ngIf="
                          combinedShippingForm.get('usuario')?.invalid &&
                          combinedShippingForm.get('usuario')?.touched &&
                          combinedShippingForm.get('usuario').value.length == 0
                        "
                        class="invalid-feedback label-mandatory"
                      >
                        Campo obrigatório*
                      </div>
                    </div>
                  </div>
                  <div fxLayout="column" fxFlex style="margin-left: 10px">
                    <div class="form-group">
                      <label for="password" class="label-input form-label required">
                        Senha
                      </label>
                      <input
                        type="text"
                        class="input-fields form-control"
                        id="password-combinedShipping"
                        type="password"
                        name="password"
                        formControlName="password"
                        placeholder="Digite uma senha"
                        required
                        [ngClass]="{
                          'is-invalid':
                            combinedShippingForm.get('password')?.invalid &&
                            combinedShippingForm.get('password')?.touched,
                          'is-valid':
                            (combinedShippingForm.get('password')?.valid &&
                              combinedShippingForm.get('password')?.touched &&
                              combinedShippingForm.get('password')?.value !== '') ||
                            combinedShippingForm.get('password')?.value !== ''
                        }"
                      />
                      <div
                        *ngIf="
                          combinedShippingForm.get('password')?.invalid &&
                          combinedShippingForm.get('password')?.touched &&
                          combinedShippingForm.get('password').value.length == 0
                        "
                        class="invalid-feedback label-mandatory"
                      >
                        Campo obrigatório*
                      </div>
    
                      <div class="form-check mt-2">
                        <input
                          name="showPassword"
                          type="checkbox"
                          class="form-check-input"
                          (change)="visiblePassword($event, 'password-combinedShipping')"
                        />
                        <label
                          class="form-check-label view-password"
                          for="showPassword"
                        >
                          Mostrar senha
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
    
                <div class="d-flex flex-row-reverse mt-3">
                  <div>
                    <button
                      mat-flat-button
                      color="primary"
                      class="save-button"
                      [disabled]="combinedShippingForm.invalid"
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</mat-card>

<ng-template #isLoading>
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</ng-template>
