import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RequirementsService {
  constructor(private readonly http: HttpClient) {}

  saveProduct(obj: any) {
    const objComLookupRenomeado = { ...obj, produtoList: obj.lookup };
    return this.http
      .post<any>(
        `${environment.API_BACK}/produto-empresa`,
        objComLookupRenomeado
      )
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  saveUF(obj: any) {
    const objComLookupRenomeado = { ...obj, ufList: obj.lookup };
    return this.http
      .post<any>(`${environment.API_BACK}/uf-atendida`, objComLookupRenomeado)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  saveVeiculo(obj: any) {
    for (let v of obj.veiculoList) {
      Object.assign(v, {
        empresa: obj.empresa,
        possuiRastreador: obj.empresa.possuiVeiculosComRastreador,
      });
    }

    return this.http
      .post<any>(`${environment.API_BACK}/veiculo-empresa`, obj)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  saveCarga(obj: any) {
    const objComLookupRenomeado = { ...obj, cargaList: obj.lookup };
    return this.http
      .post<any>(`${environment.API_BACK}/carga-empresa`, objComLookupRenomeado)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  deleteProduct(objPadrao: any) {
    let params = new HttpParams();
    params = params.append('idEmpresa', objPadrao.empresa.id);
    params = params.append('produtoLookup', String(objPadrao.label));

    const httpOptions = {
      params: params,
    };

    return this.http.delete(
      `${environment.API_BACK}/produto-empresa/remove-produto`,
      httpOptions
    );
  }

  deleteUF(objPadrao: any) {
    let params = new HttpParams();
    params = params.append('idEmpresa', objPadrao.empresa.id);
    params = params.append('ufLookup', String(objPadrao.label));

    const httpOptions = {
      params: params,
    };

    return this.http.delete(
      `${environment.API_BACK}/uf-atendida/remove-uf`,
      httpOptions
    );
  }

  deleteVehicle(objPadrao: any) {
    let params = new HttpParams();
    params = params.append('idEmpresa', objPadrao.empresa.id);
    params = params.append('veiculoLookup', String(objPadrao.label));

    const httpOptions = {
      params: params,
    };

    return this.http.delete(
      `${environment.API_BACK}/veiculo-empresa/remove-veiculo`,
      httpOptions
    );
  }

  deleteLoad(objPadrao: any) {
    let params = new HttpParams();
    params = params.append('idEmpresa', objPadrao.empresa.id);
    params = params.append('cargaLookup', String(objPadrao.label));

    const httpOptions = {
      params: params,
    };

    return this.http.delete(
      `${environment.API_BACK}/carga-empresa/remove-carga`,
      httpOptions
    );
  }
}
