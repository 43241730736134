import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  isLogged: boolean = false;

  constructor(private route: ActivatedRoute, private authService: AuthService) {}
  ngOnInit(): void {
    const authenticated = sessionStorage.getItem('authenticated');

    if(!authenticated) return

    if(window.location.pathname === '/'){
      this.authService.logout();
      sessionStorage.removeItem('authenticated');
      this.isLogged = false;
      return
    }

    if (authenticated === 'yes') {
      this.isLogged = true;
    } 

    let el = document.getElementById('mat-typography');

    if (this.isLogged) {
      el.style.background = '#f6f6f8';
    } else {
      el.style.background = '#fff';
    }
  }
}
