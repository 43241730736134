import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  HeaderTable,
  TablePrivileges,
} from '../../model/access-privileges.model.carrier';

@Component({
  selector: 'app-accordion-check',
  templateUrl: './accordion-check.component.html',
  styleUrls: ['./accordion-check.component.scss'],
})
export class AccordionCheckComponent implements OnInit {
  @Input() ELEMENT_DATA: TablePrivileges[] = [];
  @Input() headerTable: HeaderTable = { lbColumnA: '', lbColumnB: '' };
  @Input() lbAccordion: string = '';
  @Input() hideTable: boolean = false;
  @Input() listaSelecionados: any[] = [];

  @Output() getSelecionados = new EventEmitter<string>();

  panel: boolean = true;

  displayedColumns: string[] = ['select', 'columnA', 'columnB'];
  dataSource: any;
  selection = new SelectionModel<TablePrivileges>(true, []);

  selecionados:any

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<TablePrivileges>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<TablePrivileges>(true, this.listaSelecionados);
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    const numPreselected = this.listaSelecionados.length;
    return numSelected + numPreselected === numRows;
  }

  public masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row: any) => this.selection.select(row));
      this.preparaEEnviaEvento();
  }

  public enablePanel() {
    this.panel = !this.panel;
    this.selection.clear();
    return this.panel;
  }

  onChange(event:any, selection:any, row:any){
    const retorno = event ? selection.toggle(row) : null
    this.preparaEEnviaEvento();
    return retorno;
  }

  onChangeAll(event:any, row:any){
    return event ? this.masterToggle() : null
  }

  preparaEEnviaEvento(){
    const array = this.selection.selected
    .map( (objeto:any) => ({
      ...objeto,
      tipo: this.lbAccordion
    }));
    this.emitEvent(array)
  }

  emitEvent(value: any) {
    this.getSelecionados.emit(value);
  }

  linhaSelecionada(row:any){
    return this.listaSelecionados.some(item => item.valor === row.columnC);
  }
}
