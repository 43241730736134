import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CepService {
  constructor(private readonly http: HttpClient) {}

  consultaCep(cep: string) {
    cep = cep.replace(/D/g, '');
    cep = cep.replace(/D/g, '');
    if (cep !== '') {
      return this.http
        .get(`https://viacep.com.br/ws/${cep}/json`)
        .pipe((dados) => dados);
    }

    return of({});
  }
}
