import { formatCurrency } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MaskService {

  formatCNPJ(value: number) {
    const cnpjCpf = value?.toString().replace(/\D/g, '');
    return cnpjCpf
      ?.padStart(14, '0')
      .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
  }

  formatTelephone(value: string) {
    if (value == null || value == undefined || value == '') {
      return null;
    }
    return value.replaceAll(' ', '').replace(/(\d{2})?(\d{9})/, '($1) $2');
  }

  formatValue(valor: any) {
    if (valor != null) {
      return formatCurrency(valor, 'pt-BR', 'R$');
    }
    return null;
  }
}
