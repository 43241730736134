import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Location } from '@angular/common';

interface BreadCrumbItem {
  label: string;
  path: string;
}

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnChanges {
  @Input()
  paths: Array<BreadCrumbItem> = [];
  @Input()
  title: string = '';
  @Input()
  isHome: boolean = false;
  btnGoBack: boolean = false;
  routePath: string = '';
  userName = JSON.parse(localStorage.getItem("usuario") ?? "" ).nome;
  user = JSON.parse(localStorage.getItem("usuario") ?? "" );
  isCarrier = this.user.cliente?.ofertaLance;

  constructor(private location: Location) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.routePath = window.location.pathname;
    const currentPaths = String(this.routePath)
      .split('/')
      .filter((s: string) => s);

    if (currentPaths.length > 1) {
      this.btnGoBack = true;
    } else {
      this.btnGoBack = false;
    }
  }

  back(): void {
    this.location.back();
  }
}
