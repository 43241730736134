import { Component } from '@angular/core';

@Component({
  selector: 'app-error-accepting-partner-ship',
  templateUrl: './error-accepting-partner-ship.component.html',
  styleUrls: ['./error-accepting-partner-ship.component.scss']
})
export class ErrorAcceptingPartnerShipComponent {

}
