<div class="container-requirements">
  <mat-dialog-title>
    <h6 class="mt-2">{{ title }}</h6></mat-dialog-title
  >
  <mat-dialog-content [hidden]="visibleTable">
    <form
      (ngSubmit)="submitForm()"
      class="select-form d-flex"
      [formGroup]="searchForm"
    >
      <div class="form-group position-relative search">
        <input
          matInput
          id="search"
          name="search"
          formControlName="search"
          type="text"
          class="form-control pe-5 input-data w-100"
          placeholder="Faça sua busca aqui"
          (keyup)="applyFilter($event)"
        />
        <i
          class="ri-search-line position-absolute end-0 my-1 mx-2 text-center"
          style="top: 5px"
        ></i>
      </div>
    </form>

    <table mat-table [dataSource]="dataSource" class="mt-4">
      <caption hidden></caption>
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="table-head-color">
          <mat-checkbox
            (change)="$event ? toggleAllRows() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="{{ columnA }}">
        <th mat-header-cell *matHeaderCellDef class="table-head-color">
          {{ type }}
        </th>
        <td mat-cell *matCellDef="let element" class="table-row">
          {{ element.label }}
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef class="table-head-color">
          Descrição
        </th>
        <td mat-cell *matCellDef="let element" class="table-row">-</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="selection.toggle(row)"
      ></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
      (page)="handlePageEvent($event)"
      [length]="totalElements"
      [pageIndex]="page"
      [pageSize]="size"
    >
    </mat-paginator>
  </mat-dialog-content>
  <div class="d-flex flex-row-reverse mt-2">
    <button
      *ngIf="type === 'Veículos'"
      mat-flat-button
      color="primary"
      class="btn-send"
      cdkFocusInitial
      (click)="addQuantity()"
      [disabled]="selection.selected.length == 0"
      [hidden]="visibleTable"
    >
      Avançar
    </button>
    <button
      *ngIf="type !== 'Veículos'"
      mat-flat-button
      color="primary"
      class="btn-send"
      cdkFocusInitial
      (click)="addRegister()"
      [disabled]="selection.selected.length == 0"
      [hidden]="visibleTable"
    >
      Salvar
    </button>
    <button
      mat-flat-button
      mat-dialog-close
      class="btn-back"
      [hidden]="visibleTable"
      style="margin-right: 10px"
    >
      Voltar
    </button>
  </div>

  <mat-dialog-content [hidden]="visibleQuantity && type === 'Veículos'">
    <form action="" [formGroup]="quantityForm">
      <div
        formArrayName="veiculos"
        *ngFor="let vehicle of vehicles; let i = index"
      >
        <div
          [formGroupName]="i"
          fxLayout="row"
          fxLayoutAlign="space-between"
          style="margin-bottom: 1rem; margin-top: 1rem"
        >
          <div fxLayout="column" fxFlex="70" fxLayoutAlign="center">
            <span>{{ vehicle.label }}</span>
          </div>
          <div fxLayout="column" fxFlex="30">
            <input
              type="text"
              [id]="'quantity' + i"
              [name]="'quantity' + i"
              formControlName="quantidade"
              type="text"
              class="form-control pe-5 input-data w-100"
            />
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <div
    [hidden]="(visibleQuantity && type === 'Veículos') || type !== 'Veículos'"
  >
    <div class="d-flex flex-row-reverse mt-3">
      <div mat-dialog-actions>
        <button mat-flat-button class="btn-back" (click)="goBack()">
          Voltar
        </button>
        <button
          mat-flat-button
          color="primary"
          class="btn-send"
          (click)="addRegister()"
        >
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>
