<hr />
<ngx-dropzone
  (change)="onSelect($event)"
  [multiple]="false"
  [accept]="'*'"
  class="container mt-4"
>
  <ngx-dropzone-label id="dropzone-label">
    <div fxLayout="row" fxLayoutAlign="start">
      <div fxLayout="column">
        <i class="ri-file-upload-fill icon-upload"></i>
      </div>
      <div fxLayout="column">
        <p class="text-upload">Importar Anexo</p>
      </div>
    </div>
  </ngx-dropzone-label>
</ngx-dropzone>

<form class="mt-2 px-2" [formGroup]="formCertifications">
  <div fxLayout="row" fxLayoutAlign="space-between" class="mb-3">
    <div fxLayout="column" fxFlex class="right">
      <div class="form-group">
        <label class="label-input form-label required" for="certifies">
          Certificações
        </label>
        <ng-select
          name="certifies"
          formControlName="certifies"
          [items]="certifies"
          [multiple]="false"
          [closeOnSelect]="false"
          [searchable]="false"
          bindLabel="label"
          bindValue="value"
          [ngClass]="{
            'invalid is-invalid':
              formCertifications.get('certifies')?.invalid &&
              formCertifications.get('certifies')?.touched,
            'valid is-valid':
              formCertifications.get('certifies')?.valid &&
              formCertifications.get('certifies')?.touched &&
              formCertifications.get('certifies')?.value.length > 0
          }"
        ></ng-select>
        <div
          *ngIf="
            formCertifications.get('certifies')?.invalid &&
            formCertifications.get('certifies')?.touched &&
            formCertifications.get('certifies')?.value.length == 0
          "
          class="invalid-feedback label-mandatory"
        >
          Campo obrigatório*
        </div>
      </div>
    </div>
    <div fxLayout="column" fxFlex class="left">
      <div class="form-group">
        <div class="d-flex">
          <div class="flex-grow-1">
            <label class="label-input form-label required" for="expirationDate">
              Validade
            </label>
          </div>

          <i class="ri-information-line icon-info ms-1"></i>
        </div>
        <input
          id="expirationDate"
          matInput
          onfocus="this.showPicker()"
          type="date"
          name="expirationDate"
          class="form-control input-data w-100"
          formControlName="expirationDate"
          placeholder="Digite seu nome"
          required
          [ngClass]="{
            'is-invalid':
            formCertifications.get('expirationDate')?.invalid &&
            formCertifications.get('expirationDate')?.touched,
            'is-valid':
            formCertifications.get('expirationDate')?.valid &&
            formCertifications.get('expirationDate')?.touched,
          }"
        />
        <div
          *ngIf="
            formCertifications.get('expirationDate')?.invalid &&
            formCertifications.get('expirationDate')?.touched
          "
          class="invalid-feedback label-mandatory"
        >
          Campo obrigatório*
        </div>
      </div>
    </div>
  </div>
  <button (click)="saveCertification()" mat-flat-button class="add-button mt-3">
    <i class="ri-add-line"></i> Adicionar certificação
  </button>
</form>

<div class="px-2">
  <h6 class="mt-4">Minhas certificações</h6>
</div>

<div class="mt-2 px-2">
  <table mat-table [dataSource]="dataSource" class="table mt-4">
    <caption hidden>
      Tabela certificações
    </caption>
    <ng-container matColumnDef="certifies">
      <th mat-header-cell *matHeaderCellDef class="table-head-color">
        {{ headerTable.lbCertifies }}
      </th>
      <td mat-cell *matCellDef="let element" class="table-row">
        {{ element.certifies }}
      </td>
    </ng-container>

    <ng-container matColumnDef="expirationDate">
      <th mat-header-cell *matHeaderCellDef class="table-head-color">
        {{ headerTable.lbExpirationDate }}
      </th>
      <td mat-cell *matCellDef="let element" class="table-row">
        {{ element.expirationDate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="attachment">
      <th mat-header-cell *matHeaderCellDef class="table-head-color">
        {{ headerTable.lbAttachment }}
      </th>
      <td mat-cell *matCellDef="let element" class="table-row">
        <a
          target="_blank"
          [href]="element.attachment"
          mat-stroked-button
          class="btn-attachment"
          [disabled]="!element.attachment"
        >
          {{ element.attachment ? "Arquivo" : "Sem Anexo" }}</a
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="table-head-color column-actions"
      >
        {{ headerTable.lbActions }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let rowIndex = index"
        class="table-row"
      >
        <button
          class="btn-trash"
          (click)="removeItem(element.certifiesWithUndescore)"
        >
          <i class="ri-delete-bin-5-fill"></i>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div class="add-hover">
  </div>
</div>
