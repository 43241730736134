import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject, first } from 'rxjs';
import { ConfigurationsService } from 'src/app/configurations/configurations.service';
import { DialogConfirmComponent } from 'src/app/shared/dialog-confirm/dialog-confirm.component';
import { PermissaoService } from 'src/app/shared/services/permissao.service';
import { SnackbarDefaultService } from 'src/app/shared/services/snackbar-default.service';

export const dataEmitter = new Subject<any>();
export interface PeriodicElement {
  id: number;
  name: string;
  email: string;
  status: string;
  actions: string;
}

export interface HeaderTable {
  lbName: string;
  lbEmail: string;
  lbStatus: string;
  lbActions: string;
}

@Component({
  selector: 'app-my-users',
  templateUrl: './my-users.component.html',
  styleUrls: ['./my-users.component.scss'],
})
export class MyUsersComponent {
  displayedColumns: string[] = ['name', 'email', 'status', 'actions'];
  dataSource = new MatTableDataSource();
  loaded: boolean = true;
  options: any = ['Editar', 'Alterar status'];

  headerTable: HeaderTable = {
    lbName: 'Nome',
    lbEmail: 'E-mail',
    lbStatus: 'Status',
    lbActions: '	Ações',
  };

  routePath: string = '';
  newUser: string = '';
  editUser: string = '';
  gridData: any = [];
  page: number = 0;
  size: number = 50;
  totalElements: number = 0;

  isAdmin: boolean = false;
  userId = Number(JSON.parse(localStorage.getItem('usuario'))?.id);

  constructor(
    private service: ConfigurationsService,
    private router: Router,
    private permissaoService: PermissaoService,
    private dialog: MatDialog,
    private _snackBar: SnackbarDefaultService
  ) {}
  ngOnInit(): void {
    this.routePath = window.location.pathname;
    this.newUser = `${this.routePath}/new-user`;
    this.editUser = `${this.routePath}/edit-user`;
    this.isAdmin = this.permissaoService.isAdmin();
    this.getUsers();
  }

  public redirect(action: string, row: any) {
    if (action === 'Editar') {
      this.router.navigate([this.editUser, row.id]).catch((err) => err);
    }
    if (action == 'Alterar status') {
      this.changeStatus(row);
    }
  }

  public getUsers() {
    const size = this.size;
    const page = this.page;

    const paginatorObj = {
      page,
      size,
    };

    this.service
      .getUsers(paginatorObj)
      .pipe()
      .subscribe((users: any) => {
        this.gridData = users.content?.map((user: any) => ({
          id: user.id,
          name: user.nome.toUpperCase(),
          email: user.email,
          status: this.statusUserAdjustment(user),
          actions: '',
        }));
        this.totalElements = users.totalElements;
        this.dataSource = new MatTableDataSource(this.gridData);
      });
  }

  public handlePageEvent(e: PageEvent) {
    if (this.size != e.pageSize) {
      this.page = 0;
    } else {
      this.page = e.pageIndex;
    }
    this.size = e.pageSize;
    this.getUsers();
  }

  public statusUserAdjustment(user: any) {
    if (user.statusUsuario === 'ATIVO') {
      return 'Ativo';
    } else {
      return 'Inativo';
    }
  }

  public changeStatus(row: any) {
    let text = row.status == 'Ativo' ? 'INATIVAR' : 'ATIVAR';

    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: 'Confirmação',
        description: `Tem certeza que deseja ${text} esse usuário?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.service
          .changeUserStatus(row.id)
          .pipe(first())
          .subscribe((data: any) => {
            if (data) {
              this._snackBar.openSnackBarSuccess(
                `Usuário alterado com sucesso`
              );
              setTimeout(() => {
                this.getUsers();
              }, 500);
            }
          });
      }
    });
  }
}
