import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EnvironmentRulesService } from './environment-rules.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-environment-rules',
  templateUrl: './environment-rules.component.html',
  styleUrls: ['./environment-rules.component.scss'],
})
export class EnvironmentRulesComponent implements OnInit {
  public rulesForm: FormGroup;
  public user = JSON.parse(localStorage.getItem('usuario')!);
  offerApproval: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private environmentRulesService: EnvironmentRulesService,
    private cdr: ChangeDetectorRef
  ) {
    this.rulesForm = this.formBuilder.group({
      offerApproval: [''],
    });
  }

  ngOnInit(): void {
    this.getCompany();
    this.cdr.detectChanges();
  }

  public getCompany() {
    this.environmentRulesService
      .getCompany(this.user.cliente.id)
      .pipe(first())
      .subscribe((shipper) => {
        if (shipper != null) {
          this.offerApproval = shipper.aprovacaoSemRegras;
          this.setOfferApproval();
        }
        this.cdr.detectChanges();
      });
  }

  setOfferApproval() {
    let offerApprovalYesElement = document.getElementById(
      'offerApprovalYes'
    ) as HTMLInputElement;
    let offerApprovalNoElement = document.getElementById(
      'offerApprovalNo'
    ) as HTMLInputElement;

    if (this.offerApproval) {
      offerApprovalYesElement.checked = true;
    } else {
      offerApprovalNoElement.checked = true;
    }

    offerApprovalYesElement.disabled = true;
    offerApprovalNoElement.disabled = true;
  }
}
