import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarDefaultService } from 'src/app/shared/services/snackbar-default.service';
import { HomeLandingService } from './home-landing.service';
import { first } from 'rxjs';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  public interestForm: FormGroup = this.formBuilder.group({
    user: ['', Validators.required],
    company: ['', Validators.required],
    email: ['', Validators.compose([Validators.required, Validators.email])],
    phone: ['', Validators.required],
    message:[''],
    // typeCompany:['']
  });

  validEmail: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: SnackbarDefaultService,
    private service: HomeLandingService,
  ) {}

  public checkEmail() {
    this.validEmail = false;
    const email = this.interestForm.get('email')?.value;

    if (email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$/)) {
      this.validEmail = true;
    } else {
      this.validEmail = false;
    }
    return this.validEmail;
  }

  public submitForm(){
    let interested ={
      nomeCliente : this.interestForm.value.user,
      nomeEmpresa : this.interestForm.value.company,
      email : this.interestForm.value.email,
      telefone : this.interestForm.value.phone,
      mensagem : this.interestForm.value.message
      // tipoEmpresa : this.interestForm.value.typeCompany,
    }

    this.service
      .cadastroInteresse(interested)
      .pipe(first())
      .subscribe(
        (result: any) => {   
            this.snackBar.openSnackBarSuccess(
              'Cadastro Concluido.'
            );      
        }      
        );   
  }
}
