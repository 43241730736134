import { Component, Inject, inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent {
  snackBarRef = inject(MatSnackBarRef);

  icon: string = '';
  title: string = '';
  message: string = '';
  type: string = '';
  link: string = ''


  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.icon = this.data.icon;
    this.title = this.data.title;
    this.message = this.data.message;
    this.type = this.data.type;
    this.link = this.data?.link
  }
}
