<mat-card class="container-details">
  <mat-card-content>
    <h6>Minha conta</h6>
    <div *ngIf="!loaded; else isLoading">
      <mat-card class="container-information mt-3">
        <mat-card-content>
          <h6>Informações do Plano</h6>
          <div fxLayout="row" fxLayoutAlign="start" class="mt-4">
            <div fxLayout="column" fxFlex>
              <div fxLayout="row">
                <div fxLayout="column" style="margin-right: 5px">
                  <img
                    src="assets/img/svg/icons/circle-dollar.svg"
                    alt=""
                    width="25px"
                    class="icon-top"
                  />
                </div>
                <div fxLayout="column">
                  <p class="informations-title">
                    {{ nameColumns.lbPlan }}
                  </p>
                  <p class="informations-content">
                    {{ data.plan }}
                  </p>
                </div>
              </div>
            </div>
            <div fxLayout="column" fxFlex>
              <div fxLayout="row">
                <div fxLayout="column" style="margin-right: 5px">
                  <img
                    src="assets/img/svg/icons/money-check-dollar.svg"
                    alt=""
                    width="25px"
                    class="icon-top"
                  />
                </div>
                <div fxLayout="column">
                  <p class="informations-title">
                    {{ nameColumns.lbTotalOffers }}
                  </p>
                  <p class="informations-content">
                    {{ data.totalOffers }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="start" class="mt-2">
            <div fxLayout="column" fxFlex>
              <div fxLayout="row">
                <div fxLayout="column" style="margin-right: 5px">
                  <img
                    src="assets/img/svg/icons/calendar-check.svg"
                    alt=""
                    width="25px"
                    class="icon-top"
                  />
                </div>
                <div fxLayout="column">
                  <p class="informations-title">
                    {{ nameColumns.lbActivationDate }}
                  </p>
                  <p *ngIf="data.activationDate" class="informations-content">
                    {{ data.activationDate }}
                  </p>
                  <p *ngIf="!data.activationDate" class="informations-content">
                    Sem informação
                  </p>
                </div>
              </div>
            </div>
            <div fxLayout="column" fxFlex>
              <div fxLayout="row">
                <div fxLayout="column" style="margin-right: 5px">
                  <img
                    src="assets/img/svg/icons/calendar-clock.svg"
                    alt=""
                    width="25px"
                    class="icon-top"
                  />
                </div>
                <div fxLayout="column">
                  <p class="informations-title">
                    {{ nameColumns.lbExpirationDate }}
                  </p>
                  <p *ngIf="data.expirationDate" class="informations-content">
                    {{ data.expirationDate }}
                  </p>
                  <p *ngIf="!data.expirationDate" class="informations-content">
                    Sem informação
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <h6>Ofertas criadas no mês</h6>
          <div fxLayout="row" fxLayoutAlign="start" class="mt-4">
            <div fxLayout="column" fxFlex>
              <div fxLayout="row">
                <div fxLayout="column" style="margin-right: 5px">
                  <img
                    src="assets/img/svg/icons/money-check-dollar.svg"
                    alt=""
                    width="25px"
                    class="icon-top"
                  />
                </div>
                <div fxLayout="column">
                  <p class="informations-title">
                    {{ nameColumns.lbTotalOffersMonth }}
                  </p>
                  <p class="informations-content">
                    {{ data.totalOffersMonth }}
                  </p>
                </div>
              </div>
            </div>
            <div fxLayout="column" fxFlex>
              <div fxLayout="row">
                <div fxLayout="column" style="margin-right: 5px">
                  <img
                    src="assets/img/svg/icons/money-check-dollar.svg"
                    alt=""
                    width="25px"
                    class="icon-top"
                  />
                </div>
                <div fxLayout="column">
                  <p class="informations-title">
                    {{ nameColumns.lbSpotOffer }}
                  </p>
                  <p class="informations-content">
                    {{ data.spotOffers }}
                  </p>
                </div>
              </div>
            </div>
            <div fxLayout="column" fxFlex>
              <div fxLayout="row">
                <div fxLayout="column" style="margin-right: 5px">
                  <img
                    src="assets/img/svg/icons/money-check-dollar.svg"
                    alt=""
                    width="25px"
                    class="icon-top"
                  />
                </div>
                <div fxLayout="column">
                  <p class="informations-title">
                    {{ nameColumns.lbContractOffer }}
                  </p>
                  <p class="informations-content">
                    {{ data.contractOffers }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #isLoading>
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</ng-template>
