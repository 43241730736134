import { Component } from '@angular/core';

@Component({
  selector: 'app-partner-invitation',
  templateUrl: './partner-invitation.component.html',
  styleUrls: ['./partner-invitation.component.scss']
})
export class PartnerInvitationComponent {

}
