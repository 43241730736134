import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissaoService {
 
constructor() {}

public isAdmin(){
  return JSON.parse(localStorage.getItem('usuario')!)?.tipoUsuario == 1;
}

}
