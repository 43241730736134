import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-public-layout',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss'],
})
export class PublicLayoutComponent implements OnInit {
  routePath: string = ''

  constructor(private route: ActivatedRoute) {
  }
  ngOnInit(): void {
    this.routePath = window.location.pathname
  }
}
