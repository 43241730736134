<footer class="footer">
  <div class="container-fluid footer-container">
    <div class="row justify-content-between p-5">
      <div class="col col-md-4">
        <img
          src="assets/img/svg/logo-plataforma-de-frete-medium.svg"
          alt="Logo app"
          class="img-fluid footer-logo"
        />
        <p class="app-description mt-3">
          Ajudamos as empresas a encontrar os melhores fornecedores transporte,
          a preços competitivos e com alta qualidade de serviço.
        </p>
        <a mat-raised-button color="primary" class="app-support-button" style="margin-top: 10px;" href="/#interest">
          <i class="ri-phone-fill"></i>
          Fale conosco
        </a>
      </div>
      <div class="col col-md-6 margin-clients">
        <div class="row justify-content-between">
          <div class="col col-md-4 ">
            <p class="app-service-tittle">Clientes</p>
            <a class="app-service-item py-2" routerLink="im-a-shipper"
              >Embarcadores</a
            >
            <a class="app-service-item py-2" routerLink="im-a-carrier"
              >Transportadoras</a
            >
          </div>
          <div class="col col-md-6">
            <p class="app-service-tittle" routerLink="/">Links úteis</p>
            <a
              class="app-service-item py-2"
              href="https://www.senior.com.br/politica-de-privacidade"
              target="_blank"
              >Política de privacidade</a
            >
            <a
              class="app-service-item py-2"
              href="https://www.senior.com.br/politica-de-seguranca-da-informacao"
              target="_blank"
              >Política de segurança da informação</a
            >
            <a
              class="app-service-item py-2"
              href="https://www.senior.com.br/politica-de-privacidade"
              target="_blank"
              >Termos de uso</a
            >
          </div>
        </div>
      </div>
      <div class="col col-md-2 margin-clients">
        <div class="row justify-content-end">
          <i class="col-sm-1 social-icon ri-twitter-fill"></i>
          <i class="col-sm-1 social-icon ri-linkedin-fill"></i>
          <i class="col-sm-1 social-icon ri-facebook-fill"></i>
          <i class="col-sm-1 social-icon ri-instagram-line"></i>
        </div>
      </div>
    </div>
    <div class="row copyright text-center">
      <div class="col">
        <p class="text-white my-2">
          Senior Copyright &#169; {{ year }}. Versão 1.0
        </p>
      </div>
    </div>
  </div>
</footer>
