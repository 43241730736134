import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MyCompaniesService {

  constructor(private readonly http: HttpClient) {}

  getCompanies(paramsObject: any) {
    let params = new HttpParams();
    params = params.append('size', String(paramsObject.size));
    params = params.append('page', String(paramsObject.page));
    if (paramsObject.status) {
          params = params.append('status', paramsObject.status);
        }

    return this.http
      .get<any>(`${environment.API_FILTER}/cliente/minhas-empresas`, {
        params: params,
      })
      .pipe(
        first(),
        map((data: any) => {
          return data;
        })
      );
  }

  updateStatus(obj: any) {
    let params = new HttpParams();
    params = params.append('idEmpresa', String(obj.idEmpresa));
    params = params.append('status', String(obj.status));

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('idEmpresa', String(obj.idEmpresa))
      .set('status', String(obj.status))
      .set('responseType', 'text');

    return this.http
      .put(
        `${environment.API_BACK}/empresa/altera-status`,
        {},
        { headers, params }
      )
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }
}
