<mat-card id="principal" class="container-details">
  <form [formGroup]="contractOfferForm" (ngSubmit)="submitForm()">
    <mat-card-content>
      <div class="">
        <h6>Configurações de Oferta Contrato</h6>
      </div>

      <mat-card class="mt-3 container-information">
        <mat-card-content class="">
          <div fxLayout="row" fxLayoutAlign="space-between">
            <div fxLayout="column" fxFlex="49">
              <div class="form-group">
                <label class="label-input form-label required" for="processType">
                  Tipo Processo
                </label>

                <i class="ri-information-fill info-green hover-text">
                  <div class="tooltip-text right-tooltip">
                    <p class="p-tooltip">
                      Este campo serve para definir como será o processo de
                      contratação dos fretes que forem apresentados nos retornos
                      da sua tabela de frete. Caso escolha opção automático a
                      plataforma irá automaticamente enviar a cotação de frete
                      para a transportadora que apresentar o menor valor de
                      frete. Caso escolha a opção manual o cliente quem deverá
                      escolher para qual transportadora ele deseja enviar a
                      cotação.
                    </p>
                  </div>
                </i>
                <div *ngIf="!edit">
                  {{processType}}
                </div>
                <div *ngIf="edit">

                  <ng-select name="province" formControlName="processType" [items]="processTypeOptions"
                    [closeOnSelect]="true" [searchable]="true" [clearable]="false" bindLabel="label" bindValue="value"
                    (change)="checkAttempts(contractOfferForm.get('processType').value)"
                    required [ngClass]="{
                      'invalid is-invalid':
                        contractOfferForm.get('processType')?.invalid &&
                        contractOfferForm.get('processType')?.touched,
                      'valid is-valid':
                        (contractOfferForm.get('processType')?.valid &&
                          contractOfferForm.get('processType')?.touched) ||
                        contractOfferForm.get('processType')?.value?.length > 0
                    }">
                    <ng-template ng-label-tmp let-item="item" let-index="index">
                      {{ item.value }}
                    </ng-template>
                  </ng-select>
                  <div *ngIf="
                      contractOfferForm.get('processType')?.invalid &&
                      contractOfferForm.get('processType')?.touched &&
                      contractOfferForm.get('processType')?.value?.length == 0
                    " class="invalid-feedback label-mandatory">
                    Campo obrigatório*
                  </div>
                </div>
                
              </div>
            </div>
            <div *ngIf="showAttempts()" fxLayout="column" fxFlex="49">
              <div class="form-group">
                <label for="" class="label-input form-label required">Quantidade de Tentativas</label>

                <i class="ri-information-fill info-green hover-text">
                  <div class="tooltip-text left-tooltip">
                    <p class="p-tooltip">
                      Este campo está disponível apenas para o processo automático.
                      Ele irá definir por quantas vezes o sistema
                      tentará realizar o contato com as transportadoras. <br>
                      Exemplo: Em um cenário onde a plataforma obteve como retorno 3
                      transportadoras, o usuário definiu com 2 tentativas o seu
                      default, a plataforma irá enviar a cotação para a
                      transportadora 2 vezes caso elas não respondam o processo
                      será finalizado automaticamente.
                    </p>
                  </div>
                </i>
                <div *ngIf="!edit">
                  {{attempts}}
                </div>
                <div *ngIf="edit">
                  <input class="input-field form-control" matInput name="attempts"
                    placeholder="Digite a quantidade de tentativas" formControlName="attempts" type="number"
                    required
                    [ngClass]="{
                      'is-invalid':
                        (contractOfferForm.get('attempts')?.invalid &&
                        contractOfferForm.get('attempts')?.touched)||
                        contractOfferForm.get('attempts')?.value < 1,
                      'is-valid':
                        (contractOfferForm.get('attempts')?.valid &&
                          contractOfferForm.get('attempts')?.touched) ||
                        contractOfferForm.get('attempts')?.value > 0
                    }" />
                  <div *ngIf="contractOfferForm.hasError('attemptsRequired')" class="invalid-feedback label-mandatory">
                    Campo obrigatório*
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="d-flex flex-row-reverse mt-4">
            <div *ngIf="!edit">
              <button mat-flat-button color="primary" class="save-button" (click)="edit = !edit">
                Editar
              </button>
            </div>
            <div *ngIf="edit">
              <button mat-flat-button color="secondary" class="save-button" (click)="edit = !edit">
                Cancelar
              </button>
              <button [disabled]="contractOfferForm.invalid" mat-flat-button color="primary" class="save-button">
                Salvar
              </button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-card-content>
  </form>
</mat-card>