<mat-card class="container-supplier" *ngIf="loaded; else isLoading">
  <mat-card-content>
    <h6 class="mt-2">Meus fornecedores</h6>

    <div class="d-flex mt-4">
      <div class="flex-grow-1">
        <a
          mat-flat-button
          color="primary"
          class="button-new"
          [routerLink]="[newSupplier]"
          ><i class="ri-add-line"></i> Novo
        </a>
        <!-- <button
          mat-flat-button
          [matMenuTriggerFor]="menu"
          class="dropdown-button-import"
        >
          Importar fornecedores
          <i class="ri-arrow-down-s-line"></i>
        </button>
        <mat-menu #menu="matMenu">
          <a
            mat-menu-item
            class="dropdown-button-item"
            *ngFor="let action of optionsSupplier"
            (click)="redirectActionsBtnImport(action)"
            >{{ action }}</a
          >
        </mat-menu> -->
      </div>
      <div class="d-flex flex-row-reverse">
        <form
          class="select-form d-flex"
          [formGroup]="searchForm"
          (keyup)="submitForm()"
        >
          <div class="form-group position-relative search">
            <input
              matInput
              id="search"
              name="search"
              formControlName="search"
              type="text"
              class="form-control pe-5 search w-100"
              placeholder="Buscar por cnpj"
            />
            <i
              class="ri-search-line position-absolute end-0 my-1 mx-2 text-center"
              style="top: 5px; cursor: pointer"
            ></i>
          </div>
        </form>
      </div>
    </div>

    <div class="mt-2">
      <table mat-table [dataSource]="dataSource" class="table mt-4">
        <caption hidden>
          Tabela fornecedores
        </caption>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="table-head-color">
            {{ headerTable.lbName }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            {{ element.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="document">
          <th mat-header-cell *matHeaderCellDef class="table-head-color">
            {{ headerTable.lbDocument }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            {{ element.document }}
          </td>
        </ng-container>

        <ng-container matColumnDef="uf">
          <th mat-header-cell *matHeaderCellDef class="table-head-color">
            {{ headerTable.lbUf }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            {{ element.address.province }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-head-color column-actions"
          >
            {{ headerTable.lbActions }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            <button
              mat-button
              [matMenuTriggerFor]="menu"
              class="dropdown-button"
            >
              {{ headerTable.lbActions }}
              <i class="ri-arrow-down-s-line"></i>
            </button>
            <mat-menu #menu="matMenu">
              <a
                mat-menu-item
                class="dropdown-button-item"
                *ngFor="let action of options; let i = index"
                (click)="redirect(action, element)"
              >
                {{ action }}
              </a>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
        (page)="handlePageEvent($event)"
        [length]="totalElements"
        [pageIndex]="page"
        [pageSize]="size"
      >
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #isLoading>
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</ng-template>
