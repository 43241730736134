import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, first, map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { of } from 'rxjs';
import * as xmljs from 'xml-js';

@Injectable({
  providedIn: 'root',
})
export class CarrierPortalService {
  constructor(private http: HttpClient) {}

  login(obj: any) {
    return this.http
      .post<any>(
        `${environment.API_PORTAL_TRANSP}/login/portal-transportador`,
        obj
      )
      .pipe(
        map((dados) => {
          return dados;
        }),
        catchError((error) => {
          return of(error);
        })
      );
  }

  logout(idsessao: any) {
    let headers = new HttpHeaders();

    headers = headers
      .append('Content-Type', 'text/xml; charset="UTF-8"')
      .append('idsessao', idsessao);
    return this.http
      .post<any>(
        `${environment.API_PORTAL_TRANSP}/api/wsgko.dll/logout`,
        null,
        {
          headers: headers,
          responseType: 'xml' as 'json',
        }
      )
      .pipe(
        first(),
        map((dados) => {
          return dados;
        }),
        catchError((error) => {
          return of(error);
        })
      );
  }

  //serve para todas as telas de lista
  listGrid(obj: any) {
    let headers = new HttpHeaders();
    let params = new HttpParams();

    headers = headers.append('authorization_portal', String(obj.token));

    if (obj.filtro) {
      let filter = obj.filtro;
      for (let key of Object.keys(filter)) {
        if (
          filter[key] != undefined &&
          filter[key] != null &&
          filter[key] != ''
        ) {
          params = params.append(key, filter[key]);
        }
      }
    }

    let httpOptions = {
      headers,
      params,
    };

    return this.http
      .get(`${environment.API_PORTAL_TRANSP}/listar`, httpOptions)
      .pipe(
        first(),
        map((data) => {
          console.log(data);
          return data;
        }),
        catchError((error) => {
          console.error('Erro na solicitação HTTP:', error);
          return of(error);
        })
      );
  }

  deleteRecord(obj: any) {
    let headers = new HttpHeaders();

    headers = headers
      .append('Content-Type', 'text/xml; charset="UTF-8"')
      .append('idsessao', obj.idsessao);

    return this.http
      .delete(
        `${environment.API_PORTAL_TRANSP}/api/wsgko.dll/cadastros/p${obj.process}?id=${obj.id}`,
        { headers: headers, responseType: 'text' }
      )
      .pipe(
        first(),
        map((data: any) => {
          const jsonData = xmljs.xml2json(data, {
            compact: true,
            spaces: 4,
          });
          const parsedData = JSON.parse(jsonData);
          console.log(parsedData);
          return parsedData;
        }),
        catchError((error) => {
          return of(error);
        })
      );
  }

  getClients(paramsObject: any) {
    let params = new HttpParams();
    params = params.append('size', String(paramsObject.size));
    params = params.append('page', String(paramsObject.page));

    const httpOptions = {
      params,
    };
    return this.http
      .get<any>(`${environment.API_FILTER}/empresa/parceiros`, httpOptions)
      .pipe(
        first(),
        map((data) => {
          return data;
        })
      );
  }
}
