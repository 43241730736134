<mat-toolbar class="toolbar">
  <div fxLayout="row">
    <div fxLayout="column" class="align-bell">
      <button
        [satPopoverAnchor]="popover"
        [satPopoverAnchorFor]="popover"
        (click)="popover.toggle()"
        class="bell"
        id="btn-notifications"
      >
        <img src="assets/img/svg/bell.svg" alt="" />
      </button>
      <div *ngIf="redDot" class="circle" id="bellCircle"></div>
    </div>
    <div fxLayout="column">
      <span class="account">
        {{ user }}
      </span>
    </div>
  </div>
</mat-toolbar>

<sat-popover #popover [horizontalAlign]="'before'" [verticalAlign]="'center'">
  <div id="notifications" class="card">
    <h6 class="title-notification">Notificações</h6>
    <mat-divider></mat-divider>
    <div *ngIf="!redDot" class="no-notifications" id="no-notifications">
      <span>Não há notificações</span>
    </div>

    <div style="overflow-y: auto">
      <div *ngIf="listGroupMessages">
        <a
          (click)="redirectGroupMessages(n, i)"
          *ngFor="let n of listGroupMessages; let i = index"
          class="link"
          id="menu-notification-{{ i }}"
        >
          <div style="padding: 1rem">
            <div fxLayout="row" fxLayoutAlign="space-between">
              <div fxLayout="column" fxFlex="1" style="margin-right: 10px">
                <div class="circle-message"></div>
              </div>
              <div fxLayout="column" fxFlex="99">
                <div>
                  Existem novas mensagens para a oferta
                  <span class="offer">{{ n.numeroOferta }}</span>
                </div>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
        </a>
      </div>

      <div *ngIf="notifications">
        <div *ngFor="let notification of notifications; let i = index">
          <a
            *ngIf="notification.nomeEmpresas == null"
            (click)="redirect(notification, i)"
            class="link"
            id="menu-notification-{{ i }}"
            ><div style="padding: 1rem">
              <div fxLayout="row" fxLayoutAlign="space-between">
                <div fxLayout="column" fxFlex="1" style="margin-right: 10px">
                  <div class="circle-message"></div>
                </div>
                <div fxLayout="column" fxFlex="99">
                  <div>
                    Nova mensagem de {{ typeUserMessage }}
                    <span class="carrier">{{ notification.nomeEmpresa }}</span>
                    na oferta
                    <span class="offer">{{ notification.numeroOferta }}</span>
                  </div>
                </div>
              </div>
            </div>
            <mat-divider></mat-divider>
          </a>
        </div>
      </div>
    </div>
  </div>
</sat-popover>
