<div fxLayout="row" class="container-1" fxLayoutAlign="center">
  <div fxLayout="row" FxlayoutAlign="space-between">
    <div fxLayout="column" fxFlex="60" class="column-title">
      <div>
        <h1 class="title">Plataforma de Fretes</h1>
        <p class="description">
          Conexões estratégicas que potencializam embarcadores e transportadores
          para uma logística imbatível e negócios lucrativos!
        </p>
        <a
          mat-stroked-button
          color="primary"
          class="mt-4 register"
          href="#interest"
        >
          Registrar interesse
        </a>
      </div>
    </div>
    <div fxLayout="column" fxFlex class="img-truck">
      <div fxLayout="row" fxLayoutAlign="center">
        <img
          src="assets/img/svg/caminhao_landing_page.svg"
          alt=""
          width="350px"
        />
      </div>
    </div>
  </div>
</div>
<div fxLayout="row" class="container-2" fxLayoutAlign="center">
  <div fxLayout="column">
    <div fxLayout="row" class="global-title mb-4">
      <div>Conheça algumas <strong> vantagens</strong></div>
    </div>
    <div fxLayout="row">
      <mat-card class="card">
        <mat-card-content>
          <div class="step step-1 completed">
            <div class="v-stepper">
              <div class="circle"></div>
              <div class="line"></div>
            </div>

            <div class="content">
              <p class="title">
                <strong>Conectividade</strong> na sua operação
              </p>
              <p class="description">
                Nossa <strong>plataforma de fretes</strong> oferece uma solução
                simples para agilizar os seus processos na contração de um
                frete, proporcionando visibilidade em tempo real, otimizando
                custos e garantindo uma operação eficiente e confiável.
              </p>
            </div>
          </div>
          <div class="step step-2 completed">
            <div class="v-stepper">
              <div class="circle"></div>
              <div class="line"></div>
            </div>

            <div class="content">
              <p class="title"><strong>Comunicação</strong> facilitada</p>
              <p class="description">
                Nosso chat integrado simplifica a comunicação entre
                <strong>embarcadores</strong> e
                <strong>transportadores</strong>, permitindo negociações ágeis e
                respostas rápidas. Isso agiliza o contato com os motoristas e
                operadores logísticos, promovendo uma interação eficiente e um
                fluxo de trabalho mais fluido.
              </p>
            </div>
          </div>
          <div class="step step-3 completed">
            <div class="v-stepper">
              <div class="circle"></div>
              <div class="line"></div>
            </div>

            <div class="content">
              <p class="title">
                Aumento da <strong>eficiência operacional</strong>
              </p>
              <p class="description">
                Esqueça aquelas incansáveis trocas de e-mails, diversas
                ligações, preços acordados em planilhas e etc. Realize todo esse
                processo de forma simples e ágil através da nossa plataforma.
              </p>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<div fxLayout="row" class="container-grey" fxLayoutAlign="center">
  <div fxLayout="column">
    <div fxLayout="row" class="global-title mb-4">
      <div class="mb-4">
        Encontre o melhor <strong>caminho</strong> para sua
        <strong>solução de logística</strong>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between" class="mt-4">
      <div fxLayout="column" fxFlex class="shipper">
        <div class="solutions">
          <h5 class="solutions-title">Sou <span>embarcador</span></h5>
          <p class="mt-4 solutions-description">
            Quero aumentar minha eficiência logística, reduzindo meus custos ao
            acessar uma plataforma completa que simplifica o gerenciamento de
            fretes
          </p>
        </div>
      </div>
      <div fxLayout="column" fxFlex class="carrier">
        <div class="solutions">
          <h5 class="solutions-title">Sou <span>transportador</span></h5>
          <p class="mt-4 solutions-description">
            Quero aumentar minha rede de contatos e otimizar minha operação com
            uma plataforma completa que oferece valores competitivos e insights
            do mercado
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div fxLayout="row" class="container-grey">
  <div fxLayout="column">
    <div fxLayout="row" class="global-title mb-4">
      <div class="mb-4">
        Depoimentos dos nossos
        <strong>clientes</strong>
      </div>
    </div>
    <div fxLayout="row" class="global-subtitle mb-4">
      <div class="mb-4">
        Veja alguns comentários do nosso evento <strong>Logthink</strong> que
        apresentou a <strong>Plataforma de fretes</strong>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column" fxFlex="32">
        <div class="comments-container">
          <div class="mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4.58341 17.3211C3.55316 16.2275 3 15 3 13.0104C3 9.51092 5.45651 6.37372 9.03059 4.82324L9.92328 6.20085C6.58804 8.00545 5.93618 10.3461 5.67564 11.8221C6.21263 11.5444 6.91558 11.4467 7.60471 11.5106C9.40908 11.6778 10.8312 13.1591 10.8312 15C10.8312 16.933 9.26416 18.5 7.33116 18.5C6.2581 18.5 5.23196 18.0096 4.58341 17.3211ZM14.5834 17.3211C13.5532 16.2275 13 15 13 13.0104C13 9.51092 15.4565 6.37372 19.0306 4.82324L19.9233 6.20085C16.588 8.00545 15.9362 10.3461 15.6756 11.8221C16.2126 11.5444 16.9156 11.4467 17.6047 11.5106C19.4091 11.6778 20.8312 13.1591 20.8312 15C20.8312 16.933 19.2642 18.5 17.3312 18.5C16.2581 18.5 15.232 18.0096 14.5834 17.3211Z"
                fill="#E2D1A1"
              />
            </svg>
          </div>
          <p class="comments text-justify">
            A plataforma vai nos proporcionar uma experiência incrível para o
            negócio. Com acesso a uma ampla variedade de ofertas, sugestões de
            valores competitivos e um gerenciamento simplificado de
            embarcadores, vejo a oportunidade de aumentar nossa receita e
            economizar tempo.
          </p>
          <div class="d-flex flex-row-reverse mt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19.4167 6.67891C20.4469 7.77257 21.0001 9 21.0001 10.9897C21.0001 14.4891 18.5436 17.6263 14.9695 19.1768L14.0768 17.7992C17.4121 15.9946 18.0639 13.6539 18.3245 12.178C17.7875 12.4557 17.0845 12.5533 16.3954 12.4895C14.591 12.3222 13.1689 10.8409 13.1689 9C13.1689 7.067 14.7359 5.5 16.6689 5.5C17.742 5.5 18.7681 5.99045 19.4167 6.67891ZM9.41669 6.67891C10.4469 7.77257 11.0001 9 11.0001 10.9897C11.0001 14.4891 8.54359 17.6263 4.96951 19.1768L4.07682 17.7992C7.41206 15.9946 8.06391 13.6539 8.32446 12.178C7.78746 12.4557 7.08452 12.5533 6.39539 12.4895C4.59102 12.3222 3.16895 10.8409 3.16895 9C3.16895 7.067 4.73595 5.5 6.66895 5.5C7.742 5.5 8.76814 5.99045 9.41669 6.67891Z"
                fill="#E2D1A1"
              />
            </svg>
          </div>
          <div class="user-comments d-flex flex-row-reverse mt-4">
            <span>Logthink</span>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxFlex="32">
        <div class="comments-container">
          <div class="mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4.58341 17.3211C3.55316 16.2275 3 15 3 13.0104C3 9.51092 5.45651 6.37372 9.03059 4.82324L9.92328 6.20085C6.58804 8.00545 5.93618 10.3461 5.67564 11.8221C6.21263 11.5444 6.91558 11.4467 7.60471 11.5106C9.40908 11.6778 10.8312 13.1591 10.8312 15C10.8312 16.933 9.26416 18.5 7.33116 18.5C6.2581 18.5 5.23196 18.0096 4.58341 17.3211ZM14.5834 17.3211C13.5532 16.2275 13 15 13 13.0104C13 9.51092 15.4565 6.37372 19.0306 4.82324L19.9233 6.20085C16.588 8.00545 15.9362 10.3461 15.6756 11.8221C16.2126 11.5444 16.9156 11.4467 17.6047 11.5106C19.4091 11.6778 20.8312 13.1591 20.8312 15C20.8312 16.933 19.2642 18.5 17.3312 18.5C16.2581 18.5 15.232 18.0096 14.5834 17.3211Z"
                fill="#E2D1A1"
              />
            </svg>
          </div>
          <p class="comments text-justify">
            A estrutura de vocês é incrível, fiquei muito satisfeito em conhecer
            a empresa e saber que estamos sendo bem cuidados. A iniciativa da
            Senior em proporcionar proximidade e integração entre os usuários é
            fantástica, me trouxe ainda conhecimento em relação aos negócios,
            tendências do mercado e tecnologia.
          </p>
          <div class="d-flex flex-row-reverse mt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19.4167 6.67891C20.4469 7.77257 21.0001 9 21.0001 10.9897C21.0001 14.4891 18.5436 17.6263 14.9695 19.1768L14.0768 17.7992C17.4121 15.9946 18.0639 13.6539 18.3245 12.178C17.7875 12.4557 17.0845 12.5533 16.3954 12.4895C14.591 12.3222 13.1689 10.8409 13.1689 9C13.1689 7.067 14.7359 5.5 16.6689 5.5C17.742 5.5 18.7681 5.99045 19.4167 6.67891ZM9.41669 6.67891C10.4469 7.77257 11.0001 9 11.0001 10.9897C11.0001 14.4891 8.54359 17.6263 4.96951 19.1768L4.07682 17.7992C7.41206 15.9946 8.06391 13.6539 8.32446 12.178C7.78746 12.4557 7.08452 12.5533 6.39539 12.4895C4.59102 12.3222 3.16895 10.8409 3.16895 9C3.16895 7.067 4.73595 5.5 6.66895 5.5C7.742 5.5 8.76814 5.99045 9.41669 6.67891Z"
                fill="#E2D1A1"
              />
            </svg>
          </div>
          <div class="user-comments d-flex flex-row-reverse mt-4">
            <span>Logthink</span>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxFlex="32">
        <div class="comments-container">
          <div class="mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4.58341 17.3211C3.55316 16.2275 3 15 3 13.0104C3 9.51092 5.45651 6.37372 9.03059 4.82324L9.92328 6.20085C6.58804 8.00545 5.93618 10.3461 5.67564 11.8221C6.21263 11.5444 6.91558 11.4467 7.60471 11.5106C9.40908 11.6778 10.8312 13.1591 10.8312 15C10.8312 16.933 9.26416 18.5 7.33116 18.5C6.2581 18.5 5.23196 18.0096 4.58341 17.3211ZM14.5834 17.3211C13.5532 16.2275 13 15 13 13.0104C13 9.51092 15.4565 6.37372 19.0306 4.82324L19.9233 6.20085C16.588 8.00545 15.9362 10.3461 15.6756 11.8221C16.2126 11.5444 16.9156 11.4467 17.6047 11.5106C19.4091 11.6778 20.8312 13.1591 20.8312 15C20.8312 16.933 19.2642 18.5 17.3312 18.5C16.2581 18.5 15.232 18.0096 14.5834 17.3211Z"
                fill="#E2D1A1"
              />
            </svg>
          </div>
          <p class="comments text-justify">
            Parabéns pelos resultados do Logthink, esperamos estar em novas
            oportunidades. Ficamos a disposição na continuidade dos trabalhos da
            solução para contratação de transportadoras.
          </p>
          <div class="d-flex flex-row-reverse mt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19.4167 6.67891C20.4469 7.77257 21.0001 9 21.0001 10.9897C21.0001 14.4891 18.5436 17.6263 14.9695 19.1768L14.0768 17.7992C17.4121 15.9946 18.0639 13.6539 18.3245 12.178C17.7875 12.4557 17.0845 12.5533 16.3954 12.4895C14.591 12.3222 13.1689 10.8409 13.1689 9C13.1689 7.067 14.7359 5.5 16.6689 5.5C17.742 5.5 18.7681 5.99045 19.4167 6.67891ZM9.41669 6.67891C10.4469 7.77257 11.0001 9 11.0001 10.9897C11.0001 14.4891 8.54359 17.6263 4.96951 19.1768L4.07682 17.7992C7.41206 15.9946 8.06391 13.6539 8.32446 12.178C7.78746 12.4557 7.08452 12.5533 6.39539 12.4895C4.59102 12.3222 3.16895 10.8409 3.16895 9C3.16895 7.067 4.73595 5.5 6.66895 5.5C7.742 5.5 8.76814 5.99045 9.41669 6.67891Z"
                fill="#E2D1A1"
              />
            </svg>
          </div>
          <div class="user-comments d-flex flex-row-reverse mt-4">
            <span>Logthink</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div fxLayout="row" class="container-grey" fxLayoutAlign="center" id="interest">
  <div fxLayout="column" fxFlex="100">
    <div fxLayout="row" class="global-title mb-4">
      <div class="mb-4">
        Registre o
        <strong>seu interesse</strong>
      </div>
    </div>
    <div fxLayout="row" class="global-subtitle mb-4">
      <div class="mb-4">
        Cadastre suas informações para entrarmos em contato
      </div>
    </div>

    <mat-card class="container-interest">
      <mat-card-content>
        <form action="" [formGroup]="interestForm">
          <span class="description-interest">Deixe seu contato aqui</span>

          <div fxLayout="row" fxLayoutAlign="space-between">
            <div fxLayout="column" fxFlex>
              <div class="form-group mt-4 right">
                <label for="user" class="label-input form-label required">
                  Nome de usuário
                </label>
                <input
                  type="text"
                  class="input-data form-control"
                  placeholder="Informe o nome"
                  required
                  name="user"
                  formControlName="user"
                  [ngClass]="{
                    'is-invalid':
                      interestForm.get('user')?.invalid &&
                      interestForm.get('user')?.touched,
                    'is-valid':
                      interestForm.get('user')?.valid &&
                      interestForm.get('user')?.touched
                  }"
                />
                <div
                  *ngIf="
                    (interestForm.get('user')?.errors &&
                      interestForm.get('user')?.touched) ||
                    interestForm.get('user')?.dirty
                  "
                  class="invalid-feedback label-mandatory"
                >
                  Campo obrigatório*
                </div>
              </div>
            </div>
            <div fxLayout="column" fxFlex>
              <div class="form-group mt-4 left">
                <label for="company" class="label-input form-label required">
                  Empresa
                </label>
                <input
                  type="text"
                  class="input-data form-control"
                  placeholder="Informe a empresa"
                  required
                  name="company"
                  formControlName="company"
                  [ngClass]="{
                    'is-invalid':
                      interestForm.get('company')?.invalid &&
                      interestForm.get('company')?.touched,
                    'is-valid':
                      interestForm.get('company')?.valid &&
                      interestForm.get('company')?.touched
                  }"
                />
                <div
                  *ngIf="
                    (interestForm.get('company')?.errors &&
                      interestForm.get('company')?.touched) ||
                    interestForm.get('company')?.dirty
                  "
                  class="invalid-feedback label-mandatory"
                >
                  Campo obrigatório*
                </div>
              </div>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between" class="mt-3">
            <div fxLayout="column" fxFlex>
              <div class="form-group right">
                <label for="email" class="label-input form-label required">
                  E-mail
                </label>
                <input
                  type="email"
                  class="input-data form-control"
                  placeholder="example@senior.com.br"
                  required
                  name="email"
                  formControlName="email"
                  [ngClass]="{
                    'is-invalid':
                      interestForm.get('email')?.invalid &&
                      interestForm.get('email')?.touched,
                    'is-valid':
                      checkEmail() &&
                      interestForm.get('email')?.valid &&
                      interestForm.get('email')?.touched &&
                      interestForm.get('email')?.value.length > 0
                  }"
                />
                <div
                  *ngIf="
                    interestForm.get('email')?.invalid &&
                    interestForm.get('email')?.touched &&
                    interestForm.get('email')?.value.length === 0
                  "
                  class="invalid-feedback label-mandatory"
                >
                  Campo obrigatório*
                </div>
                <div
                  *ngIf="
                    checkEmail() === false &&
                    interestForm.get('email')?.value.length > 0 &&
                    interestForm.get('email')?.errors
                  "
                  class="invalid-feedback label-mandatory"
                >
                  Email inválido!*
                </div>
              </div>
            </div>
            <div fxLayout="column" fxFlex>
              <div class="form-group left">
                <label for="phone" class="label-input form-label required">
                  Telefone
                </label>
                <input
                  mask="(00) 0000-0000||(00) 00000-0000"
                  class="input-data form-control"
                  placeholder="(xx) xxxx-xxxx"
                  required
                  name="phone"
                  formControlName="phone"
                  [ngClass]="{
                      'is-invalid':
                      interestForm.get('phone')?.invalid &&
                      interestForm.get('phone')?.touched,
                      'is-valid':
                      interestForm.get('phone')?.valid &&
                      interestForm.get('phone')?.touched,
                    }"
                />
                <div
                  *ngIf="
                    interestForm.get('phone')?.value.length === 0 &&
                    interestForm.get('phone')?.touched &&
                    interestForm.get('phone')?.invalid
                  "
                  class="invalid-feedback label-mandatory"
                >
                  Campo obrigatório*
                </div>
                <div
                  *ngIf="
                    interestForm.get('phone')?.errors &&
                    interestForm.get('phone')?.value.length > 0 &&
                    interestForm.get('phone')?.dirty
                  "
                  class="invalid-feedback label-mandatory"
                >
                  Telefone inválido!*
                </div>
              </div>
            </div>
          </div>

          <div fxLayout="row" class="mt-3">
            <div fxLayout="column" fxFlex>
              <div class="form-group">
                <label for="message" class="label-input form-label"
                  >Mensagem</label
                >
                <textarea
                  class="form-control"
                  id="message"
                  rows="3"
                  formControlName="message"
                  name="message"
                  placeholder="Deixe sua mensagem aqui."
                  [ngClass]="{
                    'is-valid':
                      interestForm.get('message')?.valid &&
                      interestForm.get('message')?.touched &&
                      interestForm.get('message')?.value.length > 0
                  }"
                ></textarea>
              </div>
            </div>
          </div>

          <div class="d-flex flex-row-reverse mt-4">
            <div>
              <button
                mat-flat-button
                color="primary"
                class="save-button"
                (click)="submitForm()"
                [disabled]="interestForm.invalid"
              >
                enviar
              </button>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div fxLayout="row" class="container-white" fxLayoutAlign="center">
  <mat-card id="about">
    <mat-card-content>
      <div fxLayout="row" fxlayoutAlign="space-between">
        <div fxLayout="column" fxFlex>
          <p class="about-1">Sobre nós</p>
          <p class="about-2">Somos Senior Sistemas</p>
          <p class="about-3">
            Presente em todo território brasileiro e na América Latina, é
            referência em tecnologia para gestão, a companhia tem um dos mais
            completos portfólios para alta performance, oferecendo soluções em
            Gestão Empresarial, Logística, Gestão de Pessoas, Relacionamento com
            Clientes, Supermercados e Gestão de Acesso e Segurança.
          </p>
        </div>
        <div fxLayout="column" fxFlex>
          <div fxLayout="row" fxLayoutAlign="end">
            <img
              src="assets/img/png/image_20.png"
              alt=""
              width="420px"
              class="about-image"
            />
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div fxLayout="row" class="container-grey" fxLayoutAlign="center">
  <div fxLayout="column">
    <div fxLayout="row" class="global-title mb-4">
      <div class="mb-4">
        Conheça nosso portfólio de
        <strong>produtos</strong>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column" fxFlex="31.5">
        <mat-card class="products-image">
          <div>
            <img
              src="assets/img/png/image_26.png"
              alt=""
              width="100%"
              class="products-image"
            />
          </div>
          <div class="products-container">
            <p class="products-title">Soluções para Logística</p>
            <p class="products-description">
              Conte com uma solução eficiente e integrada com a inteligência
              artificial para melhorar a produtividade do seu negócio, desde o
              processo de armazenagem até a entrega final.
            </p>
            <a mat-flat-button color="primary" class="products-btn">Conhecer</a>
          </div>
        </mat-card>
      </div>
      <div fxLayout="column" fxFlex="31.5">
        <mat-card class="products-image">
          <div>
            <img
              src="assets/img/png/image_24.png"
              alt=""
              width="100%"
              class="products-image"
            />
          </div>
          <div class="products-container">
            <p class="products-title">Confirma fácil</p>
            <p class="products-description">
              Acompanhe a gestão de entregas e ocorrências em tempo real com a
              possibilidade de apontamentos offline, além de pesquisa de NPS e
              outros dashboards com informações.
            </p>
            <a mat-flat-button color="primary" class="products-btn">Conhecer</a>
          </div>
        </mat-card>
      </div>
      <div fxLayout="column" fxFlex="31.5">
        <mat-card class="products-image">
          <div>
            <img
              src="assets/img/png/image_25.png"
              alt=""
              width="100%"
              class="products-image"
            />
          </div>
          <div class="products-container">
            <p class="products-title">GKO Frete</p>
            <p class="products-description">
              O GKO FRETE é o sistema TMS líder na gestão de fretes contratados
              por embarcadores junto a transportadores terceirizados.
            </p>
            <a mat-flat-button color="primary" class="products-btn">Conhecer</a>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
