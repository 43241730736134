<div class="wrapper">
    <div class="top box1">
      <img
        class="logo-circle"
        src="https://gko-leilao-frete.s3.amazonaws.com/email/imagens/logo-circle.png"
        alt=""
      />
    </div>
    <div class="top box2"></div>
    <div class="top box4"></div>
    <div class="middle box6">
      <div class="line-title">
        <h3 style="text-align: center; margin-top:2rem">Rastreio de Ordem de Coleta</h3>
        <div class="no-print container-print" >
          <i class="ri-printer-line icon-print" (click)="print()"></i>
        </div>
      </div>
      <div
        style="
          margin-top: 50px;
          text-align: justify;
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 25px;
        "
        *ngIf="isContract; else spot"
      >
        <div style="display: flex; flex-direction: column" fxFlex="30">
          <span><strong>INFORMAÇÕES DA CARGA</strong> </span>
          <span
            >Nº da Oferta Contrato:
            {{ detailsOfOffer?.numeroOfertaContrato }}</span
          >
          <span>Nº Pedido: {{ detailsOfOffer.numeroPedido }}</span>
          <span>Nº Ordem de Coleta: {{ detailsOfOffer.numeroOrdemColeta }}</span>
          <span
            >Origem: {{ detailsOfOffer.origemCidade }},
            {{ detailsOfOffer.origemUf }}</span
          >
          <span
            >Destino: {{ detailsOfOffer.destinoCidade }},
            {{ detailsOfOffer.destinoUf }}</span
          >
          <span>Data Emissão Coleta: {{ detailsOfOffer.dataEmissaoColeta }}</span>
          <span
            >Data Prevista Coleta: {{ detailsOfOffer.dataPrevistaColeta }}</span
          >
          <span
            >Data Prevista Entrega: {{ detailsOfOffer.dataPrevistaEntrega }}</span
          >
          <span>Valor Total da Carga: {{ detailsOfOffer.valorTotalCarga }}</span>
          <span
            >Valor Total Peso Bruto:
            {{ detailsOfOffer.valorTotalPesoBruto }}</span
          >
          <span>Valor Total Cubagem: {{ detailsOfOffer.valorTotalCubagem }}</span>
          <!-- <span>Quantidade de Volumes: {{detailsOfOffer.numeroOfertaContrato}}</span> -->
        </div>
        <div
          style="max-width: 50%; display: flex; flex-direction: column; gap: 20px"
        >
          <div style="display: flex; flex-direction: column">
            <span><strong>INFORMAÇÕES DA TRANSPORTADORA</strong> </span>
            <span
              >Nome do Motorista:
              {{ detailsOfOffer?.nomeMotoristaTransportadora }}</span
            >
            <span
              >Placa do Veículo:
              {{ detailsOfOffer?.placaVeiculoTransportadora }}</span
            >
            <span>CPF/CNPJ: {{ detailsOfOffer?.cnpjTransportadora }}</span>
            <span
              >Razão Social: {{ detailsOfOffer?.razaoSocialTransportadora }}</span
            >
          </div>
          <div style="display: flex; flex-direction: column">
            <span><strong>INFORMAÇÕES DO DESTINATÁRIO </strong></span>
            <span
              >CPF/CNPJ:
              {{
                detailsOfOffer.destinatarioDocumento
                  ? detailsOfOffer.destinatarioDocumento
                  : "Não informado"
              }}</span
            >
            <span
              >Razão Social:
              {{
                detailsOfOffer.destinatarioNome
                  ? detailsOfOffer.destinatarioNome
                  : "Não informado"
              }}</span
            >
            <span
              >CEP:
              {{
                detailsOfOffer.origemCep
                  ? detailsOfOffer.origemCep
                  : "Não informado"
              }}</span
            >
            <span
              >Endereço:
              {{
                detailsOfOffer.destinoLogradouro ? endereco : "Não informado"
              }}</span
            >
            <span
              >Bairro:
              {{
                detailsOfOffer.destinoBairro
                  ? detailsOfOffer.destinoBairro
                  : "Não informado"
              }}</span
            >
            <span>Cidade: {{ detailsOfOffer.destinoCidade }}</span>
            <span>Estado: {{ detailsOfOffer.destinoUf }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom box7">
      <div class="mt-4 no-print-bottom">
        <button mat-flat-button color="primary" (click)="print()">Imprimir</button>
      </div>
  
      <div class="item footer">
        <div>
          <p class="access-platform">
            Caso queira alinhar alguma coisa ou interagir melhor com o seu cliente
            acesse a plataforma clicando aqui.
          </p>
          <h6>
            <a href="https://plataformadefretes.com.br">https://plataformadefretes.com.br</a>
          </h6>
  
          <img
            class="logo-senior"
            src="https://gko-leilao-frete.s3.amazonaws.com/email/imagens/logo-senior.png"
            alt="logo senior"
          />
        </div>
      </div>
    </div>
  </div>
  
  <ng-template #spot>
    <div class="container-offer">
      <div>
        <div class="block">
          <span><strong>INFORMAÇÕES GERAIS</strong> </span>
          <span class="mt-3"
            ><strong>Embarcador:</strong>
            {{ detailsOfOfferSpot?.nomeEmbarcador }} -
            {{ cpfOrCnpjMask(detailsOfOfferSpot.cnpjEmbarcador) }}</span
          >
          <span
            ><strong>Tipo de oferta:</strong>
            {{ detailsOfOfferSpot.tipoOferta }}</span
          >
          <span
            ><strong>Valor da oferta de frete:</strong>
            {{ detailsOfOfferSpot.valorOferta | currency }}</span
          >
          <div *ngIf="detailsOfOfferSpot.certificacoes" style="margin-top: 20px">
            <span><strong>Certificações:</strong> </span>
            <ul *ngFor="let certification of detailsOfOfferSpot.certificacoes">
              <li>
                <div style="margin-top: 20px;">
                  <div class="circle"></div>
                  <p style="margin-left: 15px" class="certifications">{{
                    certification.replaceAll("_", " ")
                  }}</p>
                </div>
              </li>
            </ul>
          </div>
          <div *ngIf="!detailsOfOfferSpot.certificacoes" style="margin-top: 20px">
            <span>Não informado </span>
          </div>
          <span
            ><strong>Rastreamento de veículo:</strong>
            {{
              detailsOfOfferSpot.rastreamentoVeiculo
                ? 'Sim'
                : "Não informado"
            }}</span
          >
        </div>
  
        <div class="block mt-3">
          <span><strong>INFORMAÇÕES DA CARGA</strong> </span>
          <span class="mt-3"
            ><strong>Nº da Oferta Spot:</strong>
            {{ detailsOfOfferSpot?.numeroOferta ? detailsOfOfferSpot?.numeroOferta : 'Não informado' }}</span
          >
          <span
            ><strong>Nº Ordem de Coleta:</strong>
            {{
              detailsOfOfferSpot?.ordemColeta
                ? detailsOfOfferSpot?.ordemColeta
                : "Não informado"
            }}</span
          >
          <span
            ><strong>Origem:</strong> {{ detailsOfOfferSpot?.origem.cidade }},
            {{ detailsOfOfferSpot?.origem.uf }}</span
          >
          <span
            ><strong>Destino:</strong> {{ detailsOfOfferSpot?.destino.cidade }},
            {{ detailsOfOfferSpot?.destino.uf }}</span
          >
          <span
            ><strong>Data Prevista Coleta:</strong>
            {{
              detailsOfOfferSpot?.dataPrevisaoColeta
                ? formatDate(detailsOfOfferSpot?.dataPrevisaoColeta)
                : "Não informado"
            }}</span
          >
          <span
            ><strong>Data Prevista Entrega:</strong>
            {{
              detailsOfOfferSpot?.dataPrevistaEntrega
                ? formatDate(detailsOfOfferSpot?.dataPrevistaEntrega)
                : "Não informado"
            }}</span
          >
          <span
            ><strong>Tipo de produto:</strong>
            {{
              detailsOfOfferSpot?.tipoProduto
                ? detailsOfOfferSpot?.tipoProduto.replaceAll("_", " ")
                : "Não informado"
            }}</span
          >
          <span
            ><strong>Valor Total da Carga:</strong>
            {{ detailsOfOfferSpot?.valorTotalCarga | currency }}</span
          >
          <span
            ><strong>Valor Total Peso Bruto:</strong>
            {{ detailsOfOfferSpot?.valorTotalPesoBruto }} kg</span
          >
          <span
            ><strong>Quantidade de Volumes:</strong>
            {{
              detailsOfOfferSpot?.volume
                ? detailsOfOfferSpot?.volume
                : "Não informado"
            }}</span
          >
          <span
            ><strong>Tipo de carga:</strong>
            {{
              detailsOfOfferSpot?.tipoDeCarga?.length > 0
                ? detailsOfOfferSpot?.tipoDeCarga
                : "Não informado"
            }}</span
          >
          <span
            ><strong>Tipo de embalagem:</strong>
            {{
              detailsOfOfferSpot?.tipoEmbalagem
                ? detailsOfOfferSpot?.tipoEmbalagem
                : "Não informado"
            }}</span
          >
          <span
            ><strong>Altura:</strong>
            {{
              detailsOfOfferSpot?.altura 
                ? detailsOfOfferSpot?.altura + 'cm'
                : "Não informado"
            }}</span
          >
          <span
            ><strong>Largura:</strong>
            {{
              detailsOfOfferSpot?.largura
                ? detailsOfOfferSpot?.largura  + 'cm'
                : "Não informado"
            }}</span
          >
          <span
            ><strong>Comprimento:</strong>
            {{
              detailsOfOfferSpot?.comprimento
                ? detailsOfOfferSpot?.comprimento + 'cm'
                : "Não informado"
            }}</span
          >
          <span
            ><strong>Cubagem (m³):</strong>
            {{
              detailsOfOfferSpot?.cubagem
                ? detailsOfOfferSpot?.cubagem + 'm³'
                : "Não informado"
            }}</span
          >
          <span
            ><strong>Nº do pedido:</strong>
            {{
              detailsOfOfferSpot?.numeroPedido?.length > 0
                ? detailsOfOfferSpot?.numeroPedido
                : "Não informado"
            }}</span
          >
          <span
            ><strong>Nº da nota:</strong>
            {{
              detailsOfOfferSpot?.numeroNota?.length > 0
                ? detailsOfOfferSpot?.numeroNota
                : "Não informado"
            }}</span
          >
        </div>
  
        <div class="block mt-3">
          <span><strong>INFORMAÇÕES DA TRANSPORTADORA</strong> </span>
          <span class="mt-3"
            ><strong>Razão social:</strong>
            {{ detailsOfOfferSpot?.nomeTransportadora }}</span
          >
          <span
            ><strong>CPF/CNPJ:</strong>
            {{ cpfOrCnpjMask(detailsOfOfferSpot?.cnpjTransportadora) }}</span
          >
          <span
            ><strong>Telefone:</strong>
            {{
              detailsOfOfferSpot?.telefoneTransportadora
                ? detailsOfOfferSpot?.telefoneTransportadora
                : "Não informado"
            }}</span
          >
          <span
            ><strong>E-mail:</strong>
            {{ detailsOfOfferSpot?.emailTransportadora }}</span
          >
        </div>
        <div class="block mt-3">
          <span><strong>INFORMAÇÕES DO DESTINATÁRIO </strong></span>
          <span class="mt-3"
            ><strong>Razão Social:</strong>
            {{
              detailsOfOfferSpot?.nomeDestinatario
                ? detailsOfOfferSpot.nomeDestinatario
                : "Não informado"
            }}</span
          >
          <span
            ><strong>CPF/CNPJ:</strong>
            {{
              detailsOfOfferSpot?.documentoDestinatario
                ? cpfOrCnpjMask(detailsOfOfferSpot.documentoDestinatario)
                : "Não informado"
            }}</span
          >
          <span
            ><strong>CEP:</strong>
            {{
              detailsOfOfferSpot?.destino.cep
                ? cepMask(detailsOfOfferSpot?.destino?.cep)
                : "Não informado"
            }}</span
          >
          <span
            ><strong>Endereço:</strong>
            {{
              detailsOfOfferSpot?.destino?.logradouro
                ? detailsOfOfferSpot?.destino?.logradouro
                : "Não informado"
            }}</span
          >
          <span
            ><strong>Bairro:</strong>
            {{
              detailsOfOfferSpot?.destino?.bairro
                ? detailsOfOfferSpot?.destino?.bairro
                : "Não informado"
            }}</span
          >
          <span
            ><strong>Cidade:</strong>
            {{ detailsOfOfferSpot?.destino?.cidade }}</span
          >
          <span
            ><strong>Estado:</strong> {{ detailsOfOfferSpot?.destino?.uf }}</span
          >
        </div>
      </div>
    </div>
  </ng-template>
  
  <ng-template #isLoading>
    <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
  </ng-template>