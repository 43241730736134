import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';

@Injectable({
  providedIn: 'root',
})

export class SortUtil{

  constructor(){}


  public order(parametro: any , direction:any){

  const  ordenarSort: Sort = { active: parametro, direction: direction };
   const ordenarParam = ordenarSort.active + ',' + ordenarSort.direction;

   return ordenarParam;
  }
}



