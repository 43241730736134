<div class="container-confirm">
  <h6 mat-dialog-title>Redefinição de senha</h6>
  <mat-dialog-content>
    <div fxLayout="row wrap" class="mt-2">
      <p class="description">Informe um email para redefinir a senha.</p>
    </div>
    <div class="recaptcha">
      <div #recaptcha></div>
    </div>
    <form [formGroup]="forgotPasswordForm">
      <div >
        <label class="label-input form-label required" for="email">
          Email
        </label>
        <input
          matInput
          name="email"
          type="email"
          class="form-control input-data w-100"
          formControlName="email"
          placeholder="Digite seu email"
          required
          [ngClass]="{
            'is-invalid':
              forgotPasswordForm.get('email')?.invalid &&
              forgotPasswordForm.get('email')?.touched,
            'is-valid':
              checkEmail() &&
              forgotPasswordForm.get('email')?.valid &&
              forgotPasswordForm.get('email')?.touched &&
              forgotPasswordForm.get('email')?.value.length > 0
          }"
        />
        <div
          *ngIf="
            forgotPasswordForm.get('email')?.invalid &&
            forgotPasswordForm.get('email')?.touched &&
            forgotPasswordForm.get('email')?.value.length === 0
          "
          class="invalid-feedback label-mandatory"
        >
          Campo obrigatório*
        </div>
        <div
          *ngIf="
            checkEmail() === false &&
            forgotPasswordForm.get('email')?.value.length > 0 &&
            forgotPasswordForm.get('email')?.errors
          "
          class="invalid-feedback label-mandatory"
        >
          Email inválido!*
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <div class="d-flex flex-row-reverse mt-3">
    <div mat-dialog-actions>
      <button
        mat-flat-button
        mat-dialog-close
        class="btn-cancel"
        (click)="close()"
      >
        Cancelar
      </button>
      <button
        id="button-recaptcha"
        mat-flat-button
        color="primary"
        cdkFocusInitial
        class="btn-send"
        (click)="sendData()"
        [disabled]="recaptchaStage()"
      >
        Enviar
      </button>
    </div>
  </div>
</div>
