import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {

constructor(private readonly http: HttpClient) { }

getNotifications() {

  return this.http
    .get<any>(`${environment.API_BACK}/chat/notificacoes`)
    .pipe(
      first(),
      map((data) => {
        return data;
      })
    );
}

}
