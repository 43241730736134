import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyProfileService {

  constructor(protected http: HttpClient) { }

  forgotMyPassword(email: string, token: string) {
    let headers = new HttpHeaders()
    let obj =
    {
      email: email,
      tokenRecaptcha: token
    }
    return this.http
      .post<any>(`${environment.API_LOGIN}/esqueci-minha-senha`, obj, {
        headers,
        responseType: 'text' as 'json',
      })
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );

  }


}
