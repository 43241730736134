import { HtmlParser } from '@angular/compiler';
import { Component, DoCheck } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements DoCheck {
  currentRoute: string;
  mobile: boolean = false;
  selectedLanguage: string = 'Brasil';
  languages = [
    {
      label: 'Brasil',
      value: 'Brasil',
    },
  ];

  constructor(private router: Router) {
    this.currentRoute = '';
    this.router.events.subscribe((event: any) => {
      if (!(event instanceof NavigationEnd)) return;
      this.currentRoute = event.url;
    });
  }

  ngDoCheck(): void {
    this.checkMobile();
  }

  checkMobile() {
    if (window.innerWidth <= 901) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  hiddenMenu() {
    if (this.mobile) {
      let el = document.getElementById('menu-toggle');
      if ((el as HTMLInputElement).checked) {
        (el as HTMLInputElement).checked = false;
      }
    }
  }
}
