<div class="container-authentication">
  <mat-dialog-title> <h6 class="mt-2">Autenticação</h6></mat-dialog-title>
  <mat-dialog-content>
    <div fxLayout="row wrap">
      <p class="description">
        Informe as credencias da GKO FRETE para acessar o portal:
      </p>
    </div>
    <form [formGroup]="loginForm">
      <div class="form-group">
        <label class="label-input form-label required" for="database">
          Embarcador
        </label>
        <ng-select
          *ngIf="shippers.length > 0"
          name="shipper"
          formControlName="shipper"
          [items]="shippers"
          [closeOnSelect]="true"
          [searchable]="true"
          [clearable]="false"
          [multiple]="false"
          bindLabel="label"
          bindValue="value"
          required
          [ngClass]="{
            'invalid is-invalid':
              loginForm.get('shipper')?.touched &&
              loginForm.get('shipper')?.invalid,
            'valid is-valid': loginForm.get('shipper')?.value?.length > 0
          }"
        >
          <ng-template
            ng-label-tmp
            let-item="item"
            let-index="index"
            let-search="searchTerm"
          >
            {{ item.label }}
          </ng-template>
        </ng-select>
        <div
          *ngIf="
            loginForm.get('shipper')?.touched &&
            loginForm.get('shipper')?.invalid
          "
          class="invalid-feedback label-mandatory"
        >
          Campo obrigatório*
        </div>
      </div>
      <div class="form-group">
        <label class="label-input form-label required" for="database">
          Banco de dados
        </label>
        <input
          required
          matInput
          name="database"
          class="form-control input-data w-100"
          formControlName="database"
          [ngClass]="{
            'is-invalid':
              loginForm.get('database')?.touched &&
              loginForm.get('database')?.invalid,
            'is-valid': loginForm.get('database')?.value?.length > 0
          }"
        />
        <div
          *ngIf="
            loginForm.get('database')?.touched &&
            loginForm.get('database')?.invalid
          "
          class="invalid-feedback label-mandatory"
        >
          Campo obrigatório*
        </div>
      </div>
      <div class="form-group">
        <label class="label-input form-label required" for="user">
          Usuário
        </label>
        <input
          required
          matInput
          name="user"
          class="form-control input-data w-100"
          formControlName="user"
          [ngClass]="{
            'is-invalid':
              loginForm.get('user')?.touched && loginForm.get('user')?.invalid,
            'is-valid': loginForm.get('user')?.value?.length > 0
          }"
        />
        <div
          *ngIf="
            loginForm.get('user')?.touched && loginForm.get('user')?.invalid
          "
          class="invalid-feedback label-mandatory"
        >
          Campo obrigatório*
        </div>
      </div>
      <div class="form-group mt-2 position-relative w-100">
        <label for="newPassword" class="label-input form-label required"
          >Senha
        </label>
        <input
          required
          type="password"
          id="password"
          class="input-data form-control"
          matInput
          name="password"
          formControlName="password"
          [ngClass]="{
            'is-invalid':
              loginForm.get('password')?.touched &&
              loginForm.get('password')?.invalid,
            'is-valid': loginForm.get('password')?.value?.length > 0
          }"
        />
        <div
          *ngIf="
            loginForm.get('password')?.touched &&
            loginForm.get('password')?.invalid
          "
          class="invalid-feedback label-mandatory"
        >
          Campo obrigatório*
        </div>
        <span
          (click)="visiblePassword('password')"
          class="btn btn-sm position-absolute top-1 end-0 my-1 mx-2 text-center align-eyes"
        >
          <i *ngIf="!this.visiblePasswordCheck" class="ri-eye-close-fill"></i>
          <i *ngIf="this.visiblePasswordCheck" class="ri-eye-fill"></i>
        </span>
      </div>
    </form>
    <div id="loading" [hidden]="loaded">
      <mat-spinner class="spinner"></mat-spinner>
    </div>
  </mat-dialog-content>
  <div class="d-flex flex-row-reverse mt-4">
    <div mat-dialog-actions>
      <button
        mat-flat-button
        mat-dialog-close
        class="btn-cancel"
        (click)="close()"
      >
        Cancelar
      </button>
      <button
        [disabled]="loginForm.invalid"
        mat-flat-button
        color="primary"
        cdkFocusInitial
        class="btn-send"
        (click)="login()"
      >
        Confirmar
      </button>
    </div>
  </div>
</div>

<ng-template #isLoading>
  <ng-template class="container-spinner">
    <mat-spinner class="spinner"></mat-spinner>
  </ng-template>
</ng-template>
