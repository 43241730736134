import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MaskDirectiveComponent } from "./mask-directive.component";
import { MaskFilterDirective } from "./mask-filter-directive.component";
import { MaskIntDirective } from "./mask-int-directive.component";
import { MaskWeightDirective } from "./mask-weight-directive.component";
import { MaxValueDirective } from "./mask-max-value-directive.component";
import { MaskDistanceDirective } from "./mask-distance-directive.component";

@NgModule({
    declarations: [
        MaskDirectiveComponent,
        MaskFilterDirective,
        MaskIntDirective,
        MaskWeightDirective,
        MaxValueDirective,
        MaskDistanceDirective,
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,

    ],
    exports: [MaskDirectiveComponent, MaskFilterDirective, MaskIntDirective, MaskWeightDirective, MaxValueDirective, MaskDistanceDirective,]
})
export class MaskDirectiveModule { }










