import { Component } from '@angular/core';

@Component({
  selector: 'app-my-company-form',
  templateUrl: './my-company-form.component.html',
  styleUrls: ['./my-company-form.component.scss']
})
export class MyCompanyFormComponent {

}
