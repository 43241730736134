<div class="container-fluid mt-5">
  <div class="card-body container">
    <div class="row mb-2 text-center">
      <h5 class="complete-title">E-mail confirmado!</h5>
    </div>
    <div class="description mt-3">
      <p class="complete-text px-4" id="textConclusion">
        A Plataforma já está liberada para você fazer seu login.
      </p>
      <p class="complete-text px-4" id="textConclusion">
        Acesse agora, amplie suas conexões e leve sua empresa a novos
        horizontes.
      </p>
    </div>
    <div class="row mt-3 mb-2 justify-content-center">
      <a routerLink="/login" mat-flat-button color="primary" class="access-platform"
        >Acessar plataforma</a
      >
    </div>
    <div class="row mb-2 justify-content-center">
      <img src="/assets/img/svg/New-message-pana.svg" alt="" width="250px" height="250px" />
    </div>
  </div>
</div>
