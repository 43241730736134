<mat-card class="container-my-users">
  <mat-card-content>
    <h6 *ngIf="!id" class="mt-2">Cadastro de usuário</h6>
    <h6 *ngIf="id" class="mt-2">Edição de usuário</h6>
    <form [formGroup]="userForm">
      <p class="form-section-title mt-3">Dados do usuário <i class="ri-information-fill info-green hover-text">
        <div class="tooltip-text right-tooltip">
          <p class="p-tooltip">
            Somente o próprio usuário consegue alterar as informações de: Nome de usuário, email e telefone.
          </p>
        </div>
      </i></p>

      <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column" class="right" fxFlex>
          <div class="form-group">
            <label class="label-input form-label required" for="name">
              Nome de usuário
            </label>
            <input
              [readOnly]="id"
              name="name"
              class="form-control input-data"
              formControlName="name"
              placeholder="Digite seu nome"
              [required]="id"
              [ngClass]="{
                'is-invalid':
                  userForm.get('name')?.invalid &&
                  userForm.get('name')?.touched,
                'is-valid':
                  userForm.get('name')?.valid &&
                  userForm.get('name')?.touched &&
                  userForm.get('name')?.value !== null &&
                  userForm.get('name')?.value !== '' && !id
              }"
            />
            <div
              *ngIf="
                userForm.get('name')?.invalid && userForm.get('name')?.touched
              "
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
          </div>
        </div>

        <div fxLayout="column" class="left" fxFlex>
          <div class="form-group">
            <label
              class="label-input form-label required"
              for=""
              *ngIf="options.length > 0"
            >
              Tipo de Usuário
            </label>
            <ng-select
              *ngIf="options.length > 0"
              required
              name="userType"
              formControlName="userType"
              [items]="options"
              [closeOnSelect]="true"
              [searchable]="true"
              [clearable]="false"
              bindLabel="label"
              bindValue="value"
              [ngClass]="{
                'invalid is-invalid':
                  userForm.get('userType')?.invalid &&
                  userForm.get('userType')?.touched,
                'valid is-valid':
                  userForm.get('userType')?.valid &&
                  userForm.get('userType')?.touched &&
                  userForm.get('userType')?.value !== null &&
                  userForm.get('userType')?.value !== ''
              }"
            >
              <ng-template
                ng-label-tmp
                let-item="item"
                let-index="index"
                let-search="searchTerm"
              >
                {{ item.label }}
              </ng-template>
            </ng-select>
            <div
              *ngIf="
                userForm.get('userType')?.invalid &&
                userForm.get('userType')?.touched
              "
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column" class="right" fxFlex>
          <div class="form-group mt-3">
            <label class="label-input form-label required" for="email">
              Email
            </label>
            <input
              matInput
              [readOnly]="id"
              name="email"
              type="email"
              class="form-control input-data w-100"
              formControlName="email"
              placeholder="Digite seu email"
              [required]="id"
              [ngClass]="{
                'is-invalid':
                  userForm.get('email')?.invalid &&
                  userForm.get('email')?.touched,
                'is-valid':
                  checkEmail() &&
                  userForm.get('email')?.valid &&
                  userForm.get('email')?.touched &&
                  userForm.get('email')?.value !== null && !id
              }"
            />
            <div
              *ngIf="
                (userForm.get('email')?.invalid &&
                  userForm.get('email')?.touched &&
                  userForm.get('email')?.value === null) ||
                userForm.get('email')?.value === ''
              "
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
            <div
              *ngIf="
                checkEmail() === false &&
                userForm.get('email')?.value !== null &&
                userForm.get('email')?.value !== '' &&
                userForm.get('email')?.errors
              "
              class="invalid-feedback label-mandatory"
            >
              Email inválido!*
            </div>
          </div>
        </div>
        <div fxLayout="column" class="left" fxFlex>
          <div class="form-group mt-3">
            <label class="label-input form-label required" for="phoneNumber">
              Telefone
            </label>
            <input
              [readonly]="id"
              mask="(00) 0000-0000||(00) 00000-0000"
              name="phoneNumber"
              class="input-data form-control"
              formControlName="phoneNumber"
              placeholder="Digite o telefone"
              [required]="id"
              [ngClass]="{
                'is-invalid':
                userForm.get('phoneNumber')?.invalid &&
                userForm.get('phoneNumber')?.touched,
                'is-valid':
                userForm.get('phoneNumber')?.valid &&
                userForm.get('phoneNumber')?.touched && !id,
              }"
            />
            <div
              *ngIf="
                userForm.get('phoneNumber')?.value === null ||
                (userForm.get('phoneNumber')?.value === '' &&
                  userForm.get('phoneNumber')?.touched &&
                  userForm.get('phoneNumber')?.invalid)
              "
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
            <div
              *ngIf="
                userForm.get('phoneNumber')?.value != null &&
                userForm.get('phoneNumber')?.invalid &&
                userForm.get('phoneNumber')?.value.length > 0
              "
              class="invalid-feedback label-mandatory"
            >
              Telefone inválido!*
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between" class="mt-3">
        <div fxLayout="column" class="right" fxFlex>
          <div *ngIf="!id" class="form-group">
            <label for="" class="label-input form-label required">
              Senha
            </label>
            <input
              [readonly]="id"
              type="password"
              class="input-fields form-control"
              placeholder="Senha"
              [required]="id"
              name="password"
              formControlName="password"
              id="password"
              [ngClass]="{
                'is-invalid':
                 userForm.get('password')?.invalid &&
                 userForm.get('password')?.touched,
                'is-valid':
                userForm.get('password')?.valid &&
                userForm.get('password')?.touched,

              }"
            />
            <div
              *ngIf="
                userForm.get('password')?.invalid &&
                userForm.get('password')?.touched
              "
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
          </div>
        </div>
        <div *ngIf="!id" fxLayout="column" class="left" fxFlex>
          <div class="form-group">
            <label for="" class="label-input form-label required">
              Repita a senha
            </label>
            <input
              [readonly]="id"
              type="password"
              class="input-fields form-control"
              placeholder="Repita a senha"
              (change)="validConfirmPassword()"
              [required]="id"
              id="confirmPassword"
              name="confirmPassword"
              formControlName="confirmPassword"
              [ngClass]="{
                'is-invalid':
                userForm.get('confirmPassword')?.invalid &&
                userForm.get('confirmPassword')?.touched,
                'is-valid':
                userForm.get('confirmPassword')?.valid &&
                userForm.get('confirmPassword')?.touched && (userForm.get('confirmPassword')?.value == userForm.get('password')?.value) ,
                'invalid-confirm': (userForm.get('confirmPassword')?.value !== '' && userForm.get('password')?.value !== '' ) && (userForm.get('confirmPassword')?.value !== userForm.get('password')?.value),
              }"
            />
            <div
              *ngIf="
                userForm.get('confirmPassword')?.invalid &&
                userForm.get('confirmPassword')?.value === '' &&
                userForm.get('confirmPassword')?.touched
              "
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
            <div
              *ngIf="
                userForm.get('confirmPassword')?.value?.length > 0 &&
                userForm.get('confirmPassword')?.touched &&
                !confirmPasswordValue
              "
              class="label-mandatory"
              style="
                margin-top: 0.25rem !important;
                color: var(--bs-form-invalid-color) !important;
              "
            >
              Confirmação de senha incorreta*
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-2 mt-2" *ngIf="!id">
        <div class="col col-md-6">
          <div class="form-check">
            <input
              name="showPassword"
              type="checkbox"
              class="form-check-input"
              (change)="visiblePassword($event, 'password')"
            />
            <label
              class="form-check-label view-password mt-1"
              for="showPassword"
            >
              Mostrar senha
            </label>
          </div>
        </div>
        <div class="col col-md-6">
          <div class="form-check">
            <input
              name="showConfirmPassword"
              type="checkbox"
              class="form-check-input"
              (change)="visiblePassword($event, 'confirmPassword')"
            />
            <label
              class="form-check-label view-password mt-1"
              for="showConfirmPassword"
            >
              Mostrar senha
            </label>
          </div>
        </div>
      </div>

      <div fxLayout="row" *ngIf="!id">
        <div fxLayout="column" fxFlex class="right mt-3">
          <div class="container-passwordvalidate">
            <p class="text-password">Sua senha deve conter:</p>
    
            <div>
              <p>
                <i
                  *ngIf="emptyPassword || (!emptyPassword && validLength)"
                  class="ri-checkbox-circle-fill icon-check"
                  [ngClass]="{
                    'check-none': emptyPassword,
                    'check-yes': !emptyPassword && validLength
                  }"
                ></i
                ><i
                  class="ri-close-circle-fill icon-check check-no"
                  *ngIf="!emptyPassword && !validLength"
                ></i
                >Entre 8 e 30 caracteres
              </p>
              <p>
                <i
                  *ngIf="
                    emptyPassword || (!emptyPassword && validUpper && validLower)
                  "
                  class="ri-checkbox-circle-fill icon-check"
                  [ngClass]="{
                    'check-none': emptyPassword,
                    'check-yes': !emptyPassword && validUpper && validLower
                  }"
                ></i
                ><i
                  class="ri-close-circle-fill icon-check check-no"
                  *ngIf="!emptyPassword && (!validUpper || !validLower)"
                ></i
                >Letras maiúsculas e minúsculas
              </p>
              <p>
                <i
                  *ngIf="emptyPassword || (!emptyPassword && validNumber)"
                  class="ri-checkbox-circle-fill icon-check"
                  [ngClass]="{
                    'check-none': emptyPassword,
                    'check-yes': !emptyPassword && validNumber
                  }"
                ></i
                ><i
                  class="ri-close-circle-fill icon-check check-no"
                  *ngIf="!emptyPassword && !validNumber"
                ></i
                >Pelo menos um número
              </p>
              <p>
                <i
                  *ngIf="emptyPassword || (!emptyPassword && validSpecialCharacter)"
                  class="ri-checkbox-circle-fill icon-check"
                  [ngClass]="{
                    'check-none': emptyPassword,
                    'check-yes': !emptyPassword && validSpecialCharacter
                  }"
                ></i
                ><i
                  class="ri-close-circle-fill icon-check check-no"
                  *ngIf="!emptyPassword && !validSpecialCharacter"
                ></i
                >Pelo menos um caractere especial
              </p>
            </div>
          </div>
    
        </div>
        <div fxLayout="column" fxFlex class="left">
          <div fxLayout="column" fxFlex>
            <div class="form-group mt-3">
              <label class="label-input form-label required" for="embarcadores">
                Selecione os Embarcadores para Gestão
              </label>
              <ng-select
                name="shippersByUser"
                formControlName="shippersByUser"
                [items]="shippersByUser"
                [multiple]="true"
                [closeOnSelect]="false"
                [searchable]="false"
                required
                bindLabel="label"
                bindValue="value"
                [ngClass]="{
                  'invalid is-invalid':
                   userForm.get('shippersByUser')?.invalid &&
                   userForm.get('shippersByUser')?.touched,
                  'valid is-valid':
                  userForm.get('shippersByUser')?.valid &&
                  userForm.get('shippersByUser')?.touched || userForm.get('shippersByUser')?.valid && userForm.get('shippersByUser')?.value.length > 0,
  
                }"
              ></ng-select>
              <div
                *ngIf="
                  (userForm.get('shippersByUser')?.invalid &&
                    userForm.get('shippersByUser')?.value.length == 0) ||
                  (userForm.get('shippersByUser')?.dirty &&
                    userForm.get('shippersByUser')?.value.length == 0)
                "
                class="invalid-feedback label-mandatory"
              >
                Campo obrigatório*
              </div>
            </div>
          </div>
          <div fxLayout="column" fxFlex>
            <div class="form-group" style="margin-top: -15px;">
              <label class="label-input form-label required" for="alcada">
                Alçada
              </label>
              <input
                matInput
                prefix="R$ "
                mask="separator.2"
                thousandSeparator="."
                name="alcada"
                class="input-data form-control"
                formControlName="alcada"
                name="totalValue"
                placeholder="Valor máximo que poderá aprovar/criar a oferta de frete"
                [required]="true"
                [ngClass]="{
                      'is-invalid':
                      userForm.get('alcada')?.invalid &&
                      userForm.get('alcada')?.touched,
                      'is-valid':
                      userForm.get('alcada')?.valid &&
                      userForm.get('alcada')?.touched,
                    }"
              />
              <div
                *ngIf="
                  userForm.get('alcada')?.value === null ||
                  (userForm.get('alcada')?.value === '' &&
                    userForm.get('alcada')?.touched &&
                    userForm.get('alcada')?.invalid)
                "
                class="invalid-feedback label-mandatory"
              >
                Campo obrigatório*
              </div>
              <div
                *ngIf="
                  userForm.get('alcada')?.value != null &&
                  userForm.get('alcada')?.invalid &&
                  userForm.get('alcada')?.value.length > 0
                "
                class="invalid-feedback label-mandatory"
              >
                Alcada inválida!*
              </div>
            </div>
          </div>
        </div>
      </div>


      <div fxLayout="row" fxLayoutAlign="space-between" *ngIf="id">
        <div fxLayout="column" class="right" fxFlex>
          <div class="form-group mt-3">
            <label class="label-input form-label required" for="embarcadores">
              Selecione os Embarcadores para Gestão
            </label>
            <ng-select
              name="shippersByUser"
              formControlName="shippersByUser"
              [items]="shippersByUser"
              [multiple]="true"
              [closeOnSelect]="false"
              [searchable]="false"
              required
              bindLabel="label"
              bindValue="value"
              [ngClass]="{
                'invalid is-invalid':
                 userForm.get('shippersByUser')?.invalid &&
                 userForm.get('shippersByUser')?.touched,
                'valid is-valid':
                userForm.get('shippersByUser')?.valid &&
                userForm.get('shippersByUser')?.touched || userForm.get('shippersByUser')?.valid && userForm.get('shippersByUser')?.value.length > 0,

              }"
            ></ng-select>
            <div
              *ngIf="
                (userForm.get('shippersByUser')?.invalid &&
                  userForm.get('shippersByUser')?.value.length == 0) ||
                (userForm.get('shippersByUser')?.dirty &&
                  userForm.get('shippersByUser')?.value.length == 0)
              "
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
          </div>
        </div>
        <div fxLayout="column" class="left" fxFlex>
          <div class="form-group mt-3">
            <label class="label-input form-label required" for="alcada">
              Alçada
            </label>
            <input
              matInput
              prefix="R$ "
              mask="separator.2"
              thousandSeparator="."
              name="alcada"
              class="input-data form-control"
              formControlName="alcada"
              name="totalValue"
              placeholder="Valor máximo que poderá aprovar/criar a oferta de frete"
              [required]="true"
              [ngClass]="{
                    'is-invalid':
                    userForm.get('alcada')?.invalid &&
                    userForm.get('alcada')?.touched,
                    'is-valid':
                    userForm.get('alcada')?.valid &&
                    userForm.get('alcada')?.touched,
                  }"
            />
            <div
              *ngIf="
                userForm.get('alcada')?.value === null ||
                (userForm.get('alcada')?.value === '' &&
                  userForm.get('alcada')?.touched &&
                  userForm.get('alcada')?.invalid)
              "
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
            <div
              *ngIf="
                userForm.get('alcada')?.value != null &&
                userForm.get('alcada')?.invalid &&
                userForm.get('alcada')?.value.length > 0
              "
              class="invalid-feedback label-mandatory"
            >
              Alcada inválida!*
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-row-reverse mt-5">
        <div>
          <button
            *ngIf="!id"
            mat-flat-button
            color="primary"
            class="save-button"
            (click)="submitForm()"
            [disabled]="userForm.invalid || !confirmPasswordValue"
          >
            Salvar
          </button>
        </div>

        <div *ngIf="id">
          <!-- atualizacao -->
          <button
            mat-flat-button
            color="primary"
            class="save-button"
            (click)="submitFormUpdate()"
            [disabled]="userForm.invalid"
          >
            Salvar
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
