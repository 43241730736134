<mat-card class="container-details">
  <mat-card-content>
    <mat-card class="container-information">
      <mat-card-content>
        <h6>Informações</h6>
        <div *ngIf="recipient">
          <div fxLayout="row" fxLayoutAlign="start" class="mt-4">
            <div fxLayout="column" fxFlex="25">
              <p class="informations-title">
                {{ nameColumns.lbName }}
              </p>
              <p *ngIf="recipient.name" class="informations-content">
                {{ recipient.name }}
              </p>
              <p *ngIf="!recipient.name" class="informations-content">
                Não informado
              </p>
            </div>
            <div fxLayout="column" fxFlex="25">
              <p class="informations-title">
                {{ nameColumns.lbDocument }}
              </p>
              <p *ngIf="recipient.document" class="informations-content">
                {{ recipient.document }}
              </p>
              <p *ngIf="!recipient.document" class="informations-content">
                Não informado
              </p>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="start" class="mt-2">
            <div fxLayout="column" fxFlex="25">
              <p class="informations-title">
                {{ nameColumns.lbZipcode }}
              </p>
              <p *ngIf="recipient.address.zipcode" class="informations-content">
                {{ recipient.address.zipcode }}
              </p>
              <p *ngIf="!recipient.address.zipcode" class="informations-content">
                Não informado
              </p>
            </div>
            <div fxLayout="column" fxFlex="25">
              <p class="informations-title">
                {{ nameColumns.lbProvince }}
              </p>
              <p *ngIf="recipient.address.province" class="informations-content">
                {{ recipient.address.province }}
              </p>
              <p *ngIf="!recipient.address.province" class="informations-content">
                Não informado
              </p>
            </div>
            <div fxLayout="column" fxFlex="25">
              <p class="informations-title">
                {{ nameColumns.lbCity }}
              </p>
              <p *ngIf="recipient.address.city" class="informations-content">
                {{ recipient.address.city }}
              </p>
              <p *ngIf="!recipient.address.city" class="informations-content">
                Não informado
              </p>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between" class="mt-2">
            <div fxLayout="column" fxFlex>
              <p class="informations-title">
                {{ nameColumns.lbStreet }}
              </p>
              <p *ngIf="recipient.address.street" class="informations-content">
                {{ recipient.address.street }}
              </p>
              <p *ngIf="!recipient.address.street" class="informations-content">
                Não informado
              </p>
            </div>
            <div fxLayout="column" fxFlex>
              <p class="informations-title">
                {{ nameColumns.lbnumber }}
              </p>
              <p *ngIf="recipient.address.number" class="informations-content">
                {{ recipient.address.number }}
              </p>
              <p *ngIf="!recipient.address.number" class="informations-content">
                Não informado
              </p>
            </div>
            <div fxLayout="column" fxFlex>
              <p class="informations-title">
                {{ nameColumns.lbNeighborhood }}
              </p>
              <p *ngIf="recipient.address.neighborhood" class="informations-content">
                {{ recipient.address.neighborhood }}
              </p>
              <p *ngIf="!recipient.address.neighborhood" class="informations-content">
               Não informado
              </p>
            </div>
            <div fxLayout="column" fxFlex>
              <p class="informations-title">
                {{ nameColumns.lbComplement }}
              </p>
              <p
                *ngIf="recipient.address.complement"
                class="informations-content"
              >
                {{ recipient.address.complement }}
              </p>
              <p
                *ngIf="!recipient.address.complement"
                class="informations-content"
              >
                Não informado
              </p>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-card-content>
</mat-card>
