import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { ConfigurationsShipperService } from '../configurations-shipper.service';

export interface PeriodicElement {
  id: number;
  name: string;
}

export interface HeaderTable {
  lbName: string;
  lbDescription: string;
}

@Component({
  selector: 'app-user-type',
  templateUrl: './user-type.component.html',
  styleUrls: ['./user-type.component.scss'],
})
export class UserTypeComponent implements OnInit {
  displayedColumns: string[] = ['name', 'description'];
  dataSource = new MatTableDataSource();
  gridData: any = [];
  id: any;
  options: string[] = ['Editar'];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  headerTable: HeaderTable = {
    lbName: 'Tipo',
    lbDescription: 'Descrição',
  };

  routePath: string = '';
  newUserType: string = '';
  editUserTyp: string = '';
  page: number = 0;
  size: number = 50;
  totalElements: number = 0;

  constructor(
    private router: Router,
    private configShipperService: ConfigurationsShipperService
  ) {}

  ngOnInit(): void {
    this.getTypeUser();
    this.routePath = window.location.pathname;
    this.newUserType = `${this.routePath}/access-privileges`;
    this.editUserTyp = `${this.routePath}/edit-access-privileges`;
  }

  public redirect(action: string, row: any) {
    if (action === 'Editar') {
      this.router.navigate([this.editUserTyp, row.id]).catch((err) => err);
    }
  }

  getTypeUser() {
    const paginatorObj = {
      id: this.id,
    };
    this.configShipperService
      .getTypeUser(paginatorObj)
      .pipe(finalize(() => {}))
      .subscribe((result: any) => {
        if (result != null) {
          this.gridData = result.content?.map((user: any) => ({
            idTipoUsuario: user.id,
            alcada: user.alcada,
            editavel: user.editavel,
            tipo: this.typeUsers(user.tipo),
            descricao:
              this.typeUsers(user.tipo) === 'ADMIN'
                ? 'Tipo de usuário com privilégios totais'
                : 'Tipo de usuário sem acesso a cadastro de usuário e empresas',
            visualizaMelhorias: user.visualizaMelhorias,
          }));
        }
        this.totalElements = result?.totalElements;
        this.dataSource = this.gridData;
      });
  }

  typeUsers(type: string) {
    if (type == 'admin') return 'ADMIN';
    return 'PADRÃO';
  }

  public handlePageEvent(e: PageEvent) {
    if (this.size != e.pageSize) {
      this.page = 0;
    } else {
      this.page = e.pageIndex;
    }
    this.size = e.pageSize;
    this.getTypeUser();
  }
}
