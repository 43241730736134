<mat-card class="container-users">
  <mat-card-content>
    <h6>Tipos de usuário</h6>
    <div class="d-flex mt-4">
    </div>
    <table mat-table [dataSource]="dataSource" class="table mt-4">
      <caption hidden>
        Tabela tipo de usuário
      </caption>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="table-head-color">
          {{ headerTable.lbName }}
        </th>
        <td mat-cell *matCellDef="let element" class="table-row">
          {{ element.tipo }}
        </td>
      </ng-container>

       <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef class="table-head-color">
          {{ headerTable.lbDescription }}
        </th>
        <td mat-cell *matCellDef="let element" class="table-row">
          {{ element.descricao }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
      (page)="handlePageEvent($event)"
      [length]="totalElements"
      [pageSize]="size"
    >
    </mat-paginator>
  </mat-card-content>
</mat-card>
