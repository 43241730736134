import { Component, DoCheck, OnInit } from '@angular/core';
import { SnackbarDefaultService } from 'src/app/shared/services/snackbar-default.service';
import { HomeLandingService } from '../home/home-landing.service';

@Component({
  selector: 'app-landing-home',
  templateUrl: './landing-home.component.html',
  styleUrls: ['./landing-home.component.scss'],
})
export class LandingHomeComponent implements OnInit, DoCheck {
  slideBenefitsIndex = 1;
  slideCommentsIndex = 1;
  slideCompaniesIndex = 1;
  mobile: boolean = false;

  constructor(
    private homeLanding: HomeLandingService,
    private snackBar: SnackbarDefaultService
  ) {}

  ngOnInit(): void {
    this.checkMobile();
    if (this.mobile) {
      this.showSlidesBenefits(this.slideBenefitsIndex);
    }
    this.showSlidesComments(this.slideCommentsIndex);
    this.showSlidesCompanies(this.slideCompaniesIndex);
  }

  ngDoCheck(): void {
    this.checkMobile();
  }

  checkMobile() {
    if (window.innerWidth <= 901) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  plusSlides(n: any, classe: string) {
    if (classe == 'mySlidesBenefits') {
      this.slideBenefitsIndex += n;
      this.showSlidesBenefits(this.slideBenefitsIndex);
    } else if (classe == 'mySlidesComments') {
      this.slideCommentsIndex += n;
      this.showSlidesComments(this.slideCommentsIndex);
    } else {
      this.slideCompaniesIndex += n;
      this.showSlidesCompanies(this.slideCompaniesIndex);
    }
  }

  currentSlide(n: any, classe: string) {
    if (classe == 'mySlidesBenefits') {
      this.slideBenefitsIndex = n;
      this.showSlidesBenefits(this.slideBenefitsIndex);
    } else if (classe == 'mySlidesComments') {
      this.slideCommentsIndex = n;
      this.showSlidesComments(this.slideCommentsIndex);
    } else {
      this.slideCompaniesIndex = n;
      this.showSlidesCompanies(this.slideCompaniesIndex);
    }
  }

  showSlidesBenefits(n: any) {
    let i;
    setTimeout(() => {
      let slides = document.getElementsByClassName(
        'mySlidesBenefits'
      ) as HTMLCollectionOf<HTMLElement>;
      let dots = document.getElementsByClassName('dot-benefits');
      if (n > slides.length) {
        this.slideBenefitsIndex = 1;
      }
      if (n < 1) {
        this.slideBenefitsIndex = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = 'none';
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
      slides[this.slideBenefitsIndex - 1].style.display = 'contents';
      dots[this.slideBenefitsIndex - 1].className += ' active';
    }, 300);
  }

  showSlidesComments(n: any) {
    let i;
    setTimeout(() => {
      let slides = document.getElementsByClassName(
        'mySlidesComments'
      ) as HTMLCollectionOf<HTMLElement>;
      let dots = document.getElementsByClassName('dot-comments');
      if (n > slides.length) {
        this.slideCommentsIndex = 1;
      }
      if (n < 1) {
        this.slideCommentsIndex = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = 'none';
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
      slides[this.slideCommentsIndex - 1].style.display = 'contents';
      dots[this.slideCommentsIndex - 1].className += ' active';
    }, 300);
  }

  showSlidesCompanies(n: any) {
    let i;
    setTimeout(() => {
      let slides = document.getElementsByClassName(
        'mySlidesCompanies'
      ) as HTMLCollectionOf<HTMLElement>;
      let dots = document.getElementsByClassName('dot-companies');
      if (n > slides.length) {
        this.slideCompaniesIndex = 1;
      }
      if (n < 1) {
        this.slideCompaniesIndex = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = 'none';
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
      slides[this.slideCompaniesIndex - 1].style.display = 'contents';
      dots[this.slideCompaniesIndex - 1].className += ' active';
    }, 300);
  }
}
